import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from "yup";
import { toast } from "react-toastify";



export const  validationSchema = yup.object().shape({
  ParentBranch: yup
  .string()
  .required("Parent branch is required"),
    BranchName: yup
      .string()
      .required("Branch Name is required")
      .min(5, "min 5 characters required")
      .max(50, "max 50 characters allowed"),
    expert_id: yup
      .string()
      .required("Branch Manager is required"),
    ContactName: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Contact Name should only contain alphabets")
      .max(25, "max 25 characters allowed"),
    Address: yup
      .string()
          .matches(/^[A-Za-z0-9\s]+$/, "Address should be alphanumeric")
      .max(50, "max 50 characters allowed"),
    City: yup
      .string()
       .matches(/^[A-Za-z\s]+$/, "City should be alphanumeric")
      .max(20, "max 20 characters allowed"),
      StateSelect: yup
      .string(),
      TimeZoneSelect: yup
      .string(),
      PhoneNumber: yup
      .string()
      .matches(/^\d{10}$/,"Phone Number must be valid")
      .typeError("Phone Number can only contain numeric characters"),
    EmailAddress: yup
    .string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Enter valid email address"
    ).email("Enter a valid email address"), 
  WebAddress: yup
    .string()
    .matches(
      /^\www\.[a-zA-Z0-9\-]+\.(com|in|org|co\.in)(\/\S*)?$/,
      "Enter a valid web address"
    ) //
})

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValues: string;
  enableField: boolean;
  // Customizable Area Start
  allExperts:any;
  anchorE:boolean;
  selectRadio:string;
  BranchData:any;
  stateData:any;
  TimeZone:any;
  selectedValue:any;
  showCancelModal:boolean;
  stateDropdownOpen: boolean;
  timeZoneDropdownOpen: boolean;
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateBranchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  expertDataApiCallIds:string="";
  formikRef:  any;
  getParentBranchCallId:string="";
  getStateListCallId:string="";
  getTimeZoneCallId:string="";
  createBranchApiCallId:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValues: "A",
      enableField: false,
      // Customizable Area Start

      allExperts:[],
      anchorE:false,
      selectRadio:"",
      BranchData:[],
      stateData:[],
      TimeZone:[],
      selectedValue:null,
      showCancelModal:false,
      stateDropdownOpen: false,
      timeZoneDropdownOpen: false,
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "From: " + this.state.txtSavedValues + " To: " + value,
        "Change Value"
      );

      this.setState({ txtSavedValues: value });
    }

    // Customizable Area Start
const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

if (apiRequestCallId === this.expertDataApiCallIds) {
  this.setState({ allExperts: responseJson.data })
  this.parseApiCatchErrorResponse(errorResponse);
  }
  if (apiRequestCallId === this.getParentBranchCallId) {
    this.setState({ BranchData: responseJson.data })
    this.parseApiCatchErrorResponse(errorResponse);
    }else  if (apiRequestCallId === this.getStateListCallId) {
      this.setState({ stateData: responseJson})
      this.parseApiCatchErrorResponse(errorResponse);
      }else if (apiRequestCallId === this.getTimeZoneCallId) {
        this.setState({ TimeZone: responseJson })
        this.parseApiCatchErrorResponse(errorResponse);
        }
        else if (apiRequestCallId === this.createBranchApiCallId) {
          this.handleCreateBranchResponse(responseJson);
        }
        
    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {
    super.componentDidMount();
    this.getParentBranch();
    this.getExpertSearchListNew();
    this.getStateList();
    this.getTimeZone();
  }

  handleCreateBranchResponse = (response: any) => {
    if (response?.data) {
      toast.success(`Branch created successfully`);
      return;
    }
    if (response?.errors) {
      response.errors.map((err: { name: string }) => {
        toast.error(err.name);
      });
    }
  };

  backColor = (value: any) => {
    return value ?"#fff": "#FCF6F6"
  }
  TextColor=(value: any)=>{
    return value?"#1C1B1B":"#ADAAAA"
  }
  dropdownHandlerClose = () => {
    this.setState({
      anchorE: false,
    })
  }
  handleRadioChange=(event:any)=>{
    this.formikRef?.setFieldValue("expert_id",event?.target?.value)
  }
  dropdownHandlerOpen = (event:any) => {
    this.setState({
      anchorE:!this.state.anchorE,

    })
  }

  handleStateDropdownOpen = () => {
    this.setState({ 
      stateDropdownOpen:!this.state.stateDropdownOpen,
     });
  };

  handleTimeZoneDropdownOpen = () => {
    this.setState({ 
      timeZoneDropdownOpen:!this.state.timeZoneDropdownOpen,
     });
  };
  
  getExpertSearchListNew = () => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expertDataApiCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_library2/experts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getParentBranch=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getParentBranchCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getParentBranchEndPoints}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  getStateList=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getStateListCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.StateListEndPoints}/${"US"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleCancel=()=>{
    this.setState({showCancelModal:true})
  }
  CloseModal=()=>{
    this.setState({showCancelModal:false})

  }
  handleDisable=(values:any)=>{
if(values.expert_id && values.BranchName && values.ParentBranch){
  return true
}else{
  return false
}
  }
  handleBranchChange=(event:any,newValue:any)=>{
  this.setState({selectedValue:newValue})
  this?.formikRef?.setFieldValue("ParentBranch",newValue.id)
  }
  getTimeZone=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTimeZoneCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TimeZoneListEndPoints}?id=${"US"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  CreateBranchApi = (values:any) => {
    const body={ 
        "branch_data":{
            "name":values.BranchName,
            "branch_status": "inactive",
            "parent_id":values.ParentBranch,
            "branch_manager_id":values.expert_id,
            "branch_detail_attributes": {
                "contact_name":values.ContactName,
                "country": "USA",
                "state":values.StateSelect,
                "address":values.Address,
                "web_address":values.WebAddress,
                "phone_number": values.PhoneNumber,
                "timezone":values.TimeZone,
                "city":values.City,
                "email_address":values.EmailAddress,
                "branch_notes": ""
            }
          }}
      const token = localStorage.getItem('token')
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createBranchApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.CreateBranchEndPoints}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleCancelOk = () => {
      const { setValues } = this?.formikRef;
      setValues({
        ParentBranch: "",
        BranchName: "",
        expert_id: "",
        ContactName: "",
        Address: "",
        City: "",
        StateSelect: "",
        PhoneNumber: "",
        TimeZoneSelect: "",
        WebAddress: "",
        EmailAddress: ""
      });
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ShowBranch");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    
    
  handleApplyBtn=()=>{
    this.setState({
      anchorE: false,
    })
  }

  handleAutocompleteChange = (event: any, newValue: any) => {
    if (newValue === null) {
      this.setState({ selectedValue: null });
    } else {
      this.setState({ selectedValue: newValue });
      this?.formikRef?.setFieldValue("ParentBranch", newValue.id);
    }
  }

  // Customizable Area End
}
