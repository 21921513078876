import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
  location:any;
  // Customizable Area Start
  handleCartItemsCountInTopbar?: (count: number) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  libraryData: any;
  grapValues: any;
  allProgramLibraryData: any;
  dialogopen:boolean;
  ObjectData:any;
  style:boolean;
  cartItemsCount: number;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProgramController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetProgramLibraryData: string | Message = "";
  GetObjectData:string | Message = "";
  AddCartItemID:string | Message = "";
  PostCourseLibraryData:string | Message = "";
  getCartItemsApiCallId: string | Message = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      allProgramLibraryData: {},
      dialogopen:false,
      ObjectData:{},
      style:true,
      libraryData: [
        {
          id: 1,
          image_url: slider1
        },
        {
          id: 2,
          image_url: slider2
        },
        {
          id: 3,
          image_url: slider3
        },
        {
          id: 4,
          image_url: slider2
        },
        {
          id: 5,
          image_url: slider1
        },
        {
          id: 6,
          image_url: slider2
        },
        {
          id: 7,
          image_url: slider3
        },
        {
          id: 8,
          image_url: slider1
        },
        {
          id: 9,
          image_url: slider2
        }
      ],
      cartItemsCount: 0,
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {
     this.getProgramLibraryData();
     this.getCartItems();
     window.scrollTo(0, 0)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      const value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      const newValueText = `From: ${this.state.txtSavedValue} To: ${value}`;
  
      this.showAlert("Change Value", newValueText);
      this.setState({ txtSavedValue: value });
    }
  
    // Customizable Area Start
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.GetProgramLibraryData:
            this.setState({ allProgramLibraryData: responseJson, isLoading: false });
            break;
  
          case this.PostCourseLibraryData:
            this.getProgramLibraryData();
            if (this.state.allProgramLibraryData?.data?.attributes?.button === "enroll") {
              toast("Course Add in Personal");
            }
            break;
  
          case this.GetObjectData:
            this.setState({ ObjectData: responseJson });
            break;
  
          case this.AddCartItemID:
            this.handleAddCartItemResponse(responseJson);
            break;
  
          case this.getCartItemsApiCallId:
            this.handleGetCartItemsApiResponse(responseJson);
            break;
  
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSubscription = (data:any) => {
    this.props.history.push('/Subscription',{name: data, type:'Program'})    
  }
  startPlay = (id:any) => {
    this.props.history.push(`/title/${id.data.attributes.course_video_track.last_video_id}}`, {program: this.state.allProgramLibraryData.data.id} )
 }

 dialogHandleClose = () => {
  this.setState({dialogopen:false})
}



 handleNavigate = (ele:any) => {
  this.props.history.push(`/course-detail/${this.props.navigation.getParam("type")}/${ele.id}/${ele.title.replace(/\s/g, '-')}`,{program: this.state.allProgramLibraryData.data.id})
}

postXpandLibraryData = async (id:any) => {
  if(this.state.allProgramLibraryData?.data?.attributes?.button === "enroll"){
    // toast('Course Add in Personal')
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "courseable_id": id,
      "courseable_type": "BxBlockCoursecreation::Program" // course: "BxBlockCoursecreation::Course, program: "BxBlockCoursecreation::Program"
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCourseLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/enroll_courses`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
} 

dialogHandleOpen = (ele:any) => {
  this.setState({dialogopen:true})
  const header = {
    "Content-Type": "application/json",
    token: localStorage.getItem("token")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.GetObjectData = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_library2/user_objects?program_id=1&id=${ele.id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    header
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'get'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  getProgramLibraryData = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProgramLibraryData = requestMessage.messageId;
    const p_id = this.props.navigation.getParam("id");
    const p_title = this.props.navigation.getParam("title")
    const p_course = this.props.navigation.getParam("type")
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_playlist/user_programs?id=${p_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Add item to cart
  addCartItem = async (id: any, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const httpBody =
      type == "user_program"
        ? {
            program_id: id,
          }
        : {
            course_id: id,
          };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AddCartItemID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Handle add to cart response
  handleAddCartItemResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
     toast.warning('Item already in cart');
     } else {
       toast.success('Item has been added to your cart.')
       this.setState({
         cartItemsCount: this.state.cartItemsCount + 1
       }, () => {
         this.props?.handleCartItemsCountInTopbar &&
         this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
       });
     } 
   }

  // Get current cart items
  getCartItems = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Handle get cart items api response
  handleGetCartItemsApiResponse = (response: any) => {
    if (response?.error) {
      console.log("ERROR FETCHING CART ITEMS");
    } else if (response?.message) {
      this.setState({
        cartItemsCount: 0,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } else if (response?.data) {
      const oneTimePaymentCartItems = response.data.attributes.cart_item;
      const subscriptionCartItems = response.data.attributes.subscription_cart_item;
      const totalCartItemsCount = oneTimePaymentCartItems.length + subscriptionCartItems.length;
      this.setState({
        cartItemsCount: totalCartItemsCount,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  };

   gotoBackSide = () => {
    window.history.back()
  }

  handleObjectNavigation = (objectId: number) => {
    this.props.history.push(`${this.props.history.location.pathname}/objects/${objectId}`)
  }

  // Customizable Area End
}