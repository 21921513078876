import React from "react";
// Customizable Area Start
import { Box, Typography, Switch, TextField, FormControl, Select, MenuItem, Divider, Button, InputAdornment, IconButton, Checkbox, ListItemText } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import ControlPoint from '@material-ui/icons/ControlPoint';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DatePicker from 'react-datepicker';
import CreateAchievementController, {
    Props
} from "./CreateAchievementController.web";
import  {iconImg}  from "./assets"
// Customizable Area End

export default class CreateAchievement extends CreateAchievementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { icon } = this.state;
        return (
            <>
                <Wrapper>
                    <div style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px"}}>
                    <Box className="headingContainer">
                        <Typography style={{fontSize: "22px", fontWeight: 600, color: "#1C1B1B"}}>General Information</Typography>
                        <Box>
                            <Typography style={{color: "#1C1B1B", fontSize: "16px" , fontWeight: 400}}>Status</Typography>
                            <Box className="switchContainer">
                                <span style={{ fontWeight: 600 , fontSize: "18px", color: "#484646"}} className="active">Active</span>
                                <Switch className="criteriaToggleBtn" defaultChecked />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="infoContainer">
                        <Box className="iconBox">
                            {icon ? (
                                <img src={icon} alt="" className="selectedIcon" />
                            ) : (
                                <ControlPoint style={{color: "#6C328B", fontSize: "32px"}}/>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="icon-upload"
                            />
                            <label htmlFor="icon-upload">
                                <Typography style={{color: "#545650" , fontSize: "16px", fontWeight: 600}}>Add Icon</Typography>
                            </label>
                        </Box>
                        <Box className="inputContainer">
                            <Box className="inputWrapper">
                                <Box>
                                    <FormFieldTitle>Achievement Name</FormFieldTitle>
                                    <TextField variant="outlined" className="achievementName" value={this.state.achievementName} placeholder="Type here" fullWidth style={{ marginTop: "3px" , backgroundColor: "#fcf6f6"}}/>
                                </Box>
                                <Box>
                                    <FormFieldTitle>Owner</FormFieldTitle>
                                    <FormControl fullWidth style={{ marginTop: "3px",}}>
                                        <Select
                                            variant="outlined"
                                            className="ownerSelect"
                                            value={this.state.ownerText}
                                            displayEmpty
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <span>Select a user</span>
                                            </MenuItem>
                                            {this.state.ownerNameList?.data?.map((item) => {
                                                return (
                                                    <MenuItem value={item.attributes.first_name}>{item.attributes.first_name}&nbsp;{item.attributes.last_name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box className="assigneesWrapper">
                                <Box style={{ flex: "1" }}>
                                    <FormFieldTitle>Assignees</FormFieldTitle>
                                    <FormControl variant="outlined" style={{ width: "100%", marginTop: "3px" }}>
                                        <Select
                                            className="assigneeInput"
                                            displayEmpty
                                            multiple
                                            value={this.state.personName}
                                            input={<OutlinedInput placeholder="Select branch name" />}
                                            renderValue={(selected) => {
                                                const selectedValues = selected as string[];
                                                if (selectedValues.length === 0) {
                                                    return <span style={{ color: '#999' }}>Select branch name</span>;
                                                }
                                                return (
                                                    <span className="selectedAssignee" title={selectedValues.join(', ')}>
                                                        {selectedValues.join(', ')}
                                                    </span>
                                                );
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        width: 250,
                                                        maxHeight: 300,
                                                    },
                                                },
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                            }}
                                        >
                                            {this.state.names.data?.map((name) => (
                                                <MenuItem key={name.attributes.name} value={name.attributes.name}>
                                                    <Checkbox checked={this.state.personName.indexOf(name.attributes.name) > -1} />
                                                    <FolderOpenIcon style={{ marginRight: "10px" }} />
                                                    <ListItemText primary={name.attributes.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box style={{ display: "flex", gap: "10px", minWidth: "30%" }}>
                                    <Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                        >
                                            <FormFieldTitle>Due Date</FormFieldTitle>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                border="1px dashed #ccc"
                                                borderRadius="10px"
                                                padding="12px"
                                                bgcolor="#f9f6f6"
                                                marginTop="3px"
                                            >
                                                <label htmlFor="dateInput">
                                                <img src={iconImg} style={{ marginRight: '10px' , width: "28px", height: "28px"}}/>
                                                </label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={this.state.dueDate}
                                                    onChange={() => {}}
                                                    id="dateInput"
                                                    customInput={
                                                        <TextField
                                                            name="dateInput"
                                                            id="dateInput"
                                                            size="small"
                                                            placeholder="Due Date"
                                                            fullWidth
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box>
                                        <FormFieldTitle>Status</FormFieldTitle>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select
                                                    className="statusInput"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.status}
                                                    MenuProps={{
                                                        getContentAnchorEl: null,
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={"Active"}>Active</MenuItem>
                                                    <MenuItem value={"Not Active"}>Not Active</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    </div>
                    <Box className="footerContainer">
                        <Box className="criteriaContainer">
                            <Typography style={{color: "1C1B1B", fontSize: "22px", fontWeight: 600, fontFamily: "Poppins"}}>Criteria</Typography>
                            <Divider style={{marginBottom: "10px"}}/>
                            <Box className="toggleContainer">
                                <Box className="criteriaToggle"><FormFieldTitle>Complete content</FormFieldTitle><Switch checked={this.state.completeContentToggle} className="criteriaToggleBtn" /></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of days logged in</FormFieldTitle><Switch checked={this.state.daysLoggedInToggle}className="criteriaToggleBtn" /></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of lessons completed</FormFieldTitle><Switch checked={this.state.lessonsCompletedToggle}  className="criteriaToggleBtn" /></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of courses completed</FormFieldTitle><Switch checked={this.state.coursesCompletedToggle} className="criteriaToggleBtn" /></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of days logged in, in a row</FormFieldTitle><Switch checked={this.state.loggedInRowToggle} className="criteriaToggleBtn" /></Box>
                            </Box>
                        </Box>
                        <Box className="contentWrapper">
                            <Button variant="contained" className="selectContentButton">Select content</Button>
                            <Box className="selectContainer">
                                <Box>
                                    <DaysTitle>Number of days logged in</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.daysLoggedInToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton size="small" className="numberIcon">
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" className="numberIcon">
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.daysLoggedInToggle ? "selectInput" : "disabledSelectInput"} value={this.state.daysLoggedIn} placeholder="Type here..." fullWidth />
                                </Box>
                                <Box>
                                    <DaysTitle>Number of lessons completed</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.lessonsCompletedToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton size="small" className="numberIcon">
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" className="numberIcon">
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.lessonsCompletedToggle ? "selectInput" : "disabledSelectInput"} value={this.state.lessonsCompleted} placeholder="Type here..." fullWidth />
                                </Box>
                                <Box>
                                    <DaysTitle>Number of courses completed</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.coursesCompletedToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton  size="small" className="numberIcon">
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton  size="small" className="numberIcon">
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.coursesCompletedToggle ? "selectInput" : "disabledSelectInput"} value={this.state.coursesCompleted} placeholder="Type here..." fullWidth />
                                </Box>
                                <Box>
                                    <DaysTitle>Number of days logged in, in a row</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.loggedInRowToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton size="small" className="numberIcon">
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" className="numberIcon">
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.loggedInRowToggle ? "selectInput" : "disabledSelectInput"} value={this.state.loggedInRow} placeholder="Type here..." fullWidth />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="createAchievementContainer">
                        <Button variant="contained" className="createAchievementBtn">Create achievement
                        </Button>
                    </Box>
                </Wrapper>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const Wrapper = styled(Box)({
    padding: "20px",
    "& .headingContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
    },
    "& .infoContainer": {
        display: "flex",
        padding: "10px 20px",
        gap:"40px",
        placeContent: "space-between",
        "@media screen and (max-width: 768px)" : {
            display:"grid",
            placeItems:"center",
    }
    },
    "& .inputContainer": {
        display: "grid",
        gap: "10px",
        flex: 1
    },
    "& .inputWrapper": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        "@media screen and (max-width: 992px)" : {
                gridTemplateColumns:"1fr"
        }
    },
    "& .assigneesWrapper": {
        display: 'flex',
        gap: "10px",
        "@media screen and (max-width: 992px)" : {
            display:"grid",
            gridTemplateColumns:"1fr",
    }
    },
    "& .iconBox": {
        display: "flex",
        color: "#C7AFD2",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed #6C328B",
        boxSizing: "border-box",
        height: "198px",
        width:"250px",
        borderRadius: "8px",
        marginBottom:"20px",
    },
    "& .selectedIcon": {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    "& .footerContainer": {
        marginTop: "40px",
        display: "flex",
        gap: "30px",
        "@media screen and (max-width: 768px)" : {
           display:"grid",
           gridTemplateColumns:"1fr"

        }

    },
    "& .criteriaContainer": {
        minWidth: "40%",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px",
        height: "260px"
    },
    "& .criteriaToggle": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .criteriaToggleBtn": {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                color: '#6C328B',
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: 'var(--sys-color-PrimaryContainer, #EEE8F2)'
            },
        },
    },
    "& .contentWrapper": {
        flex: 1,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px",
        padding: "20px",
        borderRadius: "10px",
    },
    "& .selectContainer": {
        marginTop: "20px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
    },
    "& .selectContentButton": {
        background: "#E5E2E1",
        borderRadius: "12px",
        padding: "10px 40px",
        color: "#929090",
        textTransform: "none",
    },
    "& .selectInput": {
        borderRadius: "8px",
    },
    "& .disabledSelectInput": {
        background: "#E4E2E1",
        borderRadius: "8px",
    },
    "& .achievementName": {
        background: "#FCF8F8"
    },
    "& .ownerSelect": {
        background: "#FDF8F8",
        backgroundColor: "#fcf6f6"
    },
    "& .assigneeInput": {
        background: "#FDF8F8",
        display: "inline-flex",
        alignItems: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        backgroundColor: "#fcf6f6"
    },
    "& .dateInput": {
        background: "#FDF8F8",
        border: "2px dashed gray",
        boxSizing: "border-box",
        borderRadius: "8px",
    },
    "& .createAchievementContainer": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
    },
    "& .createAchievementBtn": {
        textTransform: "none",
        color: "#929090",
        background: "#E5E2E1",
        padding: "10px 60px",
        borderRadius: "12px",
    },
    "& .updownArrowBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    "& .numberIcon": {
        padding: "0",
        color: "gray",
    },
    "& .statusInput": {
        borderRadius: "27px",
        background: "#EEE8F2",
        color: "#652786",
        border: "0px",
        fotnWeight: 400,
        fontSize: "16px"
    },
    "& .selectedAssignee" : {
        maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" 
    },
    "& .statusInput .MuiOutlinedInput-notchedOutline" : {
        borderColor: "transparent !important"
      }
});

const FormFieldTitle = styled(Typography)({
    color: "1C1B1B",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins !important"
});

const DaysTitle = styled(Typography)({
    color: "#787776",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins !important"
});

// Customizable Area End