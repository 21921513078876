import React from "react";

// Customizable Area Start
import { Box, FormControl,FormControlLabel,Button, Grid, TextField, Select, MenuItem, Checkbox, Snackbar, ListItemText, Typography, Switch, InputAdornment, Modal, IconButton } from "@material-ui/core";
import { styled, } from "@material-ui/core/styles";
import MaterialUIBox from '@material-ui/core/Box';
import {ExpandMore,ExpandLess, AttachMoneyOutlined, ErrorOutlineOutlined } from "@material-ui/icons";
import { startOfToday } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import CustomDatePicker from "../../../components/src/DatePicker";
import { editIconBlue } from "./assets"
import SaveAndDiscardChangesModal from "../../../components/src/SaveAndDiscardChangesModal";
export const configJSON = require("./config");
import SearchIcon from '@material-ui/icons/Search';
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import Commonmanagement from "../../imagemanagement2/src/Commonmanagement.web"
const CustomMenuProps = {
  PaperProps: {
    style: {
      marginTop: '22px'  as const,
      marginLeft:"10px" as const,
      left: "10px"
    },
  },
  getContentAnchorEl: null
};

// Customizable Area End

import CourseCreationFormTwoController, {
  Props,
} from "./CourseCreationFormTwoController.web";

export default class CourseCreationformTwo extends CourseCreationFormTwoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderCourseDetails = () => {
    return (
      <div style={{ boxShadow: "1px 2px 10px 0px #00000011", borderRadius: "8px" }}>
        <Box sx={courseHeader}>Course Options</Box>
        <Grid container md={12} xs={12}>
          <Grid container md={12} xs={12} style={{ padding: "16px", gap: "15px" }}>
            <Grid item xs={12} md={4}>
            <label style={courseLabel}>Launch Date</label>
              <CustomDatePicker
                disable={!this.props.formikProps?.values.include_launch_date}
                placeHolder="Launch Date"
                selectedDate={this.state.launchDate ? this.state.launchDate : this.props.formikProps?.values.launch_date}
                top="70px"
                left={window.innerWidth<600?"0px":"63px"} 
                handleChange={this.handleLaunchDateChangeOne}
                minDate={this.props?.formikProps?.values.launch_date ? this.props?.formikProps?.values.launch_date : startOfToday()} />
            </Grid>
            <Grid item xs={12} md={4}>
            <label style={courseLabel}>Due Date</label>
              <CustomDatePicker
                disable={!this.props.formikProps?.values.add_due_date}
                placeHolder="Due Date"
                selectedDate={this.state.dueDate ? this.state.dueDate : this.props.formikProps?.values.due_date}
                top="70px"
                left={window.innerWidth<600?"70px":"205px"} 
              
                handleChange={this.handleDueDateChangeOne} />
            </Grid>
          </Grid>
          <Grid container md={12} xs={12} style={{ marginBottom: "16px", padding: "0 16px" }} >
            <Grid item md={6} xs={12} style={{ paddingRight: "8px" }} className={this.props.formikProps?.values.days_to_complete_the_course?"customInput":"color_inut"}>
              <label style={courseLabel}>Days to complete the course</label>
              <TextField
                 name="course_timeline"
                type="number"
                data-test-id="course_timeline"
                value={this.props.formikProps?.values.course_timeline}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={this.checkCourseTimeDisableNew()}
                onChange={(e) => {
                  const newValue = parseInt(e.target.value, 10);
                  if (newValue < 0) {
                    e.target.value = '0';
                  }
                  this.handleDays(e);
                  this.props.handleFormValChange("course_timeline", e.target.value);
                }}
                placeholder="Type a number"
                variant="standard"
                fullWidth
              />
            </Grid>

            <Grid className="customInput" style={{ display: "grid", paddingLeft: "8px" }} item md={6} xs={12}>
              <label style={courseLabel}>Notify upon completion</label>
              <Select
                multiple
                name="notify_when_completed"
                data-test-id="notify_when_completed"
                style={{ height: "54px", color:this.TextColor(this.props.formikProps?.values?.is_notify_when_completed),background: this.backColor(this.props.formikProps?.values?.is_notify_when_completed) }}
                disabled={!this.props.formikProps?.values.is_notify_when_completed}
                MenuProps={{...CustomMenuProps, anchorOrigin:{vertical: "bottom", horizontal: "right"}, transformOrigin: {vertical: "top", horizontal: "right"}}}

                value={Array.isArray(this.props.formikProps?.values?.notify_when_completed) ? this.props.formikProps?.values.notify_when_completed : []}
                 displayEmpty
                 renderValue={(selected:any) => 
                  this.renderNotifyValues(selected)
                 }           
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  this.props.handleFormValChange('notify_when_completed', selectedValue);
                }}   
              >
                <MenuItem value="" disabled style={{ color: "grey" }}>
                  <ListItemText primary="Select user" />
                </MenuItem>
                {this.state.userList.map((option: any) => (
                  <MenuItem key={option.value} value={option.labelValue}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container md={12} xs={12} style={{ marginBottom: "16px", padding: "0 16px" }} >
            <Box className="calender" style={{ display: "grid" }}>
              <label style={courseLabel} >Release content</label>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <Button data-test-id="release_daily" variant="outlined" disabled={!this.props.formikProps?.values.content_release_schedule} onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.handleFormValChange("content_release_schedule_value", "daily") }} className={this.props.formikProps?.values.content_release_schedule ? this.renderDaily() : "calender-btndisabled"}>Daily</Button>
                <Button data-test-id="release_weekly" variant="outlined" disabled={!this.props.formikProps?.values.content_release_schedule} onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.handleFormValChange("content_release_schedule_value", "weekly") }} className={this.props.formikProps?.values.content_release_schedule ? this.renderWeekly() : "calender-btndisabled"}>Weekly</Button>
                <Button data-test-id="release_monthly" variant="outlined" disabled={!this.props.formikProps?.values.content_release_schedule} onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.handleFormValChange("content_release_schedule_value", "monthly") }} className={this.props.formikProps?.values.content_release_schedule ? this.renderMonthy() : "calender-btndisabled"}>Monthly</Button>
              </div>
            </Box>
          </Grid>
          <Grid container md={12} xs={12} style={{ marginBottom: "16px", padding: "0 16px" }} >
            <Grid item md={6} xs={12}>
              <label style={courseLabel} >Phases</label>
              <FormControl className="select-phases" style={{ marginTop: "5px" }} fullWidth>
                <Select
                  labelId="open-select-label"
                  data-test-id="open-select"
                  name="phases"
                  value={this.props.formikProps?.values.phases ? this.props.formikProps?.values.phases : ""}
                  onChange={(e)=>this.props.handleFormValChange("phases",e.target.value)}
                  displayEmpty
                  style={{ background: this.backColor(this.props.formikProps?.values?.content_release_schedule) ,color:this.TextColor(this.props.formikProps?.values?.content_release_schedule)}}
                  variant="outlined"
                  disabled={!this.props.formikProps?.values.content_release_schedule}
                  fullWidth
                >
                  <MenuItem value={1} disabled style={{ color: 'grey' }}>Select the number of phases</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container md={12} xs={12} style={{ marginBottom: "16px", padding: "0 16px" }} >
            <Grid item md={6} xs={12} style={{ paddingRight: "8px" }}>
            <label style={courseLabel}>Course price</label>
              <TextField
                data-test-id="input-price"
                name="price"
                className="form-field"
                style={{ display: "flex", alignItems: "center" }}
                type="number"
                disabled={!this.props.formikProps?.values.is_sellable}
                value={this.props.formikProps?.values.price == 0 ? "" : this.props.formikProps?.values.price}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => this.handlePriceChange(e)}
                placeholder="Price"
                variant="filled"
                fullWidth
                InputProps={{
                  inputMode: 'decimal',
                  startAdornment: (
                      <InputAdornment position="start" style={{ margin: "0" }}>
                      US <AttachMoneyOutlined style={{fontSize:"inherit", marginLeft:"-3px"}}/>
                      </InputAdornment>
                  ),
              }}
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingLeft: "8px" }}>
              <FormControl className="customInput" fullWidth variant="outlined">
                <label style={courseLabel}>Subscription period</label>
                <Select
                  labelId="subscription-select-label"
                  data-test-id="subscription-select"
                  name="subscription_period"
                  value={this.props.formikProps?.values.subscription_period}
                  onChange={(e)=>this.props.handleFormValChange("subscription_period",e.target.value)}
                  displayEmpty
                  style={{ height: "54px", margin: "0", marginTop: "5px",color:this.TextColor(this.props.formikProps?.values?.is_sellable) ,background: this.backColor(this.props.formikProps?.values?.is_sellable) }}
                  disabled={!this.props.formikProps?.values.is_sellable}
                  fullWidth
                >
                  <MenuItem value="" disabled>Select a Period</MenuItem>
                  <MenuItem value={"one_time_purchase"}>One Time Purchase</MenuItem>
                  <MenuItem value={"monthly_subscription"}>Monthly</MenuItem>
                  <MenuItem value={"semi_anually"}>Semi-Annually</MenuItem>
                  <MenuItem value={"anually"}>Annually</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {this.state.releaseScheduleUpdate && this.state.editTrue && !this.props.formikProps.values.content_release_schedule  && 
          <DeleteModal
          data-test-id="Phase_modal"
          openDialog={this.state.RemovePhase}
          headingText={"Are you sure you want to remove phases from this course?"}
          btnCancelText={"Cancel"}
          btnOkText={"Remove phases"}
          handleCancel={this.handlePhaseCancel}
          handleOk={this.handleRemovePhase} />      
        }
      </div>
    )
  }
  renderSwitchComponent = () => {
    return (
      <div style={{ boxShadow: "1px 2px 10px 0px #00000011", borderRadius: "8px" , display:"flex", gap:"10px", flexDirection:"column", height:"100%"}}>
        <Box sx={courseHeader}>Course Logic</Box>

        <Grid item xs={12} style={switchContainer}>
          <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
            Include launch date
          </Typography>
          <Switch
            onChange={(e, checked)=>this.props.handleFormValChange("include_launch_date",checked)}
            name="include_launch_date"
            checked={this.props.formikProps?.values.include_launch_date}
            data-test-id="include_launch_date"
            inputProps={{ "aria-label": "Include Launch Date" }}
            className="switch"
            classes={{
              switchBase: "switchBase",
              track: "track",
            }}
          />
        </Grid>
        <Grid item xs={12} style={switchContainer}>
          <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
            Add due date
          </Typography>
          <Switch
            onChange={(e,checked) => {
              this.props.handleFormValChange("add_due_date",checked)
              this.props.formikProps?.values.add_due_date === false &&
                this.props.formikProps?.values.days_to_complete_the_course &&
                this.props.formikProps?.setFieldValue("days_to_complete_the_course", false);
            }}
            name="add_due_date"
            checked={this.props.formikProps?.values.add_due_date}
            inputProps={{ "aria-label": "Add Due Date" }}
            data-test-id="add_due_date"
            className="switch"
            classes={{
              switchBase: "switchBase",
              track: "track",
            }}
          />
        </Grid>
        <Grid item xs={12} style={switchContainer}>
          <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
            Days to complete the course
          </Typography>
          <Switch
            data-test-id="daysToComplete"
            onChange={(e, checked) => {
              this.props.handleFormValChange("days_to_complete_the_course", checked)
              this.props.formikProps?.values.days_to_complete_the_course === false &&
                this.props.formikProps?.values.add_due_date &&
                this.props.formikProps?.setFieldValue("add_due_date", false);
            }}
            name="days_to_complete_the_course"
            checked={this.props.formikProps?.values.days_to_complete_the_course}
            inputProps={{ "aria-label": "Days to complete the course" }}
            className="switch"
            classes={{
              switchBase: "switchBase",
              track: "track",
            }}
          />
        </Grid>
        <Grid item xs={12} style={switchContainer}>
          <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }}>
            Notify when completed
          </Typography>
          <Switch
            onChange={(e, checked)=> this.props.handleFormValChange("is_notify_when_completed", checked)}
            name="is_notify_when_completed"
            checked={this.props.formikProps?.values.is_notify_when_completed}
            data-test-id="is_notify_when_completed"
            inputProps={{ "aria-label": "Notify when completed" }}
            className="switch"
            classes={{
              switchBase: "switchBase",
              track: "track",
            }}
          />
          
        </Grid>
        <Grid item xs={12} style={switchContainer}>
          <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
            Content release schedule
          </Typography>
          <Switch
            onChange={(e, checked) => {
              this.props.handleFormValChange("content_release_schedule", checked)
              if(!checked)
                {
              this.setState({RemovePhase: true})
                }
              this.setState({releaseScheduleUpdate: true})
            }
              }
            name="content_release_schedule"
            data-test-id="content_release_schedule"
            checked={this.props.formikProps?.values.content_release_schedule}
            inputProps={{ "aria-label": "Content release schedule" }}
            className="switch"
            classes={{
              switchBase: "switchBase",
              track: "track",
            }}
          />
        </Grid>
        <Grid item xs={12} style={switchContainer}>
          <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
            Forced learning
          </Typography>
          <Switch
            onChange={(e, checked)=>this.props.handleFormValChange("is_forced_learning", checked)}
            name="is_forced_learning"
            checked={this.props.formikProps?.values.is_forced_learning}
            inputProps={{ "aria-label": "is_forced_learning" }}
            className="switch"
            data-test-id="is_forced_learning"
            classes={{
              switchBase: "switchBase",
              track: "track",
            }}
          />
        </Grid>
        <Grid item xs={12} style={switchContainer}>
          <Typography className="toggle-show-label" style={{ fontFamily: "'Poppins', sans-serif" }} >
            Make this course sellable
          </Typography>
          <Switch
            onChange={(e,checked)=>this.props.handleFormValChange("is_sellable",checked)}
            name="is_sellable"
            checked={this.props.formikProps?.values.is_sellable}
            inputProps={{ "aria-label": "is_sellable" }}
            className="switch"
            data-test-id="is_sellable"
            classes={{
              switchBase: "switchBase",
              track: "track",
            }}
          />
         
        </Grid>
      </div>
    )
  }

  renderTextField(label: string, name: string, placeholder: string, value: string, touched: boolean, error: string, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void) {
  
    return (
      <FormControl variant="standard" className={value|| this.state.createTitle?"color_inut inputwidth":"customInput inputwidth"} >
        <label className="labelStyle" style={labelText}>{label}</label>
        <TextField
          data-test-id="toastMessageId"
          name={name}
          placeholder={placeholder}
          value={this.state.createTitle ? this.state.createTitle : value}
          id="bootstrap-input"
          error={touched && Boolean(error)}
          helperText={touched && error}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChange(event, handleChange, this.props.handleToastMsg)}
        />
      </FormControl>
    );
  }

  renderThumbnailSection(buttonText: string, fileInput: React.RefObject<HTMLInputElement>, thumbnailImg: string) {
    return (
      <>
        <Box  className="thumbnailOuterBox">
        <label className="labelStyle" style={labelText}>Thumbnail*</label>
        <div className="thumbnailinner">
        <div style={{ display: "flex", gap: "20px" }}>
          <Button
            data-test-id="thumbnailButton"
            className="changethumb-btn"
            onClick={
               this.handleOpenOptionPrompt
            }
          >
            {buttonText}
          </Button>
          <div className={this.state?.thumbnailImageObj?.attributes?.image?.url || this?.props?.formikProps?.values?.thumbnail_img ? "":"thumbpreview"}>
            {this.state.thumbnailImageObj ?
              (<span>
                <img data-test-id="thumbnailImg" src={this.state.thumbnailImageObj.attributes?.image?.url?this.state.thumbnailImageObj?.attributes?.image?.url:this.props?.formikProps?.values?.thumbnail_img}  className={this.state.thumbnailImageObj.attributes?.image?.url ||this?.props?.formikProps?.values?.thumbnail_img ?"thumbnailImg":"thumbnailwidth"}/>
                </span>
            ) : (
              <div></div>
            )}
          </div>  
        </div>
        <div>
        {this.renderRightButton()}
        </div>
        </div>
       
      </Box>
      {this.state.showThumbnailLibraryModal && 
      <>
      <Commonmanagement
      id="2"
      navigation={this.props.navigation}
         open={this.state.showThumbnailLibraryModal}
         onClose={this.handleThumbnailModalClose}
        CloseModal={this.closeModal}
      />

    
       </>
      }
      {this.state.showOptionPrompt &&
        <>
        <DeleteModal
            data-test-id='option-prompt'
            openDialog={this.state.showOptionPrompt}
            headingText={`Where do you want to select the thumbnail from?`}
            btnOkText="Thumbnail library"
            btnCancelText="Local storage"
            handleCancel={() => this.closeOptionPrompt()}
            handleOk={() => this.handleOpenThumbnail()}
            promptOption={true}
            handleOptionOne={() => this.handleAddThumbnailFromLocal()}
          />
        </>
      }
      </>
    
    );
  }
 renderRightButton=()=>{
  return(
    <>
    <Box style={rightBtn}>
      {this.state.editTrue && 
           <Button   className="changethumb-btn archive">
             Archive
           </Button>
      }
      </Box></>
  )
 }
  renderCourseIDSection(value: string, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void) {
    return (
      <FormControl variant="standard" className="customInput" style={{ width: "100%" }}>
        <label className="labelStyle" style={{ ...labelText, color: "#787776" }}>Course ID</label>
        <CourseIdComponent sx={{ flexGrow: 1 }}>
          <TextField
            className={!this.state.isDisabled ? "" : "customDisabled"}
            value={value}
            name="course_id"
            id="bootstrap-input-courseID"
            placeholder={value?value:"CR-U420"}
            disabled={this.state.isDisabled}
            onChange={handleChange}
            style={{ width: "90%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ErrorOutlineOutlined />
                </InputAdornment>
              ),
            }}
          />
          <IconButton aria-label="edit" style={{ marginLeft: 1 }}>
            {(this.state.editTrue) ?
              <img src={editIconBlue} data-test-id="edit-modeID" onClick={this.handleEditMode} /> : ""
            }

          </IconButton>
        </CourseIdComponent>

        <Typography style={{ display: "flex", marginTop: "5px", alignItems: "center", fontSize: "12px", fontFamily: "'Poppins', sans-serif", color: "#787776" }}>
          <ErrorOutlineOutlined style={{ marginRight: "5px", width: "16px", height: "16px" }} />
          Automatic ID Assigned
        </Typography>
      </FormControl>
    );
  }
 renderPlaceholderDropdownContent(allExperts:any) {
  return <DropdownContent onMouseLeave={this.dropdownHandlerClose}>
    <TextFieldSearch
      variant="outlined"
      placeholder="Search"
      data-test-id="search_value"
      value={this.state.placeholderSearchText}
      onChange={(event)=>this.handlePlaceholderSearchChange(event)}
      InputProps={{
        startAdornment: (
          <SearchIcon />
        ),
      }}
    />
    <MaterialUIBox className="checkboxList">
      { allExperts.filter((option: any) => option.attributes.full_name.toLowerCase().includes(this.state.placeholderSearchText.toLowerCase())).map((option:any) => {
        return (
          <FormControlLabel   
          key={option.id} 
          className={this.props.formikProps.values.expert_id.toString()===option.id? "checkboxContent selectedStyle" : "checkboxContent"} control={<CheckboxMain  data-test-id={`select_expert_id${option.id}`} checked={this.props.formikProps.values.expert_id.toString()===option.id? true : false} name={option.attributes.full_name} test-id={`option_id${option.id}`} onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{this.placeholderCheckboxChangeHandler(event, option.id)}}/>} label={option.attributes.full_name} />
        )
      })}
    </MaterialUIBox>
  </DropdownContent>
}
  renderAssignedExpertSection(
    allExperts: any[],
    defaultExpertAttributes: any,
    touched: boolean,
    error: string,
    handleChange: (event: React.ChangeEvent<{}>) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    const handleAutocompleteError = () => {
      return touched && Boolean(error);
    };
    return (
      <FormControl variant="standard" className="customInput" style={Boxwidth}>
        <Typography className="labelStyle" style={labelText}>Assigned Xpert*</Typography>
          <StyledDropdownClickBox 
           onClick={(event) => this.dropdownHandlerOpen(event)} 
           style={{color:this.TextColor( this.props.formikProps?.values?.expert_id ),background: this.backColor( this.props.formikProps?.values?.expert_id ) }}
            data-test-id="selectButtonID"
      >
      <MaterialUIBox className="contentContainer contentRole">
      <MaterialUIBox className="innerContentBox">
        {
          this.props.formikProps?.values?.expert_id ? allExperts.filter((i)=>i.id==this.props.formikProps.values.expert_id).map(i=>i.attributes.full_name): "Select Xpert"
        }
        </MaterialUIBox>
        </MaterialUIBox>
       { this.state.anchorEl ?<ExpandLess/>: <ExpandMore />}
      </StyledDropdownClickBox>
      {this.state.anchorEl && this.renderPlaceholderDropdownContent(allExperts)}
        {touched && error && (
          <Typography data-test-id="errorTypographyID" style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{error}</Typography>
        )}
      </FormControl>
    );
  }
  renderDraftSaveButtons() {
    return (
      <Box sx={continueButtonContainer}>
        {this.state.editTrue ? (
          <>
            <DraftContainer data-test-id="save-and-close" onClick={this.clickedCloseSave}>
              Save & Close
            </DraftContainer>
            <DeleteModal
            data-test-id="save-and-clse-modal"
            openDialog={this.state.saveAndCloseModal}
            headingText={"Save changes?"}
              subHeading={"The changes you’ve made will apply to all users who have access to this page."}
              btnCancelText={"Cancel"}
              btnOkText={"Save"}
              handleCancel={this.handleCancel}
              handleOk={this.handleSaveData} />
          </>

        ) : (
          <DraftContainer data-test-id="save-draft-click" onClick={this.onClickedDraftOne}>
            Save to Draft
          </DraftContainer>

        )}
      </Box>
    );
  }

 renderContinueButton() {
    return (
      <Box sx={continueButtonContainer} data-test-id="add_content_btn_submit">
        {this.state.editTrue  ?
     (
          <>
            <HeaderContainer data-test-id="sav-add-content" onClick={this.handleEditContent} style={{ background: this.btnBackColor(), color: this.btnTextColor() }}>
              Save & Add Content
            </HeaderContainer>
            <DeleteModal
            data-test-id="save-add-content-modal"
             openDialog={this.state.saveContentModal}
              headingText={"Save changes?"}
              subHeading={"The changes you’ve made will apply to all users who have access to this page."}
              btnCancelText={"Cancel"}
              btnOkText={"Save"}
              handleCancel={this.handleCancelContent}
              handleOk={this.handleSaveContent} />
          </>

        ) : (
          <HeaderContainer data-test-id="sav-add-continue" onClick={this.handleContinue} style={{ background: this.btnBackColor(), color: this.btnTextColor() }}>
            Continue to Add Content
          </HeaderContainer>
        )}
      </Box>
    );
  }

  renderCourseDescriptionSection(value: string, touched: boolean, error: string, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void, handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void) {
    return (
      <Grid item container spacing={4} md={12} style={descContainer}>
        <Grid item md={12} xs={12}>
          <label className="labelStyle" style={labelText}>Course Description</label>
          <TextField
           className={value?"color_inut":"customInput"}
            fullWidth
            id="course_desc"
            name="description"
            value={value}
            error={touched && Boolean(error)}
            helperText={touched && error}
            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
              this.props.handleFormValChange("description",e.target.value)
              handleChange(e)
            }}
            onBlur={handleBlur}
            inputProps={{ maxLength: 1500 }}
            InputProps={{ style: descInput }}
            variant="outlined"
            placeholder="Please write your description"
            multiline
            rows={10}
            maxRows={12}
          />
          <Typography style={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", fontWeight: 400, lineHeight: "18px", textAlign: "left" }}>
            *A description will increase sales and improve the user experience.
          </Typography>
        </Grid>
      </Grid>
    );
  }


  renderCategories() {
    const selectedCategories = Array.isArray(this.props.formikProps?.values?.category) ? this.props.formikProps.values.category : [];
    return (
      <Box>
        <Box sx={categoriesHeader}>Categories</Box>
        <Box sx={categoriesContainer}>
          {this.state.category?.map((item, index) => {
            const isSelectedFromCategories = selectedCategories.some((selected: { name: string; }) => selected?.name === item.attributes?.name);
            const isInitiallySelected = item.attributes?.is_selected;
            const isSelected = isSelectedFromCategories || isInitiallySelected;
            return (
              <Box key={item.id}>
                <Button
                  data-test-id="selectCategoryFnID"
                  className={isSelected ? "contentRoleContent-btn btn-background" : "contentRoleContent-btn"}
                  variant="outlined"
                  onClick={() => {
                    this.selectCategoryFnOne(index, this.state.category);
                    if (!isSelectedFromCategories) {
                      this.props.handleFormValChange("categories", this.state.category);
                      this.props.formikProps?.setFieldValue("category", this.state.category);
                    }
                  }}
                >
                  {item.attributes?.name}
                </Button>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  renderduplicateConfirm() {
    return (
      <Box sx={continueButtonContainer}>
          <>
            {window.location.pathname !== "/CourseCreation/new/addCourse" && <HeaderButtons data-test-id="duplicate_button" onClick={this.handleDuplicateContent} style={{ background:"#652786", color: this.btnTextColor() }}>
             {configJSON.duplicateText}
            </HeaderButtons>}
            {this.state.duplicateContentModal && 
              <SaveAndDiscardChangesModal
              data-test-id="duplicate-content-modal"
              open={this.state.duplicateContentModal}
              onClose={this.handleCloseDuplicateContent}
              heading={configJSON.duplicateHeadingTxt}
              description={configJSON.duplicateSubText}
              leftButtonText={configJSON.cancelTxt}
              rightButtonText={configJSON.duplicateText}
              onLeftButtonClick={this.handleCloseDuplicateContent}
              onRightButtonClick={this.handleDuplicateSubmit} />             
            }               
          </>      
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { allExperts, defaultExpert, fileInput, thumbnailImg } = this.state;
    const { historyProp } = this.props;
    const isNewCourse = historyProp?.navigation?.getParam('id') === "new" && historyProp?.navigation?.getParam('type') === "addCourse";
    const defaultExpertAttributes = allExperts.length && allExperts.find(
      (expert: { attributes: { first_name: string; }; }) => expert.attributes.first_name.toLowerCase() === defaultExpert.value.toLowerCase()
    );
    let buttonText;
    if (isNewCourse) {
      buttonText = (this.props?.historyProp.location?.state?.imgUrl ? "Change Thumbnail" : "Add Thumbnail");
    } else {
      buttonText = "Update Thumbnail";
    }
    return (
      <Box>
        <ModalMainBody
          open={this.state.open}
          onClose={this.handleCancelClose}>
          <ModalContent>
            <IconAlign onClick={this.handleCancelClose}><CloseIcon /></IconAlign>
            <ModatText>Are you sure you'd like to save this as a draft?</ModatText>
            <ButtonBox>
              <ButtonGroup>
                <ButtonShape1 onClick={this.handleCancelClose} data-test-id="cancelBtnId">Cancel</ButtonShape1>
                <ButtonShape2 onClick={() => { this.handleCloseDraft() }} style={{ color: "white", background: "#652786" }} data-test-id="backDraftId">Save Draft</ButtonShape2>
                {this.state.toastOpen &&
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={this.state.toastOpen}
                    onClose={this.handleCancelClose}
                    message="Message is draft successfully"
                    key={0}
                  />
                }
              </ButtonGroup>
            </ButtonBox>
          </ModalContent>
        </ModalMainBody>
        <Box data-test-id="createCourseForm" sx={{ width: "100%" }}>          
          <Box sx={gridStyle}>          
            {this.renderTextField('Course name*', 'title', 'Course name here', this.props.formikProps?.values.title, this.props.formikProps?.touched.title, this.props.formikProps?.errors.title, this.props.formikProps?.handleChange)}
            {this.renderThumbnailSection(buttonText, fileInput, thumbnailImg)} 
                      
          </Box>
        </Box>
        <Box className="mainBox">
            {this.renderCourseIDSection(this.props.formikProps?.values.course_id, this.props.formikProps?.handleChange)}
            {this.renderAssignedExpertSection(allExperts, defaultExpertAttributes, this.props.formikProps?.touched.expert_id, this.props.formikProps?.errors.expert_id, this.props.formikProps?.handleChange, this.props.formikProps?.setFieldValue)}
              </Box>  
        <Box sx={addContentContainer}>
          <Button data-test-id="btn_setData" className="contentRole-btn">
            + Add Content Roles
          </Button>
        </Box>
        {this.renderCategories()}

        <Box>
          {this.renderCourseDescriptionSection(this.props.formikProps?.values.description, this.props.formikProps?.touched.description, this.props.formikProps?.errors.description, this.props.formikProps?.handleChange, this.props.formikProps?.handleBlur)}
        </Box>

        <Box>
          <Grid item container spacing={4} md={12}>
            <Grid item md={5} xs={12} style={{ padding: "15px" }}>
              {this.renderSwitchComponent()}
            </Grid>
            <Grid item md={7} xs={12} style={{ padding: "15px" }}>
              {this.renderCourseDetails()}
            </Grid>
          </Grid>
        </Box>

        {this.renderDraftSaveButtons()}
        {this.renderContinueButton()}

      </Box>
      // Customizable Area End

    );
  }
}

// Customizable Area Start


const StyledDropdownClickBox = styled('div')({
  marginTop:"6px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
  padding: "14px 16px !important",
  border: "1px solid #E5E2E1",
  borderRadius: "8px",
  backgroundColor: "#fcf6f6",
  cursor: "pointer",
  position:"relative",
  "&.selectCommonStyle .contentContainer":{
    display: "flex",
    width: "100%",
    gap: "3px",
  },
  "&.selectActive":{
    borderColor: "#652786",
    backgroundColor: "#EEE8F2"
  },
  "&.selectActive .title":{
    color: "#652786"
  },
 "&.creationDate":{
  display: "flex",
  justifyContent: "space-between"
 },
 "& .contentRole ":{
   display: "flex !important",
  width: "100% !important",
 justifyContent: "space-between !important"
 },
  "& .innerContentBox":{
    display: "flex",
    gap: "3px",
    fontSize:"16px",
    fontWeight:500,
    color:'#ADAAAA'
  },
  "& .title":{
    whiteSpace: "nowrap",
    fontSize: "16px",
    color: "#484646",
    fontWeight: 400,
    fontFamily: "Poppins, sans-serif"
  },
  "& .arrowIconStyle":{
    color: "#652786"
  }
});
const HeaderContainer = styled(Button)({
  width: "300px", height: "56px", borderRadius: "16px", textTransform: "none", color: "#fff", fontSize: "16px", lineHeight: "16px", fontWeight: 600, fontFamily: "'Poppins', sans-serif", "&:hover": {
    background: "#6c328b"
  }
});
const HeaderButtons = styled(Button)({
  width: "143px", height: "56px",marginLeft:"10px", borderRadius: "16px", 
  textTransform: "none", color: "#fff", fontSize: "16px", lineHeight: "16px", 
  fontWeight: 600, fontFamily: "'Poppins', sans-serif", "&:hover": {
  background: "#6c328b",marginLeft:"10px",   
  },
 
});

const DraftContainer = styled(Button)({
  width: "300px", height: "56px", textTransform: "none", textAlign: "center", color: "#E47004", fontSize: "16px", lineHeight: "16px", fontWeight: 600, fontFamily: "'Poppins', sans-serif", margin: "20px 0px", border: "1px solid #E47004", borderRadius: "16px"
});

const CourseIdComponent = styled(Box)({
  display: "flex",
})

const continueButtonContainer = { display: "flex", flexDirection: "row", justifyContent: "center" }

const labelText = { fontSize: "16px", fontWeight: 400, lineHeight: "24px", textAlign: "left" as const, color: "#1C1B1B", fontFamily: "'Poppins', sans-serif" }
  const Boxwidth={width:"100%"}
const descInput = {
  borderRadius: "15px",
  marginTop: "6px",
}
const switchContainer = { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }
const descContainer = { marginTop: "10px" }

const categoriesContainer = { display: "flex", flexWrap: "wrap", gap: "20px", marginTop: "20px" }

const categoriesHeader = { fontSize: "22px", fontWeight: "600" }

const courseHeader = { fontSize: "22px", fontFamily: "'Poppins', sans-serif", lineHeight: "33px", fontWeight: "600", borderBottom: "1px solid #E5E2E1", padding: "10px" }

const addContentContainer = { marginY: "40px" }

const courseLabel = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left" as const, fontFamily: "'Poppins', sans-serif"
}
const rightBtn={display:"flex"}

const gridStyle = {
  display: "flex",
  justifyContent:"space-between",
  gap:"60px",
  paddingBottom: "10px",
  "@media (max-width: 1100px)": {
    flexDirection:"column",
    gap:"10px"
  },
};

const DropdownContent = styled(MaterialUIBox)({
  position:"absolute",
  top:"88px",
  zIndex:9999,
  background:"#fff",
  width:"100%",
  padding: "8px 16px",
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
  borderRadius:"12px",
  "& .checkboxList": {
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "16px"
  },
  "& .checkboxContent": {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
    fontFamily: "Poppins , sans-serif",
    textTransform: "capitalize"
  },  "& .MuiTypography-body1":{
    fontFamily:"Poppins, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1C1B1B"
  },
  "& .selectedStyle": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent !important",
    borderRadius: "8px",
  },
  "& .selectedStyle .MuiTypography-body1":{
    color: "#542070 !important"
  },
  "& .checkboxContent:hover": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent",
    borderRadius: "8px",
  },
  "& .applyBtn": {
    display: "block",
    margin: "auto",
    color: "#542070",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
    borderBottom: "1px solid lightgray",
    fontFamily:"Poppins, sans-serif"
  },
  "& .react-calendar":{
    border: "unset",
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .react-calendar abbr":{
    textDecoration: "none"
  },
  "& .react-calendar__month-view__days__day":{
    width: "31px",
    height: "31px",
    borderRadius: "50%",
    fontWeight: 400,
    fontSize: "14px !important"
  },
  "& .react-calendar__month-view__weekdays":{
    fontWeight: 400,
    fontSize: "10px !important"
  },
  "& .react-calendar__tile--now:enabled":{
    backgroundColor: "#652786",
    color: "#fff"
  },
  "& .react-calendar__tile--now:enabled:hover":{
    backgroundColor: "#652786"
  },
  "& .react-calendar__month-view__days__day--weekend": {
    color: "rgba(0, 0, 0, 0.87)"
  },
  "& .react-calendar__month-view__days__day--neighboringMonth":{
    color: "#757575"
  },
  "& .react-calendar__tile--active":{
    color: "#fff",
    backgroundColor: "#652786 !important"
  },
  "& .react-calendar__year-view__months__month":{
    width: "74px",
    height: "74px",
    borderRadius:"50%"
  },
  "& .react-calendar__navigation__prev2-button":{
    display: "none"
  },
  "& .react-calendar__navigation__next2-button":{
    display: "none"
  },
  "& .react-calendar__navigation button:enabled:hover":{
    backgroundColor: "unset"
  },
  "& .react-calendar__navigation button":{
    minWidth: "auto"
  },
  "& .calendarBtnContainer":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: "16px"
  }
});
const TextFieldSearch = styled(TextField)({
  width:"100%",
  "& .MuiOutlinedInput-notchedOutline":{
    backgroundColor: "#fcf6f6"
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray"
  },
  "& .MuiSvgIcon-root":{
    position: "relative",
    zIndex: 1,
    color: "gray"
  },
  "& .MuiOutlinedInput-root":{
    borderRadius: "12px",
    height: "48px"
  },
  "& .MuiOutlinedInput-input":{
    position: "relative",
    zIndex: 1,
  },
  "& .MuiOutlinedInput-inputAdornedStart":{
    paddingLeft: "6px"
  }
});
const CheckboxMain = styled(Checkbox)({
  color: '#652786 !important',
});
const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "200px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  "@media (max-width: 656px)": {
    maxWidth:"400px",
  },
  "@media (max-width: 400px)": {
    maxWidth:"330px",
  },
  padding: "0px 20px 0px 20px"
});

const ModatText = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 500,
  fontSize: "22px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const ModatText1 = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 400,
  fontSize: "18px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const IconAlign = styled(Box)({
  position: "absolute",
  top: "-10",
  right: "-10",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white"
});

const ButtonGroup = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: "25px"
});

const ModalMainBody = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center"
});

const ButtonShape1 = styled(Button)({
  width: "228px",
  height: "42px",
  fontWeight:600,
  fontSize:"16px",
  background: "1px solid #652786 !important",
  border: "1px solid #E47004",
  borderRadius:"12px",
  color: "#E47004",
  textTransform: "none",
});

const ButtonShape2 = styled(Button)({
  width: "228px",
  height: "42px",
  background: "1px solid ##652786 !important",
  borderRadius:"12px",
  fontWeight:600,
  fontSize:"16px",
  color: "#E47004",
  textTransform: "none",
});
const CourseArchiveButton=styled(Button)({

})
const ButtonBox = styled(Box)({
  padding:"10px 10px 0px 10px",
  width:"100%"
});

// Customizable Area End
