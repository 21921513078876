// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { apiCall } from "../../utilities/src/NetworkRequest";

// Customizable Area Start

export interface Library {
  id: string;
  type: string;
  attributes: {
    id: number;
    status: string;
    title: string;
    name: string;
    thumbnail_image: {
      id: string;
      url: string;
    }
    content_type: string;
  }
}

export interface LibraryContentRole {
  id: string;
  type: string;
  attributes: {
    id: number;
    status: string;
    title: string;
    name: string;
    thumbnail_image: {
      id: string;
      url: string;
    }
    content_type: string;
  }
}

export interface ItemType {
  id: number;
}

export interface UserResponse {
  id: string;
  attributes: UserAttributes;
}

export interface UserAttributes {
  id: number;
  full_name: string;
}

export interface BranchResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  }
}

export interface AssignedCourses {
  id: string;
  type: string;
  attributes: {
    id: number;
    content_id: number;
    content_type: string;
    content: AssignedCoursesContent;
  };
}

export interface AssignedCoursesContent {
  id: number,
  title: string,
  status: string,
  thumbnail_image: {
    id: string;
    url: string;
  }
  content_id: number,
  content_type: string
}

export interface AssignedContentRole{
    id: string,
    type: string,
    attributes: {
      id: number,
      content_id: number,
      content_type: string,
      content: {
        id: number,
        title: string,
        status: string
      }
    }
  
}

export interface ExpertListing {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    profession: string,
    full_name:string,
    avatar: null,
    is_admin: boolean,
  }
}

export interface AdminCatagoryListing {
  id: string,
  type: string,
  attributes: {
    name: string,
  }
}

export interface AdminContentRoles {
  id: string;
     type: string;
     attributes:
      { id: number;
  uniq_id:string; 
  name:string; 
  description:string; 
  status:string;
  is_sellable: boolean;
  price: string;
  expert_id: number;
  expert_type: string;
  created_at: string;
  subscription_period: string;
  is_selected: boolean;
   thumbnail_image: null;
   };
   }

interface Pagination {
    curPage: number;
    nextPage: number | null;
    prevPage: number | null;
  }


export interface AttributesType {
  id: number;
  name: string
  uniq_id: string;
  lessons_count: number;
  status: string;
  updated_at: string;
  is_selected: boolean;
}
export interface ContentRolesType {
  id: string,
  type: string,
  attributes: AttributesType;
}


export interface CheckboxSelectedListType { value: string, id: string }

export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  isCourseActive: string;
  searchInputValue: string;
  showList: boolean;
  libraryData: Library[];
  programData: Library[];
  contentRoleData: LibraryContentRole[];
  selectedContent: Library[];
  selectedContentProgramData: Library[],
  checkedData: boolean;
  checkedDataProgram: boolean
  removedProgramData: Library[];
  teamsToAdd: Library[], 
  teamsToDelete:any[];
  removedAllData: Library[];
  newlyAddedData: Library[];
  groupDropdown: boolean;
  selectedGroup: string;
  assigneeDropdown: boolean;
  selectedAssignee: string;
  previousAssignee: string;
  UserData: Array<UserResponse>;
  UserDataIds: Array<UserResponse>;
  branchData: Array<BranchResponse>;
  branchDataIds: Array<BranchResponse>;
  isCourseEnabled: boolean;
  isProgramEnabled: boolean;
  isContentRoleEnabled: boolean;
  selectedUserId: string | null;
  selectedBranchId: string | null;
  filteredList: Array<string>;
  anchorFilterContainerEl: null | HTMLElement
  placeholderSearchBranchText:string;
  placeholderSearchUsersText:string;
  assignedContentCoursesData: AssignedCourses[];
  assignedContentProgramData: AssignedCourses[];
  assignedContentRoleData: AssignedContentRole[];
  openGroupDialog: boolean;
  lastSelectedUserId: string | null;
  lastSelectedBranchId: string | null;
  creationDate: string;
  selectedStatus: string;
  contentRoleTrue:boolean;
  contentRolesParams: string;
  catagoryParams:string;
  contentRoleSelectedItems: Array<CheckboxSelectedListType>;
  contentRoleSearchText: string;
  categorySelectedItems: Array<CheckboxSelectedListType>;
  categorySearchText: string;
  placeholderSelectedItems: Array<CheckboxSelectedListType>;
  placeholderSearchText: string;
  anchorEl: null | HTMLElement;
  dropdownType: string ;
  isCalendarOpen: boolean;
  placeholderParams: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  filterItems: boolean;
  expertAdminList: Array<ExpertListing>;
  adminCatagoryList: Array<AdminCatagoryListing>;
  adminContentRolesList: Array<AdminContentRoles>;
  pagination: Pagination;
  contentRolesData:Array<ContentRolesType>;
  isRemoveButtonClicked: boolean;
  }

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageAssignController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCourseListDataCallId: string = "";
  getCourseAssignedApiCallId: string = "";
  getProgramsAssignedApiCallId: string = "";
  getAssignedContentRoleApiCallId: string = "";
  getProgramListDataCallId: string = "";
  getContentRoleListDataCallId: string = "";
  createAssignContentCallId: string = "";
  createUserBranchAssignContentCallId: string = "";
  getCatalogueAdvanceSearchApiCallId: string = "";
  userListApiCallId: string = "";
  userListApiCallIdWithUserID: string = "";
  branchListApiCallIdWithUserID: string = "";
  branchListApiCallId: string = "";
  expertAdminListingAPICallId: string = "";
  adminCatagoryListingAPICallId: string = "";
  adminContentRolesListingAPICallId: string = "";
  contentRolesApiCallId:string = "";
  dropdownRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isCourseActive: "course",
      showList: false,
      searchInputValue: '',
      removedAllData: [],
      libraryData: [],
      programData: [],
      contentRoleData: [],
      removedProgramData: [],
      teamsToAdd: [], 
      teamsToDelete: [],
      newlyAddedData: [],
      selectedContent: [],
      selectedContentProgramData: [],
      checkedData: false,
      checkedDataProgram: false,
      groupDropdown: false,
      selectedGroup: '',
      assigneeDropdown: false,
      selectedAssignee: '',
      previousAssignee: '',
      UserData: [],
      UserDataIds: [],
      branchData: [],
      branchDataIds: [],
      isCourseEnabled: true,
      isProgramEnabled: true,
      isContentRoleEnabled: true,
      selectedUserId: '',
      selectedBranchId: '',
      filteredList: [],
      anchorFilterContainerEl: null,
      placeholderSearchBranchText: '',
      placeholderSearchUsersText: '',
      assignedContentCoursesData: [],
      assignedContentProgramData: [],
      assignedContentRoleData:[],
      openGroupDialog: false,
      lastSelectedUserId: null,
      lastSelectedBranchId: null,
      creationDate: "",
      selectedStatus: "",
      contentRoleTrue:false,
      contentRolesParams: "",
      catagoryParams: "",
      contentRoleSelectedItems:[],
      contentRoleSearchText:"",
      categorySelectedItems:[],
      categorySearchText: "",
      placeholderSelectedItems: [],
      placeholderSearchText: "",
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      placeholderParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      filterItems: true,
      expertAdminList: [],
      adminCatagoryList:[],
      adminContentRolesList:[],
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      contentRolesData:[],
      isRemoveButtonClicked: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    // Customizable Area Start
    this.dropdownRef = React.createRef();
    // Customizable Area End
  }

  async componentDidMount() {
    this.getUserList();
    this.getBranchList();
    let userDataId = this.props?.navigation?.getParam('id')
    this.getCourseList('',userDataId);
    this.getProgramList("",(userDataId))
    this.getContentRoleList("",(userDataId))
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAdminCategoryListing();
    this.getAdminExpertListing();
    this.getAdminContentRolesListing({ search: "", page: "", per: "", }, this.state.pagination.curPage);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.createAssignContentCallId === apiRequestCallId) {
        this.createAssignContent(from, message, responseJson)
      }
      if (this.createUserBranchAssignContentCallId === apiRequestCallId) {
        if (responseJson) {
          this.postCreateUserBranch()
        }
      }

      if (apiRequestCallId === this.userListApiCallId) {
        this.setState({
          UserData: responseJson.data,
        })
      }
      if (apiRequestCallId === this.userListApiCallIdWithUserID) {
        this.setState({
          UserDataIds: responseJson.data,
        })
      }
      if (apiRequestCallId === this.branchListApiCallId) {
        this.setState({
          branchData: responseJson.data,
        })
      }
      if (apiRequestCallId === this.branchListApiCallIdWithUserID) {
        this.setState({
          branchDataIds: responseJson.data,
        })
      }
      if (this.getCourseListDataCallId === apiRequestCallId) {
        this.setState({ libraryData: responseJson?.data })
      }

      if (this.getProgramListDataCallId === apiRequestCallId) {
        this.setState({ programData: responseJson?.data })
      }
      if (this.getContentRoleListDataCallId === apiRequestCallId) {
        this.setState({ contentRoleData: responseJson?.data })
      }
      if (this.getCatalogueAdvanceSearchApiCallId === apiRequestCallId) {
        this.setState({
          filteredList: responseJson.data
        })
        this.catalogueResponse(responseJson)
      }
      if (apiRequestCallId === this.getCourseAssignedApiCallId) {
        const assignedCoursesData = responseJson?.data;
        this.setState({
            assignedContentCoursesData: assignedCoursesData,
            removedAllData: [
                ...this.state.removedAllData,
                ...assignedCoursesData.map((item: AssignedCourses) => {
                    return {
                        id: item.id,
                        type: item.type,
                        attributes: {
                            id: item.attributes.id,
                            status: item.attributes.content.status,
                            name: item.attributes.content.title,
                            title: item.attributes.content.title,
                            thumbnail_image: item.attributes.content.thumbnail_image.url || null,
                            content_type: item.attributes.content.content_type,
                        }
                    };
                })
            ]
        });
    }
    if (apiRequestCallId === this.getProgramsAssignedApiCallId) {
      const assignedCoursesData = responseJson?.data;
      this.setState({
        assignedContentProgramData: assignedCoursesData,
          removedAllData: [
              ...this.state.removedAllData,
              ...assignedCoursesData.map((item: AssignedCourses) => {
                  return {
                      id: item.id,
                      type: item.type,
                      attributes: {
                          id: item.attributes.id,
                          status: item.attributes.content.status,
                          name: item.attributes.content.title,
                          title: item.attributes.content.title,
                          thumbnail_image: item.attributes.content.thumbnail_image.url || null,
                          content_type: item.attributes.content.content_type,
                      }
                  };
              })
          ]
      });
  }
    if (apiRequestCallId === this.getAssignedContentRoleApiCallId) {
      const assignedContentRoleData = responseJson?.data;
      this.setState({
        assignedContentRoleData: assignedContentRoleData,
          removedAllData: [
              ...this.state.removedAllData,
              ...assignedContentRoleData.map((item: AssignedContentRole) => {
                  return {
                      id: item.id,
                      type: item.type,
                      attributes: {
                          id: item.attributes.id,
                          content_type: item.attributes.content_type,
                          status: item.attributes.content.status,
                          title: item.attributes.content.title,
                      }
                  };
              })
          ]
      });
  }
  if (this.expertAdminListingAPICallId === apiRequestCallId) {
    this.setState({
      expertAdminList: responseJson.data
    }) 
  }

  if (this.adminCatagoryListingAPICallId === apiRequestCallId) {
    this.setState({
      adminCatagoryList: responseJson.data
    })
  }
  if (this.adminContentRolesListingAPICallId === apiRequestCallId) {
    this.setState({
      adminContentRolesList: responseJson.data
    })
  }

  if (this.contentRolesApiCallId === apiRequestCallId) {
    this.setState({
      contentRolesData: responseJson.data
    })
    
  }

    }
    // Customizable Area End
  }

  postCreateUserBranch = () => {
    const value = this.state.selectedAssignee;
    const selectedUser = this.state.UserData.find(user => user.attributes.full_name === value);
    const selectedBranch = this.state.branchData.find(branch => branch.attributes.name === value);
   
    if (selectedUser) {
      this.setState({
        selectedUserId: selectedUser.id,
        selectedBranchId: null,
        assigneeDropdown: false,
      });
    } else if (selectedBranch) {
      this.setState({
        selectedBranchId: selectedBranch.id,
        selectedUserId: null,
        assigneeDropdown: false
      });
    }
  }

  handleUserManageData = (id: string) => {
    if (this.state.lastSelectedUserId === id) {
      return;
    }
    this.setState({ lastSelectedUserId: id }, () => {
      this.getUserListWithId(id);
      this.getCourseList('', id);
      this.getProgramList('', id);
      this.getContentRoleList('', (id));
      this.getAssigenedCourses(Number(id));
      this.getAssigenedPrograms(Number(id));
      this.getAssigenedContentRole(Number(id));
    });
  };
  
  handleBranchManageData = (id: string) => {
    if (this.state.lastSelectedBranchId === id) {
      return;
    }
  
    this.setState({ lastSelectedBranchId: id }, () => {
      this.getBranchListWithId(id); 
      this.getCourseList('', id);
      this.getProgramList('', id);
      this.getContentRoleList('', (id));
      this.getAssigenedCourses(Number(id));
      this.getAssigenedPrograms(Number(id));
      this.getAssigenedContentRole(Number(id));
    });
  };

  handlePlaceholderUserBranchSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { selectedGroup } = this.state;
    const searchText = event.target.value;
  
    if (selectedGroup === 'Users') {
      this.setState({ placeholderSearchUsersText: searchText }, () => {
        this.getUserList()
      });
    } else if (selectedGroup === 'Branches') {
      this.setState({ placeholderSearchBranchText: searchText }, () => {
        this.getBranchList()
      });
    }
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.setState({ assigneeDropdown: false, groupDropdown: false });
    }
  };

  catalogueResponse = (responseJson: { data: Library[] }) => {
    if (!responseJson.data?.length) {
      if (this.state.isCourseActive === "course") {
        let userDataId = this.props?.navigation?.getParam('id')
      this.getCourseList('',(userDataId));
      } else if (this.state.isCourseActive === "program") {
        let userDataId = this.props?.navigation?.getParam('id')
        this.getProgramList('',(userDataId));
      } else if (this.state.isCourseActive === "content_role") {
        let userDataId = this.props?.navigation?.getParam('id')
        this.getContentRoleList('',(userDataId));
      }
    }
  };

  handleCreateTeamDisable = () => {
    if (
        (this.state.selectedGroup && this.state.newlyAddedData.length) ||
        (this.state.selectedUserId && this.state.newlyAddedData.length) ||
        (this.state.selectedBranchId && this.state.newlyAddedData.length) ||
        this.state.isRemoveButtonClicked
    ) {
        return true;
    } else {
        return false;
    }
}

  createAssignContent = (from: string, message: Message, responseJson: any) => {
    if (responseJson?.data) {
      toast.success(`Changes Saved!`);
    } else {
      responseJson?.errors.map((err: { message: string }) => {
        toast.error(err.message);
      });
    }
    this.setState({
      placeholderSearchUsersText:"",
      placeholderSearchBranchText:""
    })
    this.getUserList();
    this.getBranchList();
  };

  handleToggleCourses = () => {
    this.setState({
      isCourseActive: "course",
      searchInputValue: '',
      contentRoleTrue:false
    }, () => {
    })
    this.handleClearFilter()
  };

  handleTogglePrograms = () => {
    this.setState({
      isCourseActive: "program",
      searchInputValue: '',
      contentRoleTrue:false,
    }, () => {
    })
    this.handleClearFilter()
  };

  handleToggleContentRole = () => {
    this.setState({
      isCourseActive: "content_role",
      searchInputValue: '',
      contentRoleTrue:true,
    }, () => {
    })
    this.handleClearFilter()
  };

  handleCheckboxChange = (data: Library) => {
    let selectedData;
    if (this.state.selectedContent.includes(data)) {
      selectedData = this.state.selectedContent.filter((library) => library.id !== data.id);
    } else {
      selectedData = [...this.state.selectedContent, data];
    }
    this.setState({ selectedContent: selectedData });
  };

  handleRemoveCheckboxChangeProgram = (data: Library) => {
    let selectedData;
    if (this.state.removedProgramData.includes(data)) {
      selectedData = this.state.removedProgramData.filter((library) => library.id !== data.id);
    } else {
      selectedData = [...this.state.removedProgramData, data];
    }
    this.setState({ removedProgramData: selectedData });
  };

  handleAddButtonClick = () => {
    if (this.state.selectedContent.length > 0) {
      const newContent = this.state.selectedContent.filter(
        (selectedItem) =>
          !this.state.removedAllData.some((existingItem) => existingItem.id === selectedItem.id)
      );
      this.setState((prevState) => ({
        removedAllData: [...prevState.removedAllData, ...newContent],
        newlyAddedData: [...prevState.newlyAddedData, ...newContent],
        checkedData: true,
        selectedContent: []
      }));
    }
  };

  handleRemoveButtonClickProgram = () => {
    if (this.state.removedAllData.length > 0) {
      const filteredData = this.state.removedAllData.filter((item: Library) => 
        !this.state.removedProgramData.includes(item)
      )
      const itemsToRemove = this.state.removedProgramData.map(item => item.id);
      const courseData = this.state.removedProgramData.filter((filter) => this.state.libraryData.includes(filter))
      const programData = this.state.removedProgramData.filter((filter) => this.state.programData.includes(filter))
      this.setState({
        programData: [...this.state.programData, ...programData],
        libraryData: [...this.state.libraryData, ...courseData],
        removedAllData: filteredData, selectedContentProgramData: filteredData, selectedContent: [], 
        isRemoveButtonClicked: true,
        removedProgramData: [],
        teamsToDelete: [...this.state.teamsToDelete, ...itemsToRemove],
      });
      if (filteredData.length === 0) {
        this.setState({
          checkedDataProgram: false,
        });
      }
    }
  };

  isCheckedValue = (arrayData: Library[], value: Library) => {
    return arrayData.includes(value)
  }
 
  continueButtonDialog = () => {
    this.setState({ openGroupDialog: !this.state.openGroupDialog,
      assignedContentCoursesData:[],
      assignedContentProgramData:[],
      assignedContentRoleData:[],
     })
  }

  cancelGroupDialog = async () => {
    const { previousAssignee, UserData, branchData } = this.state;
    
    const previousUser = UserData.find(user => user.attributes.full_name === previousAssignee);
    const previousBranch = branchData.find(branch => branch.attributes.name === previousAssignee);
    if (previousUser) {
      await this.getAssigenedCourses(Number(previousUser.id));
      await this.getAssigenedPrograms(Number(previousUser.id));
      await this.getAssigenedContentRole(Number(previousUser.id));
    }else if(previousBranch){
      await this.getAssigenedCourses(Number(previousBranch.id));
      await this.getAssigenedPrograms(Number(previousBranch.id));
      await this.getAssigenedContentRole(Number(previousBranch.id));
    }  
    this.setState({ 
      selectedAssignee: previousAssignee, 
      openGroupDialog: false ,
      assignedContentCoursesData:[],
      assignedContentProgramData:[],
      assignedContentRoleData:[],
      removedAllData:[]
    });
  };

  getCourseList = async (search: string, userId: string) => {
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseListDataCallId = requestMessage.messageId;
    let userType = "AccountBlock::Account";

    if (this.state.selectedGroup === "Branches") {
      userType = "BxBlockOrganisationhierarchy::Branch";
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listCourseDataEndPoint + `user_id=${userId}&user_type=${userType}&search=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAssigenedCourses = async (userId: number) => {
    let userType = "AccountBlock::Account";

    if (this.state.selectedGroup === "Branches") {
      userType = "BxBlockOrganisationhierarchy::Branch";
    }
    this.getCourseAssignedApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.assignedCoursesLists}user_id=${userId}&user_type=${userType}`,
    });
  };

  getProgramList = async (search: string,userId: string) => {
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProgramListDataCallId = requestMessage.messageId;
    let userType = "AccountBlock::Account";

    if (this.state.selectedGroup === "Branches") {
      userType = "BxBlockOrganisationhierarchy::Branch";
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listProgramDataEndPoint + `user_id=${userId}&user_type=${userType}&search=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAssigenedPrograms = async (userId: number) => {
    let userType = "AccountBlock::Account";

    if (this.state.selectedGroup === "Branches") {
      userType = "BxBlockOrganisationhierarchy::Branch";
    }
    this.getProgramsAssignedApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.assignedProgramsLists}user_id=${userId}&user_type=${userType}`,
    });
  };

  getAssigenedContentRole = async (userId: number) => {
    let userType = "AccountBlock::Account";

    if (this.state.selectedGroup === "Branches") {
      userType = "BxBlockOrganisationhierarchy::Branch";
    }
    this.getAssignedContentRoleApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.assignedContentRoleLists}user_id=${userId}&user_type=${userType}`,
    });
  };

  getContentRoleList = async (search: string,userId: string) => {
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContentRoleListDataCallId = requestMessage.messageId;
    let userType = "AccountBlock::Account";

    if (this.state.selectedGroup === "Branches") {
      userType = "BxBlockOrganisationhierarchy::Branch";
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listContentRoleDataEndPoint + `user_id=${userId}&user_type=${userType}&search=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  groupdropdownOpen = (event: any) => {
    this.setState({
      groupDropdown: !this.state.groupDropdown,
    })
  }

  handleGroupChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedGroup = event.target.value;
    const shouldShowDialog = this.state.removedAllData.length > 0;
    if (selectedGroup === 'Users') {
      await this.getUserList();
      if (shouldShowDialog) {
        this.setState({
          openGroupDialog: !this.state.openGroupDialog
        });
      }
    } else if (selectedGroup === 'Branches') {
      await this.getBranchList();
      if (shouldShowDialog) {
        this.setState({
          openGroupDialog: !this.state.openGroupDialog
        });
      }
    }
    this.setState({
      selectedGroup,
      isCourseEnabled: selectedGroup === 'Branches' || selectedGroup === 'Users',
      isProgramEnabled: selectedGroup === 'Branches' || selectedGroup === 'Users',
      isContentRoleEnabled: selectedGroup === 'Branches' || selectedGroup === 'Users',
      assigneeDropdown: false, groupDropdown: false, selectedAssignee: "",
      placeholderSearchBranchText: "", placeholderSearchUsersText: "",
    });
  };


  assigneedropdownOpen = () => {
    this.setState({
      assigneeDropdown: !this.state.assigneeDropdown,
    })
  }

  handleAssigneeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const selectedUser = this.state.UserData.find(user => user.attributes.full_name === value);
    const selectedBranch = this.state.branchData.find(branch => branch.attributes.name === value);
    const shouldShowDialog = this.state.removedAllData.length > 0;
  
    if (shouldShowDialog) {
      this.setState({
        openGroupDialog: !this.state.openGroupDialog
      });
    }
    if (selectedUser) {
      this.setState({
        previousAssignee: this.state.selectedAssignee,
        selectedAssignee: value,
        selectedUserId: selectedUser.id,
        selectedBranchId: null,
        assigneeDropdown: false,
        assignedContentCoursesData:[],
        assignedContentProgramData:[],
        assignedContentRoleData:[],
        removedAllData:[]
      }, () => {
        this.postCreateUserBranchAssign()
      });
    } else if (selectedBranch) {
      this.setState({
        previousAssignee: this.state.selectedAssignee,
        selectedAssignee: value,
        selectedBranchId: selectedBranch.id,
        selectedUserId: null,
        assigneeDropdown: false
      }, () => {
        this.postCreateUserBranchAssign()
      });
    }
  };

  getUserList = async () => {
    this.userListApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.userListEndPoint}?search=${this.state.placeholderSearchUsersText}`,
    });
  };

  getUserListWithId = async (userId: string) => {
    this.userListApiCallIdWithUserID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.userListEndPoint}/${userId}`,
    });
  };

  getBranchList = async () => {
    this.branchListApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.branchListEndPoint}?search=${this.state.placeholderSearchBranchText}`,
    });
  };

  getBranchListWithId = async (branchId: string) => {
    this.branchListApiCallIdWithUserID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.branchListEndPoint}/${branchId}`,
    });
  };

  UpdateData = (teamsAccountsAttributes: any[]) => {
    const selectedTeamIds = this.state.newlyAddedData.map((team: any) => team?.attributes?.id);
    const teamsToDeleteIds = this.state.removedProgramData.map((team: any) => team?.attributes?.id
    );
    const teamsToRemove = teamsToDeleteIds.filter((id: number) => !selectedTeamIds.includes(id));
    const teamsToAdd = selectedTeamIds.filter((id: number) => !teamsToDeleteIds.includes(id));
    if (teamsToRemove?.length) {
      teamsToRemove.forEach((id: number) => {
        teamsAccountsAttributes.push({
          id: id,
          _destroy: true,
        });
      });
    }
    if (teamsToAdd?.length) {
      teamsToAdd.forEach((id: number) => {
        const isProgram = this.state.programData.some(item => item?.attributes?.id === id);
        const isCourse = this.state.libraryData.some(item => item?.attributes?.id === id);
        const isContentRole = this.state.contentRoleData.some(item => item?.attributes?.id === id);
        let contentType = "";
        if (isProgram) {
          contentType = "BxBlockCoursecreation::Program";
        } else if (isCourse) {
          contentType = "BxBlockCoursecreation::Course";
        } else if (isContentRole) {
          contentType = "BxBlockCoursecreation::ContentRole";
        }
        teamsAccountsAttributes.push({
          content_id: id,
          content_type: contentType,
          _destroy: false,
        });
      });
    }
    return teamsAccountsAttributes;
  };

  postCreateUserBranchAssign = async () => {   
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    let userId;
    let userType;

    if (this.state.selectedGroup === "Users") {
      userId = this.state.selectedUserId;
      userType = "AccountBlock::Account";
    } else if (this.state.selectedGroup === "Branches") {
      userId = this.state.selectedBranchId;
      userType = "BxBlockOrganisationhierarchy::Branch";
    }

    const httpBody = {
      user: {
        user_id: userId,
        user_type: userType,
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createUserBranchAssignContentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postUserBranchEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  postUserAssign = async (values: any) => {
    const { teamsToAdd, teamsToDelete } = this.state;
    const teamsAccountsAttributes : any[] = [];
   
    if (teamsToAdd?.length) {
      teamsToAdd.forEach((team: any) => {
          teamsAccountsAttributes.push({
              team_id: team.id, 
          });
      });
    }

    if (teamsToDelete?.length) {
      teamsToDelete.forEach((id: number) => {
          teamsAccountsAttributes.push({
              id: id,
              _destroy: true, 
          });
      });
    }
    this.UpdateData(teamsAccountsAttributes);
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    let userId;
    let userType;

    if (this.state.selectedGroup === "Users") {
      userId = this.state.selectedUserId;
      userType = "AccountBlock::Account";
    } else if (this.state.selectedGroup === "Branches") {
      userId = this.state.selectedBranchId;
      userType = "BxBlockOrganisationhierarchy::Branch";
    }

    const httpBody = {
      user: {
        user_id: userId,
        user_type: userType,
        user_contents_attributes: teamsAccountsAttributes
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAssignContentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createUserAssignContentEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleListItemClickSuggestion = (item: string) => {
    this.setState({
      searchInputValue: item,
      showList: false
    }, () => {
      this.getCourse({
        search: this.state.searchInputValue,
        filter: this.state.isCourseActive,
        page: "",
        per: "",
      }, 1)
    })
  }

  getCourse(params: { search: string; filter: string , page: string, per: string  }, pagination: number | Pagination) {
    if (this.state.isCourseActive === "course") {
      const customEndPoint = this.constructEndPoint({ ...params, filter: "course" },pagination);
      this.doGetCourse({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.courseAndProgramAPIEndPoint + customEndPoint,
      });
      return customEndPoint;
    } else if (this.state.isCourseActive === "program") {
      const customEndPoint2 = this.constructEndPoint({ ...params, filter: "program" },pagination);
      this.doGetProgram({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.courseAndProgramAPIEndPoint + customEndPoint2,
      });
      return customEndPoint2;
    } else if (this.state.isCourseActive === "content_role") {
      const customEndPoint3 = this.constructEndPoint({ ...params, filter: "content_role" },pagination);
      this.doGetContentRole({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.courseAndProgramAPIEndPoint + customEndPoint3,
      });
      return customEndPoint3;
    }
  }

  constructEndPoint(params: { search: string, filter?: string, page: string, per: string }, pagination: number | Pagination): string {
    let endPoint = "?";

    endPoint += `course_type=${params.filter}&`;
    endPoint += `sort_type=desc&per=${params.per}&page=${pagination}`;
    
    if (params.search) {
      endPoint += `&search=${params.search}`;
    }
    endPoint += this.addStatusParam();
    endPoint += this.addPlaceholderParams();
    endPoint += this.addContentRolesParams();
    endPoint += this.addCategoryParams();
    endPoint += this.addCreationDateParam();
    endPoint += this.addCreationDateRangeParam();

    return endPoint;
  }

  private addStatusParam(): string {
    return this.state.selectedStatus ? `&q[status]=${this.state.selectedStatus}` : '';
  }

  private addPlaceholderParams(): string {
    return this.state.placeholderParams ? `&${this.state.placeholderParams}` : '';
  }

  private addContentRolesParams(): string {
    return this.state.contentRolesParams ? `&${this.state.contentRolesParams}` : '';
  }

  private addCategoryParams(): string {
    return this.state.catagoryParams ? `&${this.state.catagoryParams}` : '';
  }

  private addCreationDateParam(): string {
    const dateParam = this.getDateParam();
    return dateParam ? `&q[created_at]=${dateParam}` : ''
  }

  private addCreationDateRangeParam(): string {
    const { start_date, end_date } = this.state.creationDateParams;
    if (start_date && end_date) {
      const rangeObj = {
        start_date: start_date,
        end_date: end_date
      };
      const queryString = encodeURIComponent(JSON.stringify(rangeObj));
      return `&q[custom_range]=${queryString}`;
    }
    return '';
  }

  private getDateParam(): string {
    switch (this.state.creationDate) {
      case "Last Week":
        return "last_week";
      case "Last Month":
        return "last_month";
      case "Last Year":
        return "last_year";
      default:
        return '';
    }
  }

  doGetCourse(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { method, endPoint } = data;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseListDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetProgram(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramListDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetContentRole(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentRoleListDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleInputCatalogueChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.setState({
      searchInputValue: value,
      showList: value !== "",
    }, () => {
      this.getCatalogueAdvanceSearch(value)
    });
  }

  getCatalogueAdvanceSearch = (value: string) => {
    this.doGetCatalogueAdvanceSearch({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.catagoryAdvanceSearch + `?search=${value}&type=${this.state.isCourseActive}`,
    });
  }

  doGetCatalogueAdvanceSearch(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCatalogueAdvanceSearchApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getAdminContentRolesListing(params: { search: string, page: string, per: string }, pagination: number | Pagination) {
    let customEndPoint = this.constructEndPoint(params, pagination);
    this.doAdminContentRolesListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.contentRoleListingApiEndPoint,
    });
    this.doContentRolesListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.contentRoleListingApiEndPoint + customEndPoint,
    })
    
  }
  doContentRolesListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.contentRolesApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  doAdminContentRolesListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.adminContentRolesListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getAdminCategoryListing() {
    this.doAdminCategoryListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.catagoryListingApiEndPoint + `?search=${this.state.categorySearchText}`,
    });
  }

  doAdminCategoryListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.adminCatagoryListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getAdminExpertListing() {
    this.doAdminExpertListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.expertAdminListApiEndPoint + `?search=${this.state.placeholderSearchText}`,
    });
  }


  doAdminExpertListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.expertAdminListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  contentRoleCheckboxHandlerChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        contentRoleSelectedItems: [...prevState.contentRoleSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        contentRoleSelectedItems: prevState.contentRoleSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    }
  }

  handleContentRoleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      contentRoleSearchText: event.target.value
    })
  }

  categoryCheckboxHandlerChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    this.setState(prevState => {
      let updatedItems;
      if (checked) {
        updatedItems = [...prevState.categorySelectedItems, { value: name, id: itemId }];
      } else {
        updatedItems = prevState.categorySelectedItems.filter(item => item.id !== itemId);
      }

      const params = this.buildCategoryParams(updatedItems);
      return { categorySelectedItems: updatedItems, catagoryParams: params };
    });
  }

  buildCategoryParams = (items: { value: string, id: string }[]) => {
    const categoryIdsKey = this.state.isCourseActive === "course" ? 'category_ids_c' : 'category_ids_p';
    return items.map(item => `q[${categoryIdsKey}][]=${item.id}`).join('&');
  }

  handleCategoryChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      categorySearchText: event.target.value
    })
  }

  placeholderCheckboxAssignedExpertChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectedItems: [...prevState.placeholderSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectedItems: prevState.placeholderSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    }
  }

  handlePlaceholderAssignedSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchText: event.target.value
    })
  }

  creationChangeHandlerDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationDate: event.target.value
    })
  }


  dropdownHandlerOpen = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorEl: event.currentTarget,
      dropdownType
    })
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false
    })
  }

  handleOpenCalendor = () => {
    this.setState({
      isCalendarOpen: true
    })
  }

  handleChangeCalendor = (value: CalendarValue) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    const formattedStartDate = startDate.getDate();
    const formattedStartDateNum = startDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartMonthNum = startDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedStartYear = startDate.getFullYear();
    const formattedEndDate = endDate.getDate();
    const formattedEndDateNum = endDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
    const formattedEndYear = endDate.getFullYear();
    const formattedEndMonthNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;
    const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedStartDateNum}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNum}`;
    this.setState({
      creationDate: dateRange,
      creationDateParams: {
        start_date,
        end_date
      }
    });
  }

  handleClickFilter = () => {
    if(this.state.contentRoleTrue){
    this.getAdminContentRolesListing({ 
      search: "", 
      page: "", 
      per: "" },1);
      
    }else{
      this.getCourse({
        search: this.state.searchInputValue,
        filter: this.state.isCourseActive,
        page: "",
        per: "",
      }, 1);
    }
  
    this.setState({
      anchorFilterContainerEl: null,
      placeholderSearchText:"" ,
    })
  }

  filterContainerDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContainerEl: null
    })
  }

  handleCatalogueChangeStatus = (status: string) => {
    this.setState({
      selectedStatus: status,
      anchorEl: null,
      dropdownType: ""
    })
  }

  handleFilterDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      filterItems: !this.state.filterItems,
      anchorFilterContainerEl: event.currentTarget,
    })
  }

  handleClearFilter = () => {
    this.setState({
      anchorFilterContainerEl: null,
      selectedStatus: "",
      contentRolesParams: "",
      catagoryParams: "",
      contentRoleSelectedItems: [],
      contentRoleSearchText: "",
      categorySelectedItems: [],
      categorySearchText: "",
      placeholderSelectedItems: [],
      placeholderSearchText: '',
      creationDate: "",
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      placeholderParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      }
    }, () => {
      let userDataId = this.props?.navigation?.getParam('id')
      this.getCourseList('',userDataId)
      this.getProgramList('',userDataId)
      this.getContentRoleList("",userDataId)
    })
  }

}
// Customizable Area End