// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface SubBranch {
  attributes: {
  id: number;
  name: string;
  branch_id: string;
  branch_status: string;
  is_primary_branch: boolean;
  teams_counts: number;
  manager: string;
  active_users: number;
  total_users: number;
  payment_status: string;
  sub_branches: SubBranch[];
}
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  anchorTeamFilter:boolean;
  AdvanceSearchValue:string;
  filteredContentData:any;
  showContentLists:boolean;
  placeholderSelectItems:string;
  branchData : Array<SubBranch>;
  isExpanded: boolean,
  expandedRows: { [key: string]: boolean },
}

interface SS {
  id: any;
}

export default class ShowBranchController extends BlockComponent<
  Props,
  S,
  SS
> {

  getTeamCollectionCallId:string="";
  getAdvanceSearchApiCallIds:string="";
  getDeleteApiCallIds:string="";
  getBranchApiCallid : string = "";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      anchorTeamFilter:false,
      filteredContentData:[],
      AdvanceSearchValue:"",
      showContentLists:false,
      placeholderSelectItems:"",
      branchData:[],
      isExpanded: false,
      expandedRows: {},
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
   
    if (apiRequestCallId === this.getAdvanceSearchApiCallIds) {
      this.setState({ filteredContentData: responseJson.data })
    }
    if(apiRequestCallId === this.getBranchApiCallid){
      this.getBranchSuccessCallBack(responseJson.data)
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getBranchApiCall();
  }

  getAdvancedSearchLists=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdvanceSearchApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AdvancedSearchEndPoint}?type=team&search=${this.state.AdvanceSearchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleCreateBranch=()=>{
    this.props.navigation.navigate("CreateBranch", {
      type: "Create"
    });
  }
  handleThumbnailListClick=(item:string)=>{
    this.setState({
      AdvanceSearchValue: item,
      showContentLists: false
    })
   }
  handleTeamInputChangeSearch = (event:any) => {
    this.setState({AdvanceSearchValue:event.target.value},() => {
    })
      this.setState({showContentLists:event.target.value !== ""},()=>{this.getAdvancedSearchLists()}) 
     }

  handleFilterDropClick=()=>{

  }

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = localStorage.getItem("token");
    let header = {
      "Content-Type": contentType,
      token,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getBranchApiCall = async () => {
    this.getBranchApiCallid = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getBranchDetailsEndPoint
    });
  };
   
  getBranchSuccessCallBack = (response: Array<SubBranch>) => {
    this.setState({branchData: response});
  };

  toggleExpandAll = () => {
    const { isExpanded, branchData } = this.state;
    const newExpandedState = !isExpanded;
    const expandedRows: { [key: string]: boolean } = {};
    const expandCollapseRows = (data: SubBranch[], state: boolean, parentIndex: string | number = '') => {
      data.forEach((branch, index) => {
        const currentIndex = parentIndex ? `${parentIndex}-${index}` : `${index}`;
        expandedRows[currentIndex] = state;
        if (branch.attributes?.sub_branches?.length > 0) {
          expandCollapseRows(branch.attributes.sub_branches, state, currentIndex);
        }
      });
    };
    expandCollapseRows(branchData, newExpandedState);
    this.setState({
      isExpanded: newExpandedState,
      expandedRows,
    });
  };

  toggleRow = (index: string) => {
    this.setState((prevState) => ({
      expandedRows: {
        ...prevState.expandedRows,
        [index]: !prevState.expandedRows[index],
      },
    }));
  };
 
  getColor = (status: string): string => {
    switch (status) {
      case 'Paid':
        return 'paid';
      case 'Pending':
        return 'pending';
      case 'Free':
        return 'free';
      default:
        return '';
    }
  };

}

// Customizable Area End
