import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ToastContentProps, toast } from "react-toastify";
import React from "react";
import { FormikErrors } from "formik";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import "./CustomForm1.css";
import {videoIcon} from "./assets";
import {Button} from '@material-ui/core';
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import * as H from "history";

interface LocationState {
  thumbnail?: {
    id: string,
      type: string,
      attributes: {
        title: string,
        created_at: string,
        image: {
          id: string,
          url: string,
          filename: string,
        },
        created_by: string,
      },
  },
  formData: {
    content_description: string,
    description: string,
    file: string,
    link: string,
    name: string,
    tags: Object[],
    thumbnail: string,
    videoName: string,
    fileStates: {
      attachedFile: File,
      uploadContentProgress: number,
      uploadContentStatus: string,
      isLinkValid: boolean,
      setLink: string,
      inputLinkStatus: string
    }
  }
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  values:any;
  classes:any;
  handleChange:any;
  touched:any;
  errors:any;
  handleBlur:any;
  setFieldValue:any;
  location?: H.Location & { state: LocationState };
  history?: H.History
  // Customizable Area End
}

// Customizable Area Start

const videoFileTypes = ['video/mp4', 'video/quicktime', 'video/x-h264', 'video/x-h265','video/x-matroska'];


export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Tag {
  id?: number;
  attributes: {
    name: string;
  };
}


export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}


export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  createLessonValues:string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  courseID:any;
  lessonData:any;
  contentType:any;
  previousLessonId:string;
  lessonEditId:string;
  thumbnailImage:any;
  attachedFile:any;
  onEditAttachedFile:any;
  thumbnailFileId:any;
  fileInput:any,
  tagsList:any,
  tags:any,
  phase_id:any;
  inputValue:any;
  parentType:string;
  goBackModal: boolean;
  saveLessonModal:boolean;
  saveLessonValues:any;
  saveTouchedValues:any;
  newValueCheck: Array<Tag>;
  newTagValue: Tag[];
  withoutPhaseId: string;
  uploadContentProgress: number;
  uploadContentStatus: string;
  inputLinkStatus: string;
  isLinkValid:boolean;
  setLink: string;
  matchLength: number;
  totalFileChunk:number;
  currentChunkFileIndex:number;
  fileUploadId:any;
  uploadSuccess:boolean;
  isOpenFileChangeModal:boolean;
  showThumbnailModal:boolean;
  isChangeFileClicked:boolean;
  thumbnailFile:any
  errorMsg: string;
  ContentLessonType:any;
  showOptionPrompt: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  createLessonApiCallId: string = "";
  getLessonApiCallId: string = "";
  getTagsApiCallId:string = "";
  createTagIdAPICallId: string = "";
  formikRef:  any;
  postContentInitializerCallId:string="";
  postContentUploadChunkCallID:string="";
  FinalContentUploadCallId:string="";
  DeleteContentUploadFileCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationCustomFormMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      createLessonValues:"",
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      contentType:"",
      previousLessonId:"",
      lessonEditId:"",
      address: "",
      country: "",
      lessonData:{
        title:"",
        description:"",
        file: "",
        videoName: "",
        link:"",
        tags:[],
        content_description: "",
        thumbnail: ""
      },
      onEditAttachedFile:"",
      tagsList:[],
      state: "",
      city: "",
      file: null,
      isChange: false,
      thumbnailImage:"",
      attachedFile:"",
      thumbnailFileId:"",
      fileInput: React.createRef(),
      tags:[],
      phase_id:"",
      courseID:"",
      inputValue:"",
      parentType:"",
      goBackModal: false,
      saveLessonModal:false,
      saveLessonValues:{},
      saveTouchedValues:{},
      newValueCheck: [],
      newTagValue: [],
      withoutPhaseId: "",
      uploadContentProgress:0,
      uploadContentStatus:"notStarted",
      inputLinkStatus: '',
      isLinkValid: false,
      setLink: "",
      matchLength: 0,
      totalFileChunk:0,
      currentChunkFileIndex:0,
      fileUploadId:0,
      uploadSuccess:false,
      isOpenFileChangeModal:false,
      showThumbnailModal:false,
      thumbnailFile:"",
      isChangeFileClicked:true,
      errorMsg: "",
      ContentLessonType:{},
      showOptionPrompt: false
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          this.handleServerError();
        } else if (responseJson.errors) {
          this.handleResponseErrors(responseJson.errors);
        }else if(responseJson.error){
         this.handleError(apiRequestCallId,responseJson)
        } else {
          this.handleApiResponse(apiRequestCallId, responseJson);
        }
      }
    }

    if (getName(MessageEnum.NavigationCustomFormMessage) === message.id) {
      const type = message.getData(
        getName(MessageEnum.NavigationCustomFormMessage)
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
 handleServerError = () => {
    toast.error("Something went wrong!");
    this.setState({ loading: false });
  };
  handleError=(apiRequestCallId:string,responseJson:any)=>{
    if(apiRequestCallId === (this.FinalContentUploadCallId || this.postContentUploadChunkCallID) ){
      toast.error(responseJson.error, { autoClose: 2000 });
      this.handleResState()
    }else{
      toast.error(responseJson.error, { autoClose: 2000 });
    }
  }
 
 handleResponseErrors = (errors: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps<{}>) => React.ReactNode) | null | undefined) => {
    toast.error(errors);
    this.setState({ loading: false });
  };
  
 handleApiResponse = (apiRequestCallId: string, responseJson: { data: any; }) => {
    switch (apiRequestCallId) {
      case this.getUserListApiCallId:
        this.handleGetUserListResponse(responseJson);
        break;
      case this.addUserApiCallId:
       this.handleAddUserResponse();
        break;
      case this.editUserApiCallId:
       this.handleEditUserResponse();
        break;
      case this.createLessonApiCallId:
        this.handleCreateLessonResponse(responseJson);
        break;
      case this.getLessonApiCallId:
        this.handleGetLessonsResponse(responseJson);
        break;
      case this.getTagsApiCallId:
        this.setState({ tagsList: responseJson.data });
        break;
      case this.createTagIdAPICallId:
       this.handleCreateTagResponse(responseJson);
        break;
      case this.postContentInitializerCallId:
        this.setState({fileUploadId:responseJson.data.id})
        this.postContentUploadChunk()
         break;
      case this.postContentUploadChunkCallID:
         if(this.state.uploadContentProgress===100){
              this.finalContentUpload();
          }
          this.setState((prevState) => ({ currentChunkFileIndex: prevState.currentChunkFileIndex + 1 }), () => {
          this.uploadContentNextChunk(25 * 1024 * 1024);
          })
          break;
          case this.FinalContentUploadCallId:
            this.setState({uploadContentStatus:"uploaded",uploadSuccess:true})
            break;
            case this.DeleteContentUploadFileCallId:
              this.handleResState()
            break; 
      default:
        break;
    }
  };
  handleResState=()=>{
    this.setState({attachedFile:null,currentChunkFileIndex:0,uploadContentStatus:"notStarted",uploadSuccess:false})
  
   }
 handleGetUserListResponse = (responseJson: { data: any[]; }) => {
    const filterData = responseJson.data.map((item) => ({
      userId: item.id,
      firstName: item.attributes.first_name,
      lastName: item.attributes.last_name,
      phoneNumber: item.attributes.phone_number,
      email: item.attributes.email,
      organization: item.attributes.organization,
      teamName: item.attributes.team_name,
      userType: item.attributes.i_am,
      rating: item.attributes.stars_rating.toString(),
      gender: item.attributes.gender,
      address: item.attributes.address,
      country: item.attributes.country,
      state: item.attributes.state,
      city: item.attributes.city,
      file: item.attributes.file.file_name,
    }));
    this.setState({ filterData }, () => {
      this.onChangeHandler(0);
    });
  };
handleGetLessonsResponse=(responseJson: any) => {
  if (responseJson && responseJson.data) {
      const imageUrl=responseJson.data.attributes.contents.attributes.attachment.url;
      const thumbnailId=responseJson.data.attributes.contents.attributes.thumbnail_image_id;
      const fileName = imageUrl.split('/').pop();
      const fileExtension = imageUrl.split('.').pop().toLowerCase();
      const fileData={
        name:fileName,
        path:fileName,
        isEdit:true,
        type:fileExtension
  
      }
    const tagsData=responseJson?.data?.attributes?.contents?.attributes?.tags?.map((tag:any)=>{
                return(
                  {
                    id:tag.id,
                    attributes:{
                        name:tag.name
                      }
                  }
                )
              });
    const youTubeUrlRegExp = /^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([a-zA-Z0-9_-]{11})([?&][^\s#]*)?$/
    const match = responseJson.data.attributes.contents.attributes.attachment.url.match(youTubeUrlRegExp)
      this.setState({
        lessonData:{
          title:responseJson.data.attributes.title,
          description:responseJson.data.attributes.description,
          file: (this.props?.navigation?.getParam("contentType")==="video" && !match ) ?responseJson.data.attributes.contents.attributes.attachment.url:"",
          videoName:responseJson.data.attributes.contents.attributes.title,
          link:(this.props?.navigation?.getParam("contentType")==="video" && match ) ?responseJson.data.attributes.contents.attributes.attachment.url:"",
          tags:tagsData,
          content_description: responseJson.data.attributes.contents.attributes.description,
          thumbnail: responseJson.data.attributes.contents.attributes.thumbnail.url,
        },
        onEditAttachedFile: fileData,
        thumbnailFileId:thumbnailId,
        isLinkValid:(this.props?.navigation?.getParam("contentType")==="video" && match )
      });
    }
  }
 handleAddUserResponse = () => {
    toast.success("User added successfully!");
    this.setState({ openDialogName: "" });
    this.onCancel();
    this.getUserList();
  };
  
 handleEditUserResponse = () => {
    toast.success("User edited successfully!");
    this.setState({ openDialogName: "", isChange: false });
    this.onCancel();
    this.getUserList();
  };
  
   handleCreateTagResponse = (responseJson: { data: Tag; }) => {
    this.getTags();
    this.formikRef.values.tags.push(responseJson.data);
    this.formikRef.setFieldValue('tags', this.formikRef.values.tags);
  };

  async componentDidMount() {
    const withoutPhaseId = await getStorageData("withoutPhaseId");
    this.setState((prevState) => ({
      contentType: this.props?.navigation?.getParam("contentType"),
      phase_id: this.props?.navigation?.getParam("id"),
      parentType: this.props?.navigation?.getParam("parentType"),
      courseID: this.props?.navigation?.getParam("courseID"),
      previousLessonId: this.props?.navigation?.getParam("previousLessonId"),
      lessonEditId: this.props?.navigation?.getParam("lessonEditId")
  }));
    this.setState({withoutPhaseId: withoutPhaseId})
    this.getTags()
    this.getThumbnailFromLocation()
    this.handleFormikInitialization()
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined,
  ): void  {
    if (prevState.lessonEditId !== this.state.lessonEditId) {
      if(this.props.navigation.getParam('type') === "editLesson"){
        this.getLesson(this.state.lessonEditId);
      }
    }
  }

  handleCreateLessonResponse=async(responseJSON:any)=>{
    if(responseJSON.data){
      let storedData = await getStorageData("UpdateData");
      if (storedData) {
        storedData = JSON.parse(storedData);
        storedData.save = true;
        setStorageData("UpdateData", JSON.stringify(storedData));
      }

      if(!this.state.uploadSuccess){
        this.setState({uploadContentStatus:"uploaded",uploadContentProgress:100})
       }
      toast.success("Lesson created successfully",{ autoClose: 2000 });
      setStorageData("isLessonAdded", true);
      this.props.navigation.navigate("AddContentPage", {type: this.state.parentType,id: this.state.courseID, lesson: "lessonAdded"});
    }else{
      toast.error(responseJSON?.content[0].errors,{ autoClose: 2000 });
    }
  
  }


  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onAddBtnClicked = () => {
    this.setState({ openDialogName: "Add" });
  };

  onFileChange = (file: File | null) => {
    this.setState({ file });
    if (this.state.openDialogName === "Edit") {
      this.setState({ isChange: true });
    }
  };

  onChangeHandler = (page: number) => {
    let { rowsPerPage, filterData } = this.state;
    let data = filterData;
    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, loading: false });
  };
  closeModals=()=>{
    this.setState({showThumbnailModal:false})
  }
  handleThumbnailModalClose=(objectData:any)=>{
  this?.formikRef?.setFieldValue("thumbnail",objectData.attributes.image.url)
  this.setState({showThumbnailModal:false,thumbnailFileId:objectData?.id})
}

  onEditBtnClicked = (item: Data) => {
    this.setState({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      organization: item.organization,
      teamName: item.teamName,
      userType: item.userType,
      rating: item.rating,
      gender: item.gender,
      address: item.address,
      country: item.country,
      state: item.state,
      city: item.city,
      file: item.file,
      openDialogName: "Edit",
    });
  };

  onCancel = () => {
    this.setState({
      openDialogName: "",
      userId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: "",
    });
  };
  isDisableContent=()=>{
      if(this.props.navigation.getParam('type') === "editLesson" && this.state.isChangeFileClicked){
        return true;  
      }else if(this.props.navigation.getParam('type') === "addLesson"){
        return false;
      }
      else{
        return false;
      }
  }
  isDisableSaveButton=(touched:any)=>{
    if(touched.name || touched.description || touched.file){
      return false;
    }
    return true;

  }
  isDisableSaveButtonStyle=(touched:any, webStyle:any)=>{
    if(touched.name || touched.description || touched.file){
      return {...webStyle.enableBTns} 
    }else{
      return {};
    }
  }
   isDisable=()=>{
    if(this.state.uploadContentStatus==="uploaded" || this.state.inputLinkStatus==="uploaded"){
      return false;  
    }
    else{
      return true;
    }
  }
  isDisableStyle=(webStyle:any)=>{
    if(this.state.uploadContentStatus==="uploaded" || this.state.inputLinkStatus==="uploaded"){
      return {...webStyle.enableBTns} 
    }else{
      return {};
    }
  }
  getUserList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    formData.append("form[file]", this.state.file as Blob);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dnDfiles=(file:any)=>{
    this.setState({ContentLessonType:file.type})
    const maxFileSize = 25 * 1024 * 1024; 
    if (file.size>maxFileSize) {
      this.setState({  attachedFile: file, uploadContentProgress: 0, uploadContentStatus: 'uploading'},()=>this.postContentInitiateUpload())
    }else{
      this.setState({ attachedFile: file, uploadContentProgress: 0, uploadContentStatus: 'uploaded'})
    }
  }
  postContentInitiateUpload=async()=>{
    const body={
      filename:this.state.attachedFile.name,
      content_type:this.state.attachedFile.type
    }
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json" 
    };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.postContentInitializerCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.bulkFileUploadAPIEnd}/initiate_upload`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)  
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.createBulkUploadMethod
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}

postContentUploadChunk = async () => {
 
  const chunkSize = 25 * 1024 * 1024; 
    const totalFileChunks = Math.ceil(this.state.attachedFile.size / chunkSize);
    this.setState({totalFileChunk:totalFileChunks})
     await this.uploadContentNextChunk(chunkSize);
  
}
uploadContentNextChunk = async (chunkSize: number) => {
  if (this.state.currentChunkFileIndex <this.state.totalFileChunk) {
    const percentageUploaded = Math.round(((this.state.currentChunkFileIndex + 1) / this.state.totalFileChunk) * 100);
    this.setState({uploadContentProgress:percentageUploaded,uploadContentStatus:"uploading"})
    const start = this.state.currentChunkFileIndex * chunkSize;
    const end = Math.min((this.state.currentChunkFileIndex + 1) * chunkSize, this.state.attachedFile.size);
    const formData: any = new FormData();
    const blobSlice = this.state.attachedFile.slice(start, end, this.state.attachedFile.type);
    const chunkFileName = `${this.state.attachedFile.name}-chunk-${this.state.currentChunkFileIndex + 1}`;
    formData.append('file', blobSlice, chunkFileName);
    formData.append('total_chunk', this.state.totalFileChunk);
    formData.append('chunk_number', this.state.currentChunkFileIndex + 1);
   
    const header = {
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.postContentUploadChunkCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bulkFileUploadAPIEnd}/${this.state.fileUploadId}/upload_chunk`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}



finalContentUpload=async()=>{
  const header = {
    token: localStorage.getItem("token"),
    "Content-Type": "application/json" 
  };
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
this.FinalContentUploadCallId = requestMessage.messageId
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.bulkFileUploadAPIEnd}/${this.state.fileUploadId}/final_upload?total_chunk=${this.state.totalFileChunk}`
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getUserListApiMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;
}
DeleteFile=()=>{
  if(this.state.uploadSuccess){
    this.DeleteContentFileUpload()
  }else{
   this.setState({attachedFile:null,onEditAttachedFile:null,isChangeFileClicked:false})
  }
}
  DeleteContentFileUpload=async()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.DeleteContentUploadFileCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.lessonAPiEndPoint}/${this.state.fileUploadId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethod
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }

  createLessonFormData= (values:any,touched:any)=>{
    let formData = new FormData();
    formData.append("lesson[title]", values.name);
    formData.append("lesson[description]", values.description);
    formData.append("lesson[phase_id]", this.state.phase_id==="default"?this.state.withoutPhaseId: this.state.phase_id)
       if(touched.file || this.state.setLink){
        formData.append("content[description]", values.content_description);
        formData.append("content[content_type]", this.state.contentType);
        if(this.state.setLink){
          formData.append("content[link]", this.state.setLink);
          }
        else if (this.state.uploadSuccess) {
          formData.append("content[upload_id]",this.state.fileUploadId);
        }else if(touched.file){
          formData.append("content[attachment]",this.state.attachedFile);
        }
        formData.append("content[title]", values.videoName);
        values.content_id &&formData.append("content_id", values.content_id);
        values?.tags?.map((item:any)=>{
          if(this.state.lessonEditId!==""){
            formData.append("tag_ids[]", item.id);
          }else{
            formData.append("tag_ids[]", item.attributes.id);
          }
                
        })
    
      formData.append("thumbnail_image_id",this.state.thumbnailFileId)
      values.link && formData.append("content[link]", values.link);
    }
   
    formData.append("lesson[previous_lesson_id]", this.state.previousLessonId);
    formData.append("lesson[previous_lesson_type]", "lesson");
    formData.append("content[file_extension]",this.state.ContentLessonType)
    
    return formData
  }

  createLesson = (values:any,touched:any) => { 
   const formData = this.createLessonFormData(values,touched)
   if(this.props.navigation.getParam('type') === "editLesson"){
    this.createEditLessonAPICALL({
      method: configJSON.editUserApiMethod,
      endPoint: `/bx_block_custom_form/lessons/${this.state.lessonEditId}`,
      body:formData
    });
   }else{
    this.createEditLessonAPICALL({
      method: configJSON.addUserApiMethod,
      endPoint: "/bx_block_custom_form/lessons",
      body:formData
    });
   }
  };

  fileChange=()=> {
    this.setState({ isOpenFileChangeModal: false} );
  }
  fileAccepted=()=> {
   this.setState({isOpenFileChangeModal: false, inputLinkStatus:" ",isChangeFileClicked:false,isLinkValid:false});
  }
  

  createEditLessonAPICALL(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.createLessonApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getLesson = (id:string) => { 
    this.getLessonAPICALL({
       contentType: configJSON.validationApiContentType,
       method: configJSON.getUserListApiMethod,
       endPoint: `/bx_block_custom_form/lessons/${id}`,
     });
   };
 
 
   getLessonAPICALL(data: any) {
     const { contentType, method, endPoint } = data;
     const header = {
       "Content-Type": contentType,
       token: localStorage.getItem("token"),
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
 
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     this.getLessonApiCallId = requestMessage.messageId
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       endPoint
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       method
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return requestMessage.messageId;
   }


  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    if (this.state.isChange) {
      formData.append("form[file]", this.state.file as Blob);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserApiEndPoint}/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTags = () => { 
    this.getTagsAPICALL({
       contentType: configJSON.getUserListApiContentType,
       method: configJSON.getUserListApiMethod,
       endPoint: "/bx_block_coursecreation/custom_tags",
     });
   };
 
 
   getTagsAPICALL(data: any) {
     const { contentType, method, endPoint } = data;
     const header = {
       "Content-Type": contentType,
       token: localStorage.getItem("token"),
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
 
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     this.getTagsApiCallId = requestMessage.messageId
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       endPoint
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       method
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return requestMessage.messageId;
   }

   gotoContentManagement(id:any) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationContentManagementMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationContentManagementMessage),
      id
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }


  handleChange = (setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): Promise<void | FormikErrors<any>>; (arg0: string, arg1: Tag[]): void; }, newValue: Tag[]) => {
    const addedTag = newValue[newValue.length - 1];

    if (addedTag && !addedTag.id) {
      this.createTagApi(addedTag);
    } else {
      setFieldValue('tags', newValue);
    }
  };
  
  handleLinkChange = (
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>, 
    setTouched: (fields: { [field: string]: boolean }, shouldValidate?: boolean | undefined) => void,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newValue = event.target.value;
  
    // Update the form value and setLink state
    this.setState({ setLink: newValue, inputLinkStatus: newValue === "" ? "" : "uploaded" });
  
    // Update the form value in Formik
    setFieldValue('link', newValue, false); // Skip validation on change
  
    // Set the 'link' field as not touched
    setTouched({ link: false });
  };
  
  

  createTagApi =async (tageName: Tag) => { 
   let  body = {
      name: tageName
    }

    this.createTagIdAPICallId = await this.apiCall({
       contentType: configJSON.getUserListApiContentType,
       method: configJSON.addUserApiMethod,
       endPoint: configJSON.createTagApiEndPoint,
       body: body
     });
   };

  apiCall = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  btnCustomStyles=(values: { name: string; tags: []; thumbnail: string; videoName: string; })=>{
    const {name, tags, thumbnail, videoName} = values;
    let checkStatus = name && tags.length && thumbnail && videoName;
    return {
      bg: checkStatus ? "#4D0F6C":"#E5E2E1",
      color: checkStatus ? "#fff":"#929090",
      isDisabled: checkStatus
    }    
  }
  handleSaveEditLessonModalOpenClose = (values:any , touched:any) => {
    this.setState( {
      saveLessonModal: true,
      saveLessonValues:values,
      saveTouchedValues:touched,
    });
  }
  handleSaveEditLessonModalclose=()=>{
    this.setState( {
      saveLessonModal: false,
    });
  }
  
  handleBackModal = () => {
    this.setState({
      goBackModal: true
    })
  }
  handleSaveScreen=()=>{
   this.props.navigation.goBack()
  }
  handleModalClose = () => {
    this.setState({
      goBackModal: false
    })
}
handleOpenThumbnails=()=>{
    this.setState({showThumbnailModal:true, showOptionPrompt: false})
}
handleChangeLinkClick=()=>{
  this.setState({ isOpenFileChangeModal: true, setLink: ''}, () => {
  });
}

// isValidYouTubeUrl(value: string): boolean {
//   const youTubeUrlRegExp = /^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([a-zA-Z0-9_-]{11})([?&][^\s#]*)?$/
//   return youTubeUrlRegExp.test(value);
// }

renderFileAccepted = () => {
  const { isLinkValid } = this.state;

  return (
    <>
    <div
     className="selectedFileDetailsContainer">
      {isLinkValid && <img src={videoIcon} alt="Selected File" className="video-icon" />}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {isLinkValid && (
          <p className="acceptedFileMessage">File accepted</p>
        )}
          <p style={{
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "14px",
            fontWeight: 400,
            color: "#787776",
            wordBreak: "break-all",
            lineHeight: "22px",
            fontFamily: "'Poppins', sans-serif",
          }}>
                {(() => {
                  const link = this.state.setLink;
                  const index = link.indexOf("youtube.com/watch");
    return index !== -1 ? link.substring(0, index + "youtube.com/watch".length)+"..." : link;
                })()}
          </p>
      </div>
      <Button
        style={{
          width: "186px",
          height: "56px",
          padding: "20px 32px 20px 32px",
          gap: "10px",
          borderRadius: "16px",
          border: "1px solid #E47004",
          opacity: "0px",
          color: "#E47004",
          textTransform: "capitalize",
          fontFamily: "'Poppins, sans-serif",
          fontWeight: 700,
          fontSize: "14px"
        }}
        onClick={this.handleChangeLinkClick}
      >
        Change
      </Button>
    </div>
    {
      this.state.isOpenFileChangeModal && (
    
      <DeleteModal
      data-test-id='delete-all-content'
      openDialog={this.state.isOpenFileChangeModal}
      headingText={`Change File`}
      btnCancelText="Cancel"
      btnOkText="Change"
      subHeading={this.props.navigation.getParam("type")==="editLesson"?"Are you sure you want to unlink the content?": "Are you sure you want to change the file? The current one will be cleared."}
      handleCancel={this.fileChange}
      handleOk={this.fileAccepted}
    />
      )}
</>
  );
};


validateYouTubeUrl = (
  setTouched: (fields: { [field: string]: boolean }) => void,
) => {

  const { setLink } = this.state;
  const youTubeUrlRegExp = /^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([a-zA-Z0-9_-]{11})([?&][^\s#]*)?$/
const match = setLink.match(youTubeUrlRegExp)

  if (match) {
    // URL is valid
    this.setState({ isLinkValid: true }, () => {
      this.renderFileAccepted();
    });
    setTouched({ link: false }); // Mark the 'link' field as not touched after successful validation
  } else {
    // URL is invalid
    this.setState({ isLinkValid: false, inputLinkStatus: "", setLink: "" });
    setTouched({ link: true }); // Ensure the 'link' field is marked as touched on invalid URL
  }
};
// Opens the thumbnail option prompt
handleOpenOptionPrompt=()=>{
  this.setState({showOptionPrompt :true})
}
// Closes the thumbnail option prompt
closeOptionPrompt=()=>{
  this.setState({showOptionPrompt:false})
}
/**
 * Handles the action when the user decides to add a thumbnail from local storage.
 * Stores the current screen state (via the URL passed) in local storage
 * and sends a message to navigate to the "/Addthumbnail" page.
 */
handleAddThumbnailFromLocal = (url: string | undefined) => {
  setStorageData("originScreen", url);
  const fileStates = {
    attachedFile: this.state.attachedFile,
    uploadContentProgress: this.state.uploadContentProgress,
    uploadContentStatus: this.state.uploadContentStatus,
    isLinkValid: this.state.isLinkValid,
    setLink: this.state.setLink,
    inputLinkStatus: this.state.inputLinkStatus
  }
  this.props.history?.push({
    pathname: "/Addthumbnail",
    state: {
      formData: {...this.formikRef.values, fileStates},
    }
  });
}
/**
 * Retrieves the thumbnail data from the location state (which was saved when the user added a thumbnail)
 * and passes it to handleThumbnailModalClose to update the UI, if a thumbnail is present.
 */
getThumbnailFromLocation = () => {
  if (this.props.location?.state?.thumbnail) {
    this.handleThumbnailModalClose(this.props.location?.state?.thumbnail)
  }
};
// Initialize Formik Values after being redirected to other page
handleFormikInitialization() {
  if (this.props.location?.state?.formData?.fileStates) {
    const fileStates = this.props.location?.state?.formData?.fileStates;
    this.setState({
      attachedFile: fileStates.attachedFile,
      uploadContentProgress: fileStates.uploadContentProgress,
      uploadContentStatus: fileStates.uploadContentStatus,
      isLinkValid: fileStates.isLinkValid,
      setLink: fileStates.setLink,
      inputLinkStatus: fileStates.inputLinkStatus
    });
    this.setState({
      lessonData:{
        title: this.props.location?.state?.formData.name,
        description: this.props.location?.state?.formData.description,
        file: this.props.location?.state?.formData.file,
        videoName: this.props.location?.state?.formData.videoName,
        link: this.props.location?.state?.formData.link,
        tags: this.props.location?.state?.formData.tags,
        content_description: this.props.location?.state?.formData.content_description,
        thumbnail: this.props.location?.state?.formData.thumbnail
      },
    });
  }
}
  // Customizable Area End
}
