// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../utilities/src/NetworkRequest";
import { debounce } from "lodash";
import { getStorageData } from "../../../framework/src/Utilities";

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

export interface UserTeamsListing  {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    is_selected: boolean,
  }
}

export interface UserBranchesListing{
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
  }
}

interface User {
  id: number;
  name: string;
  branch: string;
  teamCount: number;
  createdBy: string;
  createdDate: string | null;
  loginHistory: string | null;
  accountStatus: string;
}

export interface UserResponse {
  id: string;
  attributes: UserAttributes;
}

export interface UserAttributes {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  prefix_title: string;
  full_name: string | null;
  branches: string[];
  teams: number | null;
  created_at: string | null;
  last_login: string | null;
  status: string;
  invited_by: string;
}

export interface CheckboxSelectedListTypes { value: string, id: string }

interface UserSuggestionResponse {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
}

interface UserSuggestionsResponse {
  data: UserSuggestionResponse[];
}

interface Pagination {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

export interface ExpertListings {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    full_name:string,
    profession: string,
    avatar: null,
    is_admin: boolean
  }
}

export interface CalendarValues {
  0: string | number | Date;
  1: string | number | Date;
}

interface S {
  users: User[];
  filter: string;
  search: string;
  selectedSearch: string | null;
  openAutoComplete: boolean;
  pagination: Pagination;
  searchInputValue: string;
  filteredList: Array<string>;
  showList: boolean;
  anchorFilterContainerEl: null | HTMLElement;
  UserData:Array<UserResponse>;
  UserTableHeaderData:  {name: string, key: keyof UserAttributes}[];
  account_id: string
  sortConfig: {
    key: keyof UserAttributes;
  direction: 'asc' | 'desc';
  };
searchAdvanceValues:string;
filteredContentsLists: Array<string>,
showContentData:boolean;
anchorUserFilterContentsEl: null | HTMLElement;
creationIconsDate:string;
creationDateParamsdatas: {
  start_date: string;
  end_date: string;
};
placeholderSelectsTeamsItems:Array<CheckboxSelectedListTypes>
placeholderSelectsBranchItems:Array<CheckboxSelectedListTypes>
anchorsEl: null | HTMLElement;
dropdownType: string;
placeholderSearchsTexts:string;
placeholderSearchBranchText:string;
userTeamsList: Array<UserTeamsListing>;
userBranchList: Array<UserBranchesListing>;
isCalendarshow:boolean;
searchList:boolean;
placeholdersParams: string;
filterItemsDatas:boolean;
userBranchListParams:string;
}

interface SS {
  id: any;
}

const configJSON = require("./config.js");

export default class UserManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  userListApiCallId: string = "";
  userSuggestionsApiCallId: string = "";
  GetTeamListApiCallId: string = "";
  GetBranchListApiCallIds: string ="";
  getAdvanceSearchApiCallIds:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      users: [],
      filter: "team",
      search: "",
      selectedSearch: "",
      openAutoComplete: false,
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      searchInputValue: "",
      filteredList: [],
      showList: false,
      anchorFilterContainerEl: null,
      UserData:[],
      sortConfig: {
        key: 'full_name', 
        direction: 'asc',
      },
      UserTableHeaderData: [
      {
       name:"User Name",
       key:"full_name"
      },
      {
        name:"Branch",
        key:"branches"
      },
      {
        name:"Teams",
        key:"teams"
      },
      {
        name:"Invited by",
        key:"invited_by"
      },
      
      {
      name:"Created Date",
      key:"created_at"
      },
      {
      name:"Last Login",
      key:"last_login"
      },
      {
        name:"Status",
        key:"status"
        },],
      account_id: "",
      searchAdvanceValues:"",
      filteredContentsLists:[],
      showContentData:false,
      anchorUserFilterContentsEl: null,
      creationIconsDate:"",
      creationDateParamsdatas: {
        start_date: "",
        end_date: ""
      },
      placeholderSelectsTeamsItems:[],
      placeholderSelectsBranchItems:[],
      anchorsEl: null,
      dropdownType: "",
      placeholderSearchsTexts:"",
      placeholderSearchBranchText:"",
      userTeamsList:[],
      userBranchList:[],
      isCalendarshow:false,
      searchList:false,
      placeholdersParams:"",
      filterItemsDatas:false,
      userBranchListParams:""

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getUserList();
    this.getTeamsListings();
    this.getBranchListings();
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      if (apiRequestCallId === this.userListApiCallId) {
        this.setState({
          UserData: responseJson.data,
        })
      }
      if (this.GetTeamListApiCallId === apiRequestCallId) {  
        this.setState({
          userTeamsList: responseJson.data
        })
      }else if (this.GetBranchListApiCallIds === apiRequestCallId) {  
        this.setState({
          userBranchList: responseJson.data
        
        })
      }else if(apiRequestCallId===this.getAdvanceSearchApiCallIds){
        this.setState({filteredContentsLists:responseJson.data})
      }
    }
  }

  handleEditUserData = (id: string) =>{
    this.props.navigation.navigate("EditUser", {id: id});
    this.getUserList()
  }
  handleUserManageData = (id: string) =>{
    this.props.navigation.navigate("UserManage", {id: id});
    this.getUserList()
  }

  getUserList = async () => {
    this.userListApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.userListEndPoint}/?${this.ConstructEndPoints()}`
    });
  };

  handleSort = (key: keyof UserAttributes) => {
    const { UserData, sortConfig } = this.state;
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedData = [...UserData].sort((a, b) => {
    if (typeof a.attributes[key] === 'string') { 
        return direction === 'asc'
          ? (a.attributes[key] as string).localeCompare(b.attributes[key] as string)
          : (b.attributes[key] as string).localeCompare(a.attributes[key] as string);
      } else {
        return direction === 'asc'
          ? (a.attributes[key] as number) - (b.attributes[key] as number)
          : (b.attributes[key] as number) - (a.attributes[key] as number);
      }
    });

    this.setState({
      UserData: sortedData,
      sortConfig: { key, direction },
    });
  };

   ConstructEndPoints = () => {
    let params = [];
    if (this.state.searchAdvanceValues && this.state.searchList) {
      params.push(this.searchValueParams());
    }
    if(this.state.placeholdersParams){
      params.push(this.addplaceholdersParams())
    }
    if(this.state.userBranchListParams){
      params.push(this.addBranchParams())
    }
    params = params.filter(param => param !== '');
    return params.length > 0 ? params.join('&') : '';
   };

   private searchValueParams(): string{
    return `search=${this.state.searchAdvanceValues ? encodeURIComponent(this.state.searchAdvanceValues):""}`;
   }
   private addplaceholdersParams(): string {
    return this.state.placeholdersParams ? `&${this.state.placeholdersParams}` : '';
   }
   private addBranchParams(): string {
    return this.state.userBranchListParams ? `&${this.state.userBranchListParams}` : '';
   }
  
  getTeamsListings=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.GetTeamListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamListingApiEndPoint}?search=${this.state.placeholderSearchsTexts}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getBranchListings=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.GetBranchListApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.BranchesListingApiEndPoint}?search=${this.state.placeholderSearchBranchText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleThumbnailListClick=(item:string)=>{
  this.setState({
    searchList:true,
    searchAdvanceValues: item,
    showContentData: false
  },()=>{
      this.getUserList()
  })
  }

   handleInputChangeSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchAdvanceValues:event.target.value},() => {
      this.getAdvancedSearchLists()
      if (this.state.searchAdvanceValues == "") {
        this.getUserList()
      }
    })
    this.setState({searchList:false,showContentData:event.target.value !== ""},()=>{
    }) 
   }

   filterContainerDropdownCloseHandlers = () => {
    this.setState({
      anchorUserFilterContentsEl: null
    })
  }

  handleClicksFilters = () => {
    this.setState({
      anchorUserFilterContentsEl: null
    }, () => {
      this.getUserList();
    })
  }

  placeholderCheckboxUserManageChangeHandlers = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectsTeamsItems: [...prevState.placeholderSelectsTeamsItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectsTeamsItems.map((item: { value: string, id: string }) => `q[team_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectsTeamsItems: prevState.placeholderSelectsTeamsItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectsTeamsItems.map((item: { value: string, id: string }) => `q[team_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    }
  }

  placeholderCheckboxUserManageChangeHandlersBranch = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectsBranchItems: [...prevState.placeholderSelectsBranchItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectsBranchItems.map((item: { value: string, id: string }) => `q[branch_ids][]=${item.id}`).join("&");
        this.setState({
          userBranchListParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectsBranchItems: prevState.placeholderSelectsBranchItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectsBranchItems.map((item: { value: string, id: string }) => `q[branch_ids][]=${item.id}`).join("&");
        this.setState({
          userBranchListParams: params
        });
      });
    }
  }

  
  handlePlaceholderUserTeamsSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchsTexts: event.target.value
    }, () => {
      this.placeholderDebounced();
    })
  }

  placeholderDebounced: () => void = debounce(
    () => this.getTeamsListings(),
    700
  )

  handlePlaceholderUserBranchSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchBranchText: event.target.value
    }, () => {
      this.placeholderDebouncedBranch();
    })
  }

  placeholderDebouncedBranch: () => void = debounce(
    () => this.getBranchListings(),
    700
  )
  dropdownHandlerOpens = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorsEl: event.currentTarget,
      dropdownType
    })
  }

  creationChangeHandlerDates = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationIconsDate: event.target.value
    })
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorsEl: null,
      isCalendarshow: false,
      dropdownType: "",
    })
  }

  handleClearFilters=()=>{
    this.setState({
      anchorUserFilterContentsEl: null,
    placeholdersParams: "",
    userBranchListParams:"",
    placeholderSelectsTeamsItems:[],
    placeholderSelectsBranchItems:[],
    creationIconsDate:"",
    creationDateParamsdatas: {
      start_date: "",
      end_date: ""
    },
    isCalendarshow:false,
    placeholderSearchsTexts:"",
    placeholderSearchBranchText:"",
    anchorsEl: null ,
    dropdownType: "",
      }, () => {
        this.getUserList()
      })
  }

  getAdvancedSearchLists= async ()=>{
    const header = {
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdvanceSearchApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.advanceSearchApiEndPoint}?type=user&search=${this.state.searchAdvanceValues}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }

  handleFilterDropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
       filterItemsDatas: !this.state. filterItemsDatas,
      anchorUserFilterContentsEl: event.currentTarget,
    })
  };
  renderStatusClass = (status: string) => {
    const { classes } = this.props;
    return status === "Active" ? classes.statusStyle : classes.statusInvitedStyle;
  };
  
  getFullName = (attributes: any) => {
    const { first_name, middle_name, last_name } = attributes;
    return `${first_name} ${middle_name ? middle_name : "null"} ${last_name}`;
  };

  displayPrefixTitle = (prefix_title: string) => {
    return prefix_title !== "prefer_not_to_say" ? prefix_title : "";
  };

  getInputWidth = () => (window.innerWidth < 500 ? "100%" : "480");

  
}
// Customizable Area End