import React, { useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  styled,
  Paper,
  Grid,
} from "@material-ui/core";
import { UpArrowIcon, DownArrowIcon, dummy_img } from "./asset";

//@ts-ignore

interface Reward {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    created_by: string;
    icon: {
      id: number;
      url: string;
    };
    users: number;
    unstarted: number;
    progress: number;
    earned: number;
    redeemed: number;
  };
}

interface Achievement {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    created_by: string;
    icon: {
      id: number;
      url: string;
    };
    users: number;
    unstarted: number;
    progress: number;
    earned: number;
    redeemed: number;
  };
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Reward["attributes"];
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "created_by",
    numeric: true,
    disablePadding: false,
    label: "Created by",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Created on",
  },
  {
    id: "users",
    numeric: true,
    disablePadding: false,
    label: "Users",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "Stages",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Reward,
    sortType: string
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  getRewards: (
    params: {
      search: string;
      filter: string;
      page: string;
      per: string;
      property: string;
      sortType: string;
    },
    pagination: number
  ) => void;
  isRewardsActive: string;
  getAchievements: (
    params: {
      search: string;
      filter: string;
      page: string;
      per: string;
      property: string;
      sortType: string;
    },
    pagination: number
  ) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof Reward["attributes"], sortType: string) =>
    (event: React.MouseEvent<unknown>) => {
      console.log(property, sortType, "kkkkkkkkkkk");
      const params = {
        search: "",
        filter: "",
        page: "1",
        per: "10",
        property,
        sortType,
      };
      const pagination = 1;
      if (props.isRewardsActive === "reward") {
        props.getRewards(params, pagination);
      } else if (props.isRewardsActive === "achievement") {
        props.getAchievements(params, pagination);
      }
      onRequestSort(event, property as keyof Reward, sortType);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ verticalAlign: "middle" }}
            className={
              headCell.label === "Name"
                ? "table-header-name" 
                : headCell.label === "Stages"
                ? "table-header-stages"
                : "table-header-cell" 
            }
            colSpan={
              headCell.label === "Course" || headCell.label === "Program"
                ? 2
                : 1
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginLeft: "17px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "#000",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {headCell.label}
              </span>
              {headCell.label !== "Stages" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={UpArrowIcon}
                    alt="Up Arrow"
                    width="10px"
                    style={{ paddingBottom: "5px", cursor: "pointer" }}
                    onClick={createSortHandler(headCell.id, "asc")}
                  />
                  <img
                    src={DownArrowIcon}
                    alt="Down Arrow"
                    width="10px"
                    style={{ cursor: "pointer" }}
                    onClick={createSortHandler(headCell.id, "desc")}
                  />
                </div>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      borderCollapse: "separate",
      borderSpacing: "0 15px",
      "& .MuiTable-root": {
        borderCollapse: "separate",
        borderSpacing: "0 15px",
      },
      "& .MuiTableRow-root": {
        boxShadow: "unset !important",
        borderRadius: "12px !important",
        borderSpacing: "0 15px !important",
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          boxShadow: "1px 2px 8px 1px rgba(0,0,0,.05)!important",
        },
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    statusNew: {
      display: "flex",
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#EEE8F2",
      color: "#652786",
    },
    statusDraft: {
      display: "flex",
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#FFEDE4",
      color: "#E47004",
    },
    statusArchived: {
      display: "flex",
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#FFD9DF",
      color: "#E70E6B",
    },

    errorInput: {
      borderColor: "red",
      backgroundColor: "#FFF5F5",
    },
    errorText: {
      color: "red",
      marginLeft: " 5px",
      fontSize: "10px",
    },
    successText: {
      color: "#9A5E89",
      marginLeft: " 5px",
    },
    automaticText: {
      borderColor: "#652786",
      marginLeft: " 5px",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: "30px",
      borderBottom: "1px solid lightgray",
    },
    alignRight: {
      "&:last-child": {
        textAlign: "right",
      },
    },
    paper2: {
      padding: "8px 0px",
      whiteSpace: "nowrap",
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  })
);

export default function EnhancedTable(props: any) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Reward>("id");

  const [rows, setRows] = useState<Reward[]>([]);

  const [achievements, setAchievements] = useState<Achievement[]>([]);

  const isRewardsActive = props.isRewardsActive;
  const classes = useStyles();

  console.log(props.params);

  useEffect(() => {
    const fetchRewards = async () => {
      console.log("Fetching rewards data...");
      try {
        console.log("Rewards data fetched:", props.rewardsList);

        setRows(props.rewardsList);
      } catch (error) {
        console.error("Error fetching rewards:", error);
      }
    };

    const fetchAchievements = async () => {
      console.log("Fetching Achievements data...");
      try {
        console.log("isRewardsActive:", isRewardsActive);
        console.log("Achievements data fetched:", props.achievementsList);

        setAchievements(props.achievementsList);
      } catch (error) {
        console.error("Error fetching Achievements:", error);
      }
    };
    console.log("isRewardsActive changed:", isRewardsActive);
    console.log("Props changed:", props);

    if (isRewardsActive === "reward") {
      fetchRewards();
    } else if (isRewardsActive === "achievement") {
      fetchAchievements();
    }
  }, [ props.rewardsList,props.achievementsList, isRewardsActive]);
  
  const displayedRows = isRewardsActive === 'achievement' ? achievements : rows;
  
  console.log('Displayed rows:', displayedRows);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Reward,
    sortType: string
  ) => {
    const columnValues = rows.map((row) => row[property]);
    const isAllValuesSame = columnValues.every(
      (value, index, array) => value === array[0]
    );
    if (!isAllValuesSame) {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    console.log("page hande scroll:");
    const container = event.currentTarget as HTMLDivElement;
    const isAtBottom =
      container.scrollTop + container.offsetHeight >= container.scrollHeight;
    if (isAtBottom) {
      props.handleNextPageNavigation1();
    }
  };

  return (
    <MainContainer
      className={classes.root}
      style={{ marginTop: "2%" }}
      id="course-program-table"
    >
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            getAchievements={props.getAchievements}
            getRewards={props.getRewards}
            isRewardsActive={props.isRewardsActive}
            classes={classes}
            numSelected={0}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={() => {}}
            onRequestSort={handleRequestSort}
            rowCount={displayedRows.length}
          />
          <TableBody>
            {displayedRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{
                    border: "none",
                    padding: "20px",
                  }}
                  padding="checkbox"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        row.attributes.icon && row.attributes.icon.url
                          ? row.attributes.icon.url
                          : dummy_img
                      }
                      style={{
                        borderRadius: "4px",
                        border: " 1px solid #929090",
                        background: " lightgray 50% / cover no-repeat",
                        boxShadow: "0px 1px 3px 0px rgba(140, 140, 140, 0.72)",
                        height: "40px",
                        width: "40px",
                        marginRight: "20px",
                      }}
                    />
                    {row.attributes.name}
                  </div>
                </TableCell>
                <TableCell style={{ border: "none" }} align="right">
                  {row.attributes.created_by}
                </TableCell>
                <TableCell style={{ border: "none" }} align="right">
                  {new Date(row.attributes.created_at)
                    .toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                    .replace(/\//g, "-")}
                </TableCell>
                <TableCell style={{ border: "none" }} align="right">
                  {row.attributes.users}
                </TableCell>
                <TableCell
                  style={{ border: "none", padding: "20px" }}
                  align="right"
                >
                  <Grid container spacing={1} wrap="wrap">
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "150px",
                      }}
                    >
                      <Paper
                        style={{
                          padding: "10px",
                          backgroundColor: "#FFC5C5",
                          textAlign: "center",
                          borderRadius: "10px",
                          border: "1px solid #FF3737",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: "#FF3737",
                          }}
                        >
                          {row.attributes.unstarted}
                        </div>
                        <div
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            color: "#FF3737",
                          }}
                        >
                          Unstarted
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "150px",
                      }}
                    >
                      <Paper
                        style={{
                          padding: "10px",
                          backgroundColor: "#FFD7BE",
                          textAlign: "center",
                          borderRadius: "10px",
                          border: "1px solid #FF9900",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: "#FF9900",
                          }}
                        >
                          {row.attributes.progress}
                        </div>
                        <div
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            color: "#FF9900",
                          }}
                        >
                          In Progress
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "150px",
                      }}
                    >
                      <Paper
                        style={{
                          padding: "10px",
                          backgroundColor: "#C7B8EA",
                          textAlign: "center",
                          borderRadius: "10px",
                          border: "1px solid #7A288A",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: "#7A288A",
                          }}
                        >
                          {row.attributes.earned}
                        </div>
                        <div
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            color: "#7A288A",
                          }}
                        >
                          Earned
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "150px",
                      }}
                    >
                      <Paper
                        style={{
                          padding: "10px",
                          backgroundColor:
                            row.type === "achievement_listing"
                              ? "#FFFFFF"
                              : "#C6F4D6",
                          textAlign: "center",
                          borderRadius: "10px",
                          border:
                            row.type === "achievement_listing"
                              ? "1px solid #FFFFFF"
                              : "1px solid #8BC34A",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color:
                              row.type === "achievement_listing"
                                ? "#FFFFFF"
                                : "#333",
                          }}
                        >
                          {row.attributes.redeemed}
                        </div>
                        <div
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            color:
                              row.type === "achievement_listing"
                                ? "#FFFFFF"
                                : "#333",
                          }}
                        >
                          Redeemed
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell
                  style={{ border: "none", textTransform: "capitalize" }}
                  align="right"
                >
                  <div
                    className={
                      row.attributes.status === "active"
                        ? classes.statusNew
                        : row.attributes.status === "draft"
                        ? classes.statusDraft
                        : classes.statusArchived
                    }
                  >
                    {row.attributes.status}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainContainer>
  );
}

const MainContainer = styled("div")({
  position: "relative",
});