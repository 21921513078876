// Customizable Area Start
import React from "react";
import { Box, Button, Grid, TextField, Checkbox, Typography, Divider, IconButton, Modal } from "@material-ui/core";
import { styled, withStyles, } from "@material-ui/core/styles";
import { CheckBoxOutlineBlank, CheckBox, CancelOutlined, Search } from '@material-ui/icons';
import * as yup from "yup";
import CustomFormController, {
  Props, Tag, configJSON,
} from "./CustomFormController.web";
import { Formik } from "formik";
import { icon_page, icon_upload, addTgumbnail,uploadError,errorIcon} from "./assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import Commonmanagement from "../../imagemanagement2/src/Commonmanagement.web"
import DragAndDropFileUploadWithProgress from "../../../components/src/DragAndDropFileUploadWithProgress";
import './CustomForm1.css';


export class CustomLessonForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
  }
  
  renderLabel = () => {
    const { contentType } = this.state;
    switch (contentType) {
      case 'video':
        return 'Video Name*';
      case 'audio':
        return 'Audio Name*';
      default:
        return 'Document Name*';
    }
  };
  fileUploadFunction=(setFieldTouched:any,feildName:string)=>{
    return(
      <>
        <Box>
               <Grid>
                            <DragAndDropFileUploadWithProgress
                             data-test-id="drag_and_drop_id"
                              isBulkUploading
                              type={this.state.contentType}
                              selectedThumbnailFile={this.DeleteFile}
                              image={icon_upload}
                              errorImage={uploadError}
                              onSelectFile={this.dnDfiles}
                              setFieldTouched={()=>setFieldTouched(feildName,true,true)}
                              selectedFile={this.state.attachedFile || this.state.onEditAttachedFile}
                              progress={this.state.uploadContentProgress}
                              uploadStatus={this.state.uploadContentStatus}
                              navigation={this.props.navigation}
                              uploadingMessage={"This can take a few minutes depending on the file size and connection speed"}
                            />
                          </Grid>
                        </Box>
      </>
    )
  }
  fileNameClass=(values:any,classes:any)=>{
    if(values.name){
      return `whiteBg ${classes.InputStyle}` 
    }else{
      return `pinkBg ${classes.InputStyle}`
    }
  }

  fileDescriptionClass=(values:any)=>{
    if(values.description){
      return  `whiteBg` 
    }else{
      return `pinkBg`
    }
  }
 

  contentUpload = (classes: any, handleBlur: any, handleChange: any, setFieldValue: any, values: any, touched: any, errors: any) => {
   
    return (
      <Formik
      enableReinitialize={true}
        initialValues={{ link: values?.link }}
        validationSchema={yup.object().shape({
          link: yup
            .string()
            .test('is-youtube-link', 'This is not a valid YouTube link', function (value) {
              const youTubeUrlRegExp = /^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([a-zA-Z0-9_-]{11})([?&][^\s#]*)?$/;
              const isValid = youTubeUrlRegExp.test(value);
              return isValid || this.createError({ path: this.path, message: 'This is not a valid YouTube link' });
            }),
        })}
        onSubmit={(values) => {
          // Handle form submission
        }}
      >
        {({ handleBlur, handleChange, setFieldValue, values, touched, errors, validateForm, setTouched }) => {
          const hasError = touched.link && Boolean(errors.link) && !this.state.isLinkValid && (values.link !== "");
  
          return (
            <>
              {this.state.contentType === "video" && !this.state.attachedFile ? (
                <Grid container className={classes.contentDiv}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      height: "262px",                      // border: hasError ? '2px dashed #E62345' : '2px dashed #929090',
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "32px 32px 0 32px",
                      borderRadius: "8px",
                      backgroundColor: hasError ? '#FFF5F5' : '',
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "22px",
                          fontWeight: 600,
                          lineHeight: "33px",
                          color: "#1C1B1B",
                          textAlign: "left",
                        }}
                      >
                        {configJSON.enterLinkText}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#1C1B1B",
                          lineHeight: "18px",
                          textAlign: "left",
                        }}
                      >
                        {configJSON.pasteLinkText}
                      </Typography>
                    </div>
                    <Grid
                      data-test-id="openBackModalID"
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        name="link"
                        data-test-id="youtube_link_form_id"
                        fullWidth
                        style={{
                          height: "40px",
                          paddingRight: "12px",
                          paddingLeft: "0px",
                          fontSize: "16px",
                        }}
                        onChange={(event) => this.handleLinkChange(setFieldValue, setTouched, event)}
                        InputProps={{
                          style: {
                            borderColor: errors.link && touched.link && !this.state.isLinkValid && values.link !== "" ? '#f44336' : '',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: '8px',
                            backgroundColor:this.state.setLink ? "#FFFFFF": "#FFF7FF",
                            maxWidth: '223px',
                            minHeight: '40px',
                            padding: '0 12px',
                          },
                        }}
                        helperText={
                          touched.link && errors.link && values.link !== "" && (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={errorIcon} alt="Error Icon" style={{ marginRight: 4, color: "#EF2B4B" }} />
                              <Typography variant="caption" style={{ color: "#EF2B4B" }}>
                                {errors.link}
                              </Typography>
                            </span>
                          )
                        }
                        onBlur={handleBlur}
                        defaultValue={values?.link}
                        className="input-field"
                        value={values?.link}
                        placeholder="Enter your link here"
                      />

                      <Box sx={continueButtonContainer}>
                        <HeaderContainer
                          style={this.isDisableStyle(webStyle)}
                          data-test-id="handleAddLinkButton"
                          type="submit"
                          disabled={this.isDisable()}
                          onClick={() => {
                            setTouched({ link: true }); // Mark the field as touched
                            validateForm(); // Trigger validation manually
                            this.validateYouTubeUrl(setTouched); // Pass setTouched to your custom validation logic
                          }}
                        >
                          Add Video
                        </HeaderContainer>
                      </Box>
                    </Grid>
                  </Grid>
  
                  <Grid item xs={12} md={6} className={classes.outlinedBox}>
                    <img src={icon_page} style={{ width: "56px", height: "56px" }} alt="Icon" />
                    <Typography
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "22px",
                        fontWeight: 600,
                        lineHeight: "33px",
                        textAlign: "left",
                      }}
                    >
                      {configJSON.chooseLibraryText}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Box>
                  {!this.state.attachedFile && (
                    <Grid item xs={12} md={12} className={classes.outlinedBox}>
                      <img src={icon_page} style={{ width: "56px", height: "56px" }} alt="Icon" />
                      <Typography
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "22px",
                          fontWeight: 600,
                          lineHeight: "33px",
                          textAlign: "left",
                        }}
                      >
                        {configJSON.chooseLibraryText}
                      </Typography>
                    </Grid>
                  )}
                </Box>
              )}
            </>
          );
        }}
      </Formik>
    );
  }
  
  showThumbnailrender=(values:any)=>{
   return(
    <>
       <div style={{ display: "flex", gap: "20px", width: "270px" }}>
                              <Button data-test-id="thumbnailButton" className="changethumb-btn"  disabled={this.isDisableContent()}  onClick={
                                   this.handleOpenOptionPrompt
                                  }>
                                <img style={{ paddingRight: "9px" }} src={addTgumbnail} />
                                Add Thumbnail
                              </Button>
                              <Box className={values.thumbnail? "":"thumbpreview"}>
                                {values.thumbnail ? <img data-test-id="thumbnailImg" src={values.thumbnail} alt="Thumbnail"  className={values.thumbnail?"thumbnailImg":"thumbnailwidth"} /> : <div></div>}
                              </Box>
                            </div>
    </>
   )
  }
  getInitialValues=()=>{
    const { lessonData} = this.state;
   return({
      name: lessonData.title || "",
      description: lessonData.description || "",
      file: lessonData.file || "",
      videoName: lessonData.videoName || "",
      link: lessonData.link || "",
      tags: lessonData.tags || "",
      content_description: lessonData.content_description || "",
      thumbnail: this.props.location?.state?.thumbnail?.attributes.image.url || lessonData?.thumbnail || ""
    })
  }
  getSubmitButton=(touched:any,values:any,submitForm:any)=>{
    if(this.props.navigation.getParam('type')=== "addLesson"){
      return (
        <HeaderContainer 
        style={{cursor:"pointer",...this.isDisableStyle(webStyle)}}
        data-test-id="handleSubmitButton" type="submit"  
        disabled={this.isDisable()} 
        onClick={() => {
           this.createLesson(values, touched)
           }}>
            Add Lesson
        </HeaderContainer>
           
      )
    }else{
      return(
        <HeaderContainer 
        style={{cursor:"pointer",...this.isDisableSaveButtonStyle(touched,webStyle)}}  
        data-test-id="handleEditSubmitButton" 
        type="submit"  
        disabled={this.isDisableSaveButton(touched)} 
        onClick={()=>{
          this.handleSaveEditLessonModalOpenClose(values, touched);
        }}>
          Save Lesson
        </HeaderContainer>
      )
    }                       
  }
  render() {
    const { classes } = this.props;
    const { lessonData} = this.state;
    const {isLinkValid} = this.state;
     return (
      <>
        <Box>
          <Formik
          validateOnBlur={true}
           enableReinitialize={true}
            innerRef={formik => { this.formikRef = formik; }}
            data-test-id="createLessonForm"
            initialValues={this.getInitialValues()}
            onSubmit={()=>{}}
            validationSchema={yup.object().shape({
              name: yup
                .string()
                .required("Name is required")
                .min(5, "min 5 characters required")
                .max(50, "Name can't have more than 50 characters"),
              videoName: yup
                .string()
                .required("Name is required")
                .min(5, "min 5 characters required")
                .max(50, "max 50 characters required"),
                content_description: yup
                .string()
                .max(500, "Description is too long (Max 500 character allowed)"),
                description: yup
                .string()
                .max(500, "Description is too long (Max 500 character allowed)"),
              thumbnail: yup
                .string()
                .required("Thumbnail is required"),
              tags: yup
                .string()
                .required("Tags is required"),

            })}
          >
            {({submitForm, values, handleChange, handleSubmit, validateForm, touched, errors, handleBlur, setFieldValue,setFieldTouched }) => {

              return (
                  <Grid>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={gridStyle}>

                        <Grid item xs={12} md={12} className={classes.formStyle}>
                          <label className={classes.lableStyle}>
                            Name*
                          </label>
                          <TextField
                            name='name'
                            onBlur={handleBlur}
                            className={this.fileNameClass(values,classes)}
                            placeholder="Enter title"
                            defaultValue={values?.name}
                            value={values?.name}
                            data-test-id="name"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            variant="outlined"
                            style={{ background: "#FCF8F8" }}
                            onChange={handleChange} />
                        </Grid>
                      

                        <Grid item xs={12} md={12} className={classes.formStyle}>
                          <label className={classes.lableStyle}>
                            Description
                          </label>
                          <TextField
                            className={this.fileDescriptionClass(values)}
                            fullWidth
                            data-test-id="description"
                            name="description"
                            value={values?.description}
                            error={touched.description && Boolean(errors.description)}
                            helperText={touched.description && errors.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              style: descInput,
                            }}
                            variant="outlined"
                            placeholder=" Please write your description here"
                            multiline
                            rows={10}
                            maxRows={12}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Typography style={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", fontWeight: 400, lineHeight: "18px", textAlign: "left" }}>                           
                            {configJSON.descriptionBottomText}
                          </Typography>
                        </Grid>

                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "100%", border: "1px solid #E2E5E1", height: "1px" }}></div>
                        <Typography style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "32px",
                          fontWeight: 700,
                          lineHeight: "48px",
                          letterSpacing: "-1px",
                          textAlign: "center",
                          padding: "10px"
                        }}>Content</Typography>
                        <div style={{ width: "100%", border: "1px solid #E2E5E1", height: "1px" }}></div>
                      </Box>

                      <Grid container style={gridStyle}>
                      {isLinkValid ? (
                        this.renderFileAccepted()
                      ) : (
                        <>
                          {this.fileUploadFunction(setFieldTouched,"file")}
                          {this.contentUpload(
                           classes,
                           handleBlur,
                           handleChange,
                           setFieldValue,
                           values,
                           touched,
                           errors
                          )}
                        </>
                      )}    
                          <Box className={classes.tagCss}>
                          <Box style={thumbnailOuterBox}>
                            <label className={classes.lableStyle}>
                              Thumbnail*
                            </label>
                         {this.showThumbnailrender(values)}
                         {touched.thumbnail && errors.thumbnail && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.thumbnail}</Typography>
                          )}
                          </Box>
                         
                          <Divider className={classes.dividerCss} />
                          <Grid container style={{display:"flex"}}>
                            <Grid item >
                              <label style={webStyle.labelText} >
                                Tags*
                              </label>
                              <Autocomplete
                                data-test-id="selectButtonID"
                                disabled={this.isDisableContent()}
                                className={classes.autoComplete}
                                options={this.state.tagsList}
                                freeSolo
                                value={values?.tags}
                                multiple
                                getOptionLabel={(option: any) => option?.attributes.name}
                                autoSelect
                                onChange={(event, newValue: Tag[]) => this.handleChange(setFieldValue, newValue)}
                                onBlur={handleBlur}
                                renderOption={(option: any, { selected }) => (
                                  <li>
                                    <Checkbox
                                      className="checkBox"
                                      disabled={this.isDisableContent()}
                                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                                      checkedIcon={<CheckBox fontSize="small" />}
                                      style={{ marginRight: 8, color: "#652786", textTransform: "capitalize" }}
                                      checked={selected}
                                      onBlur={handleBlur}
                                      color="primary"
                                    />
                                    {option.attributes.name}
                                  </li>
                                )}
                                renderInput={(params) => {
                                  const { InputProps, ...rest } = params;
                                  return (
                                    <TextField
                                      {...rest}
                                      className={classes.tagFieldCss}
                                      disabled={this.isDisableContent()}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      placeholder="Find a tag"
                                      name="tags"
                                      InputProps={{
                                        ...InputProps,
                                        style: { borderRadius: '8px' },
                                        startAdornment: (
                                          <Search style={{ color: "#ADAAAA" }} />
                                        )
                                      }}
                                    />
                                  )
                                }}
                              />
                                {touched.tags && errors.tags && (
                          <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.tags}</Typography>
                        )}
                            </Grid>
                            {values?.tags?.map((tagSelected: Tag, index: number) => {
                              return (
                                <Grid item key={index} style={{ alignItems: 'end', display: 'flex' }}>
                                  <Box
                                    style={{ cursor:"pointer",display: 'inline-flex',position:"relative", alignItems: 'center', width: '170px', justifyContent: 'center', margin: '5px', padding: '11px', border: '1px solid #652786', borderRadius: '12px' }}
                                  >
                                    <Typography variant="body1" style={{ fontSize: "14px", textTransform: "capitalize", lineHeight: "16px", fontWeight: 400, color: "#652786", fontFamily: "'Poppins', sans-serif" }}>{tagSelected?.attributes?.name}</Typography>
                                    <IconButton data-test-id={`removeTagButton-${index}`} style={{cursor:"pointer",position: "absolute", top: "-12px", right: "-12px"}} size="small" onClick={() => { setFieldValue("tags", values?.tags.filter((tagRemove: any) => tagRemove !== tagSelected)) }} disabled={this.isDisableContent()}>
                                      <CancelOutlined style={{  background: "#fff", borderRadius: "10px", color: "#652786" }} fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Grid>
                        
                        </Box>
                      
                        <Grid item xs={12} md={12} className={classes.formStyle}>
                          <label className={classes.lableStyle}>
                            {this.renderLabel()}
                          </label>
                          <TextField
                            name="videoName"
                            className={values.videoName ? `whiteBg ${classes.InputStyle}` : `pinkBg ${classes.InputStyle}`}
                            placeholder="Enter title"
                            error={touched.videoName && Boolean(errors.videoName)}
                            helperText={touched.videoName && errors.videoName}
                            defaultValue={values?.videoName}
                            value={values?.videoName}
                            style={{ background: "#FCF8F8" }}
                            id="bootstrap-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.isDisableContent()}
                            variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.formStyle}>
                          <label className={classes.lableStyle} style={{ display: "flex" }}>
                            Description&nbsp;<p style={{ color: "#ABAAAA", margin: "0" }}> - For internal use only</p>
                          </label>
                          <TextField
                            className={values.content_description ? "whiteBg" : "pinkBg"}
                            fullWidth
                            id="_desc"
                            name="content_description"
                            disabled={this.isDisableContent()}
                            value={values?.content_description}
                            error={touched.content_description && Boolean(errors.content_description)}
                            helperText={touched.content_description && errors.content_description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              style: descInput,
                            }}
                            variant="outlined"
                            placeholder=" Please write your description here "
                            multiline
                            rows={10}
                            maxRows={12}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid data-test-id="openBackModalID" style={{ display: "flex", gap: "20px", justifyContent: "end", marginTop: "50px", alignItems: "center" }}>
                      <CancelDraftstyle  data-test-id="cancel_btn" onClick={this.handleBackModal}>Cancel</CancelDraftstyle>
                      <Box sx={continueButtonContainer}>
                        {this.getSubmitButton( touched,values,submitForm)
                        }
                        
                      </Box>
                    </Grid>
                  </Grid>
              )
            }}
          </Formik>
          {this.state.showThumbnailModal && 
      <>
      <Commonmanagement
      id="2"
      data-test-id="commonmanagement_id"
      navigation={this.props.navigation}
         open={this.state.showThumbnailModal}
         onClose={this.handleThumbnailModalClose}
        CloseModal={this.closeModals}
      />
       </>
      }
      <DeleteModal
          data-test-id='option-prompt'
          openDialog={this.state.showOptionPrompt}
          headingText={`Where do you want to select the thumbnail from?`}
          btnOkText="Thumbnail library"
          btnCancelText="Local storage"
          handleCancel={() => this.closeOptionPrompt()}
          handleOk={() => this.handleOpenThumbnails()}
          promptOption={true}
          handleOptionOne={() => this.handleAddThumbnailFromLocal(this.props?.location?.pathname)}
        />
    </Box>
        <DeleteModal
          data-test-id='delete-all-content'
          openDialog={this.state.goBackModal}
          headingText={this.props.navigation.getParam("type")==="editLesson"?"Are you sure you want to abandon your changes?":"Save to avoid losing changes"}
          btnCancelText="Leave"
          btnOkText="Keep Editing"
          handleCancel={this.handleSaveScreen}
          handleOk={this.handleModalClose}
          library={true}
        />
      <DeleteModal
          data-test-id='save-content-modal'
          openDialog={this.state.saveLessonModal}
          headingText="Are you sure you want to save changes?"
          btnCancelText="Cancel"
          btnOkText="Save"
          handleCancel={this.handleSaveEditLessonModalclose}
          handleOk={()=>this.createLesson(this.state.saveLessonValues, this.state.saveTouchedValues)}
          library={true}
        />
      </>
    );
  }
}


const HeaderContainer = styled(Button)({
  backgroundColor: "#E5E2E1",
  height: "56px", 
  width: "230px", 
  borderRadius: "16px", 
  textTransform: "none", 
  fontSize: "16px", 
  color: "#787776",
  lineHeight: "16px", 
  fontWeight: 600, 
  fontFamily: "'Poppins', sans-serif",
   "&:hover": {
    backgroundColor: "#E5E2E1",
  },
  cursor: 'pointer',
});
const continueButtonContainer = { display: "flex", flexDirection: "row", justifyContent: "center" }

const CancelDraftstyle = styled("div")({ width: "230px", height: "56px", textTransform: "none", textAlign: "center", display: "grid", alignItems: "center", color: "#FF8D3B", fontSize: "16px", lineHeight: "16px", fontWeight: 600, fontFamily: "'Poppins', sans-serif", margin: "20px 0px", border: "1px solid #FF8D3B", borderRadius: "16px" , cursor: "pointer"})

const descInput = {
  borderRadius: "8px",
  background: "#FCF8F8",
  outline:"none"
}

const thumbnailOuterBox = { display: "flex", flexDirection: "column" as const, gap: "4px" }

const gridStyle = {
  display: "grid",
  gap: "24px",
  padding: "24px",
  boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.3)",
  borderRadius: "18px"
};

const webStyle = ({
  lableStyle: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#1C1B1B"
  },
  outlinedBox: { height: "262px", padding: "20px", borderRadius: "8px", border: "2px dashed #929090", display: "flex", flexDirection: "column" as const, gap: "15px", alignItems: "center", justifyContent: "center" },
  labelText: { fontSize: "16px", fontWeight: 400, lineHeight: "24px", textAlign: "left" as const, color: "#1C1B1B", fontFamily: "'Poppins', sans-serif" },
  formStyle: {
    display: "grid",
    gap: "6px",
    "& .MuiFormControl-root.whiteBg .MuiOutlinedInput-multiline":{
      backgroundColor: "#fff !important"
    },
    "& .MuiFormControl-root.pinkBg .MuiOutlinedInput-multiline":{
      backgroundColor: "rgb(255, 247, 255) !important"
    },
    "& .MuiFormControl-root.whiteBg .MuiInput-root":{
      backgroundColor: "#fff !important"
    },
    "& .MuiFormControl-root.pinkBg .MuiInput-root":{
      backgroundColor: "rgb(255, 247, 255) !important"
    },
    "& .MuiInput-underline":{
borderRadius:"8px"
    }
  },
  InputStyle: {
    "& .MuiInputBase-input": {
      padding: "16px 20px 16px 24px",
      gap: "0px",
      borderRadius: "8px",
    }
  },
  contentRoleContentbtn: {
    border: "1px solid #6c328b",
    width: "200px",
    borderRadius: "12px",
    height: "40px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "16px",
    textAlign: "center" as const,
    color: "#6c328b",
    textTransform: "capitalize" as const,
  },
  autoComplete: {
    maxWidth: "369px",
    width:"100%",
    background: "#FCF8F8",
    "& .MuiAutocomplete-inputRoot[class*='MuiInput-root']": {
      padding: "12px",
      marginTop: "5px"
    },
    "& .Mui-focused": {
     background:"#fff",
     color:"#000",
     outline:"none",
     border:"transparent"
    },
    "@media(max-width:800px)": {
      maxWidth:"800px",
      width:"100%"
     },
  },

  contentDiv: {
    display: "flex",
    gap: "20px",
    flexWrap: "nowrap" as const,
    "@media (max-width: 1300px)": {
      flexWrap: "nowrap",
    },
    "@media (max-width: 700px)": {
      flexWrap: "wrap",
    },
  },
  enableBTns:{
    backgroundColor:"#6C328B",
    color:"#FFFFFF",
    cursor:"pointer",

  },
  tagCss:
  {display: 'flex',
  gap:"30px",
  "@media(max-width:800px)": {
   flexDirection:"column"
  },
  },
  dividerCss:{
 height: "80px",
  width: "1px", 
  backgroundColor: "#929090",
  "@media(max-width:800px)": {
   display:"none"
   },
  } ,
  tagFieldCss:{
    minWidth: '369px', 
    background: "#fff7ff",
    "@media(max-width:800px)": {
      width:"100%"
     },
  }

})
export default withStyles(webStyle)(CustomLessonForm)
// Customizable Area End
