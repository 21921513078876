import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { IBlock } from "./../../../framework/src/IBlock";
import { Message } from "./../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "./../../../framework/src/Messages/MessageEnum";
import { runEngine } from "./../../../framework/src/RunEngine";
export const configJSON = require("./config");
interface ExpertAccount {
    id: string;
    type: string;
    attributes: {
      first_name: string;
      last_name: string;
      email: string;
      profession: string;
      full_name: string;
      avatar: string | null;
      is_admin: boolean;
    };
}

interface OwnerData {
    data: Array<ExpertAccount>
}

interface BranchList {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
    };
}

interface AssigneeData {
    data: Array<BranchList>
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface State {
    // Customizable Area Start
    user: string;
    icon: string;
    achievementName: string;
    ownerText: string;
    personName: Array<string>
    names: AssigneeData;
    status: string;
    dueDate: Date,
    completeContentToggle: boolean;
    daysLoggedInToggle: boolean;
    lessonsCompletedToggle: boolean;
    coursesCompletedToggle: boolean;
    loggedInRowToggle: boolean;
    daysLoggedIn: number;
    lessonsCompleted: number;
    coursesCompleted: number;
    loggedInRow: number;
    ownerNameList: OwnerData;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class CreateAchievementController extends BlockComponent<Props, State, SS> {

    // Customizable Area Start
    getOwnerLsitApiCallId: string = "";
    getBranchNameApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            user: "",
            icon: "",
            achievementName: "",
            personName: [],
            names: {
                data: []
            },
            status: "Active",
            dueDate: new Date(),
            completeContentToggle: false,
            daysLoggedInToggle: false,
            lessonsCompletedToggle: false,
            coursesCompletedToggle: false,
            loggedInRowToggle: false,
            daysLoggedIn: 0,
            lessonsCompleted: 0,
            coursesCompleted: 0,
            loggedInRow: 0,
            ownerNameList: {
                data: []
            },
            ownerText: ""
            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIRequestMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.NavigationTargetMessage),
          getName(MessageEnum.NavigationPropsMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getOwnerListApiCall();
        this.getBranchNameApiCall();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson) {
                if (responseJson.data) {
                    this.apiSuccessCallBack(apiRequestCallId, responseJson);
                }
            }
        }
    }

    apiSuccessCallBack = async (apiCallId: string, response: OwnerData & AssigneeData) => {
        if (apiCallId === this.getOwnerLsitApiCallId) {
            this.getOwnerListSuccessCallBack(response)
        }
        if (apiCallId === this.getBranchNameApiCallId) {
            this.getBranchNameSuccessCallBack(response)
        }
    };

    apiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
    }) => {
        let { contentType, method, endPoint, body } = valueData;
        const token = localStorage.getItem("token");
        let header = {
            "Content-Type": contentType,
            token,
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getOwnerListApiCall = async () => {
        this.getOwnerLsitApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getOwnerListEndpoint
        });
    };

    getOwnerListSuccessCallBack = (response: OwnerData) => {
        this.setState({ownerNameList : response});
    };

    getBranchNameApiCall = async () => {
        this.getBranchNameApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getBranchNameEndpoint
        });
    };

    getBranchNameSuccessCallBack = (response: AssigneeData) => {
        this.setState({names: response});
    };

    // Customizable Area End
}