import React from 'react';
import { Typography, Button, Box, styled, DialogTitle, Dialog, TableRow, TableCell, Badge, Checkbox, TableBody, Modal,Popover, TableContainer} from '@material-ui/core';
import CourseCreationController, { AttributesType, Props } from './CourseCreationController.web';

import { NewFilter, NotFoundImage, Filter, BackgroundColor } from '../src/assets';
import './CourseCreation.css';
import { BackArrow, UnlinkIcon, dotsIcon} from './assets';
import { DownArrowIcon, UpArrowIcon, editNew} from "../../../components/src/Table/asset";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
import FilterItems from "../../filteritems/src/FilterItems.web";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import PhaseDragDrop from '../../../components/src/LessonDragDrop/PhaseDragDrop';
import CloseIcon from '@material-ui/icons/Close';
import { Table, Thead, Tbody, Th, Tr, Td } from "../../../components/src/CustomTableV3";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loader from "../../../components/src/Loader";

const CreateNewVideoComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: 'flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize',
});

const webStyle = {
  AccordionSummary: {
    height: '42px',
    backgroundColor: '#6C328B',
    borderRadius: '12px',
    width: '165px',
    padding: "12px 24px 12px 16px",
    marginTop: '32px',
  },
  AccordionCourseSummary:  {
    height: '42px',
    backgroundColor: '#6C328B',
    borderRadius: '12px',
    width: '165px',
    padding: "12px 24px 12px 16px",
    marginLeft: 'auto'
  },
  
  statusNew: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#EEE8F2",
    color: "#652786",
    textTransform:"capitalize" as 'capitalize'
    
  },
  statusDraft: {
    display: 'flex',
    borderRadius: "88px",
    padding: "6px 9px",
    textTransform:"capitalize" as 'capitalize',
    justifyContent: "center",
    gap: "10px",
    background: "#FFEDE4",
    color: "#E47004",
    width: "96px",
    height: "40px",
    alignItems: "center",
  },
  statusArchived: {
    display: 'flex',
    padding: "6px 9px",
    borderRadius: "88px",
    justifyContent: "center",
    alignItems: "center",
    textTransform:"capitalize" as 'capitalize',
    gap: "10px",
    background: "#FFD9DF",
    color: "#E70E6B",
    width: "96px",
    height: "40px",
  },
  buttonsLesson: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    justifyContent: { xs: "center", md: "end" },
    alignItems: "center",
    cursor: "pointer"
  },
  containtContainer2: {
    borderRadius: "16px",
    height: "auto"
  },
  addPhasesButton: {
    width: "164px",
    height: "36px",
    padding: "6px 10px 6px 10px",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  addPhaseButtonText: {
    fontFamily: "'Poppins', sans-serif" as "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color: "rgba(101, 43, 133, 1)",
    textTransform: "capitalize" as "capitalize",
    textDecoration:"underline",
    textDecorationColor:"#652B85"
  },
  lessonRightContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  mainLessonWrapper: {
    minHeight: "59px",
    boxShadow: "1px 2px 10px 0 rgba(0, 0, 0, 0.07)",
    backgroundColor: "#EEE8F2",
    padding: "17px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 'auto'
  },
  modalMainHeading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "33px",
    color:"#000"
  },
  modalSubHeading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "22px",
    display:"flex",
    gap:"5px",
    alignItems:"center",
    color:"#000"
  },
  hoverContainer: {
    height: 0,
    border: "4px solid #B293C2",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonText: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color:"#ffffff",
    textTransform:"capitalize" as const,
  },
    saveButtonText: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center" as "center",
    color:"#542070",
    textTransform:"capitalize" as const,
    margin:"0px 5px"
  },
  dropDownArrow: {
    width: "24.66px",
    height: "24px",
  },
  innerLeftContainer: {
    display: "flex",
    gap: "16px"
  },
  secondContainerLesson: {
    backgroundColor: "#F4F0EF"
  },
  innerRightContainer: {
    display: "flex",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center"
  },
  imageContainerLesson: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    height: "178px",
    width: "271.61px"
  },
  secondaryButton: {
    width: "162px",
    height: "42px",
    borderRadius: "12px",
    gap: "16px",
    color: "#FFFFFF",
  },  
  dialogRenamePhases: {
    backgroundColor: "#FFFFFF",
    height: "40px",
    zIndex: 9,
    position: "absolute" as "absolute",
    left: "60px",
    borderRadius: "12px",
    width: "140px",
    padding: "8px 16px 8px 16px",
    top: "-20px",
    flexDirection: "column" as "column",
    gap: "8px",
    display: "flex",
  },
  addQuizButtonPhases: {
    textTransform: "capitalize" as "capitalize",
    display: "flex",
    gap: "8px",

  },
  addQuizButton: {
    width: "161px",
    height: "36px",
    padding: "10px 16px",
    textTransform: "capitalize" as "capitalize",
    display: "flex",
    backgroundColor: "#652786",
    borderRadius: "8px",
  },
}

const ModalMainBody = styled(Modal)({
  display:"flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center"
});

const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "auto",
  padding: "9px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  paddingTop: "20px",
  paddingBottom: "25px",
  "@media (max-width: 656px)": {
    maxWidth:"400px",
  },
  "@media (max-width: 400px)": {
    maxWidth:"330px",
  }
});
const IconAlign = styled(Box)({
  position: "absolute",
  top: "-6",
  right: "-6",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white",
 
  "& .MuiSvgIcon-root":{
    fontSize:"20px",
  }
});
const ButtonGroup = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent:"space-between",
  gap: "23px",
  padding: "0px 2px 0px 2px"
});
const ModalText = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:500,
  fontSize:"22px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const ModalText1 = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:400,
  fontSize:"16px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const ButtonShape2 = styled(Button)({
  width: "48%",
  height: "42px",
  background: "1px solid ##652786 !important",
  borderRadius:"12px",
  fontWeight:600,
  fontSize:"16px",
  color: "#E47004",
  textTransform:"none",
});

const FiterImage = styled("img")({
});
interface HeadCell {
    id: keyof AttributesType;
    label: string;
    colSpan?: number;
  }
  const ButtonShape1 = styled(Button)({
    width: "48%",
    height: "42px",
    fontWeight:600,
    fontSize:"16px",
    background: "1px solid #652786 !important",
    border: "1px solid #E47004",
    borderRadius:"12px",
    color: "#E47004",
    textTransform:"none",
  });
const headCells: HeadCell[] = [
    { id: "title", label: "Course", colSpan: 2 },
    { id: "course_id", label: "ID" },
    {
      id: "xpert_name",
      label: "Xpert Name",
    },
    {
      id: "content_roles",
      label: "Content Role(s)",
    },
    {
      id: "total_chapters",
      label: "Lessons",
    },
    {
      id: "user_enrolled",
      label: "Users enrolled",
    },
    {
      id: "updated_at",
      label: "Last day modified"
    },
    { id: "status", label: "Status" },
  ];
  
const NoDataImg = styled("img")({
        width: "616 !important",
        height: "404px !important"
});
const ImageComponent = styled("img")({
  width: "100%",
  height: "100%"
});
const ButtonBox = styled(Box)({
  padding:"10px 10px 0px 10px",
  width:"100%",
  "& .MuiButton-root:hover":{
    backgroundColor: "white",
  }
});

const BadgeComponents = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px"
  }
});
  const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    maxWidth: "520px",
    width: "100%",

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    minWidth: "62px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
  },
  "& .filterIconBox:hover": {
    borderColor: '#652786'
  },
  "& .filterBorder": {
    borderColor: '#652786',
    backgroundColor: "#EEE8F2",
  },
});
const NotFoundText = styled(Typography)({
  marginTop: "26px",
  textAlign: "center",
  color: "#1C1B1B !important",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  letterSpacing: "-1px",
  marginBottom: "16px",
});

const DialogWrapper=styled(Dialog)({
  "& .MuiDialog-paper":{
    maxWidth:"1275px",
    width:"100% !important",
   height:"100%",
   borderRadius:"16px",
   padding:"30px",
   marginLeft:"96px",
   marginTop:"35px",
   "@media(max-width:600px)":{
    margin:"10px",
    marginTop:"80px"
   }, 
  },
  "& .heading_wrapper":{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      marginBottom:"20px",
      "@media(max-width:1120px)":{
       flexDirection:"column",
       alignItems:"flex-start",
       gap:"20px"
      }, 
      "@media(max-width:600px)":{
        alignItems:"center",
    
       }, 
  },
  "& .heading_inner":{
      display:"flex",
      alignItems:"center",
      gap:"5px"
  },
  "& .heading_text":{
    fontSize:"48px",
    fontWeight:700,
    fontFamily:"Poppins",
    margin:0,
    padding:0,
  
    "@media(max-width:1250px)":{
      fontSize: "35px",
     }, 
    "@media(max-width:600px)":{
      fontSize: "22px",
    }, 
  },
  "& .btnWrapper":{
      display:"flex",
      justifyContent:"flex-end",
      alignItems:"center", 
      "@media(max-width:600px)":{
        justifyContent:"center",
      }, 
  },
  "& .btn": {
      marginTop:"30px",
      width:"228px",
      height:"56px",
      backgroundColor: "#E5E2E1",
      border: "0px",
      borderRadius: "16px",
      color: "#787776",
      fontFamily: "'Poppins', sans-serif" as const,
      fontSize: "16px",
      fontWeight: 600 as const,
      textTransform: "capitalize" as const,
    },
    "& .enablebtn":{
      backgroundColor: "#652786 !important",
      cursor:"pointer",
      color:"#fff"
    },
    "& .headingLabel":{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
     textAlign:"start",
      "@media(max-width:600px)":{
        fontSize: "18px",
        minWidth:"140px"
      }, 
    },
    "& .arrowIconsContainer":{
      display: "flex",
      flexDirection: "column",
      margin: "3px 0px 0px 10px",
      "& > img": {
        cursor: "pointer",
      },
      "& > img:first-child": {
        marginBottom: 5,
      },
    },
    "& .MuiRadio-root":{
       color:"#787776",
       padding:"18px 20px"
    },
    "& .MuiFormControlLabel-root":{
    margin:"0px"
    },
    "& .MuiRadio-colorSecondary.Mui-checked":{
      color:"#652786",
      background:"transparent"
    },
    "& .MuiDialogContent-root":{
      padding:"0px"
    }
}) 

const TableWrapper = styled(Box)({
  width: "100%",
  "& .tr":{
      borderRadius:"12px",
  },
  "& .td":{
      textAlign:"start"
  },
  "& .table tbody .tr":{
    cursor:"pointer"
},
  "& .td:first-child":{
    display:"flex",
    minWidth:"120px",
    borderBottomLeftRadius: "12px",
   borderTopLeftRadius: "12px"
  },
  "& .td:last-child":{
      borderBottomRightRadius: "12px",
     borderTopRightRadius: "12px",
     paddingLeft:"20px",
    },
  "& .table tbody .tr .td:nth-child(4)":{
      minWidth:"180px",
      paddingLeft:"20px",
      textAlign: "center"
    },
    "& .table tbody .tr .td:nth-child(5)":{
      minWidth:"180px",
      paddingLeft:"20px"
    },
    "& .table tbody .tr .td:nth-child(1)":{
      alignItems: "center",
      paddingLeft: "10px"
    },
    "& .table tbody .tr .td:nth-child(3) ,.td:nth-child(6), & .table tbody .tr .td:nth-child(3) ,.td:nth-child(7)":{
      textAlign: "center"
    },
    "& .table tbody .tr .td:nth-child(3) ,.td:nth-child(8)":{
      textAlign: "center",
      minWidth:"130px",
    },
  "& .table thead .tr .th:nth-child(1)":{
      width:"100px"
    },
    "& .table tbody .rowBg":{
    backgroundColor:"#EEE8F2 !important",
    },
  "& .table thead .tr .th:nth-child(2)":{
    "& p":{
      justifyContent: "flex-center !important",
    }
  },
  "& .table thead .tr .th:nth-child(4)":{
      minWidth:"210px",
  },
  "& .table thead .tr .th:nth-child(8)":{
      minWidth:"130px",
  },
  "& .table thead .tr .th":{
    "& p":{
      marginLeft:"20px"
    }
  },
  "& .table tbody .tr .td:nth-child(2)":{
    textAlign:"start",
    paddingLeft:"20px",
    lineHeight: "22px",
    minWidth:"210px",
  },
 "& .BackgroundCss":{
      background:"#EEE8F2"
  }
}) 

class ProgramContent extends CourseCreationController {
  constructor(props: Props) {

    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/'); // Splits the path into segments
    const id = pathSegments[pathSegments.length - 2]; // Extract the second last part for 'id'
    const type = pathSegments[pathSegments.length - 1]; // Extract the last part for 'type'
    if (id) {
      this.getProgramDetail(id);
    }
    if ( type === "Phases") {
      this.setState({
        phasesIncluded: true
      })
    }
  }
  renderCourseButton = () => {
    if (this.state.coursesSelected) {
      return (
        <Button
          onClick={() => this.handleCourseOpen(this.state.defaultPhaseId || this.state.phaseID)}
          style={webStyle.AccordionCourseSummary}
          data-test-id="panel1a-content"
          aria-controls="panel1a-content"
          id="panel1a-add-header"
        >
          <CreateNewVideoComponent>
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
                paddingRight: "10px",
                fontFamily: "Poppins, sans-serif",
                marginTop: '0',
              }}
            >
              +
            </span>
            Add Course
          </CreateNewVideoComponent>
        </Button>
      );
    }
    return null;
  };  

  renderContent = () => {
    const { coursesSelected,phasesIncluded} = this.state;  
    return (
<>
    {(!coursesSelected && !phasesIncluded) && (
       
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "32px",
            }}
          >
            <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID" />
            <Typography className="no-content">
              You don't have any content yet
            </Typography>
          </Box>
          <Button
            onClick={() => this.handleCourseOpen(this.state.defaultPhaseId || this.state.phaseID)}
            style={webStyle.AccordionSummary}
            data-test-id="panel1a-content"
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <CreateNewVideoComponent>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  paddingRight: "10px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                +
              </span>
              Add Course
            </CreateNewVideoComponent>
          </Button>
        </Box>  
      )
    }
    
    {phasesIncluded  && (
      <Box style={webStyle.containtContainer2}>
      <PhaseDragDrop
              phasesContent={this.state.phasesContent}
              onDragEnd={this.onDragEnd}
              handleMouseEnter={this.handleMouseEnter}
              handleMouseLeave={this.handleMouseLeave}
              handleLessonDeleteDialog={this.handleLessonDeleteDialog}
              addDialogBoxopen={this.state.addDialogBoxopen}
              dialogImageToggle={this.state.dialogImageToggle}
              handleAddIconClick={this.handleAddIconClick}
              hoverLessonId={this.state.hoverLessonId}
              isHovering={this.state.isHovering}
              handleDragEnter={this.handleDragEnter}
              handleDragLeave={this.handleDragLeave}
              editingIndex={this.state.editingIndex}
              courseData={this.state.programs}
              showPhassesDropDown={this.state.showPhassesDropDown}
              handlePhaseMouseEnter={this.handlePhaseMouseEnter}
              hoverPhaseId={this.state.hoverPhaseId}
              handlePhaseDeleteDialog={this.handlePhaseDeleteDialog}
              handlePhaseAddIconClick={this.handlePhaseAddIconClick}
              phases={true}
              handleAddIconOpenLessonNew={this.handleAddIconOpenLessonNew}
              handleMouseLeavePhase={this.handleMouseLeavePhase}
              showPhasesDialogLesson={this.state.showPhasesDialogLesson}
              handleMouseEnterPhases={this.handleMouseEnterPhases}
              handleRenameText={this.handleRenameText}
              handleLeavePhaseRename={this.handleLeavePhaseRename}
              editingText={this.state.editingText}
              showRenameDialog={this.state.showRenameDialog}
              handleDropDown={this.handleDropDown}
              handleEnterPhaseRename={this.handleEnterPhaseRename}
              handleEditClick={this.handleEditClick}
              handleSaveName={this.handleSaveName}
              webStyle={webStyle}
              handleOpenLessson={this.handleCourseOpen}
              showEmptyText={this.showEmptyText}
              handleLessonOpen={this.handleCourseOpen}
              addedContent={this.renderCourseAddedContent()}
              courseSelected={this.state.coursesSelected}
              selectedPhaseId={this.state.phaseID}
              phaseCoursesMap= {this.state.phaseCoursesMap}
              handleLessonEdit={this.handleLessonEdit}
    />    
    </Box>
    )
  }
  </>
    )
  }
  renderCourseAddedContent = () => {
    const coursesToDisplay = this.getCoursesToDisplay();
    if (!coursesToDisplay) {
      return null;
    } else {
      return (
        <>
          <TableBody
            data-test-id="coursesTable"
            style={{
              overflowY: "auto",
              borderRadius: "18px",
              display: "block",
              boxShadow: "1px 2px 10px 0px #00000011",
              marginTop: "8px",
            }}
          >
            <DragDropContext onDragEnd={this.handleDragCourse}  data-test-id="dnd-context">
              <Droppable droppableId="droppable-courses" data-test-id="droppable-courses">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ display: "block" }}
                  >
                    {coursesToDisplay?.map((row: any, index: any) => (
                      <Draggable
                        data-test-id="draggable-row"
                        key={row.id}
                        draggableId={row.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            hover
                            onMouseEnter={() => this.setState({ hoveredIndex: index })}
                            onMouseLeave={() => this.setState({ hoveredIndex: -1 })}
                            style={{
                              backgroundColor: this.getBGColor(snapshot?.isDragging),
                              padding:  this.getPadding(this.state.phasesIncluded),
                              width: "100%",
                              display: "flex",
                              boxShadow: "1px 2px 10px 0px #00000011 !important",
                              borderCollapse: "separate",
                              ...provided.draggableProps?.style,
                            }}
                          >
                            <TableCell
                              padding="none"
                              style={{
                                fontWeight: 600,
                                fontSize: "18px",
                                color: "#484646",
                                display: "flex",
                                alignItems: "center",
                                width: "300px",
                                border: "none",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  left: "-30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                  opacity: this.getOpacity(this.state.hoveredIndex, index),
                                  transition: "opacity 0.2s",
                                }}
                                {...provided.dragHandleProps}
                              >
                                <img
                                  src={dotsIcon}
                                  style={{ width: "24px", height: "24px" }}
                                  alt="Organize"
                                />
                              </div>
  
                              {row.name}
                            </TableCell>
  
                            <TableCell
                              padding="none"
                              style={{ border: "none", alignContent: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  ...(this.getStatusStyle(row.status)),
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  width: "80px",
                                  height: "30px",
                                  borderRadius: "88px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  maxWidth: "300px",
                                }}
                              >
                                {this.toPascalCase(row.status)}
                              </div>
                            </TableCell>
                            <TableCell
                              padding="none"
                              style={{
                                alignContent: "center",
                                border: "none",
                                flex: 1,
                              }}
                            >
                              <Box style={{ display: "flex", justifyContent: "right" }}>
                                <img
                                  data-test-id="edit-course-btn"
                                  onClick={() => this.handleEditCourse(row.id)}
                                  src={editNew}
                                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                                  alt="Edit Icon"
                                />
                                <img
                                  data-test-id="detach-course-btn"
                                  onClick={() => this.handleDetach(row)}
                                  src={UnlinkIcon}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "12px",
                                    cursor: "pointer",
                                  }}
                                  alt="Unlink Icon"
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </TableBody>
  
          <DeleteModal
          data-test-id="Phase_modal"
          openDialog={this.state.RemovePhase}
          headingText={`Are you sure you want to detach ${this.state.selectedCourseName}?`}
          subHeading={"The course will no longer be linked to this program but can still be accessed directly."}
          btnCancelText={"Cancel"}
          btnOkText={"Detach From Program"}
          handleCancel={this.handlePhaseCancel}
          handleOk={this.handleRemovePhase}
        />
        </>
        );
    }
    }
  
  getMinWidthByIndex = (index:number) => {
    if (index === 0) return '166px';
    if (index === 1) return '145px';
    if (index === 2) return '152px';
    if (index === 6) return '100px';

  };
  getMaxWidthByIndex = (index:number) => {
    if (index === 3) return '160px';
    if (index === 4) return '100px';
    if (index === 5) return '100px';
    if (index === 6) return '120px';


  };
  getId = (index: number) => {
    return index === 4 ? "last" : "";
  }  
  getClassName = (index: number) => {
    return index === 0 ? "first" : "all";
  }
  renderFilterIcon = () => {
    const { anchorFilterContainerEl } = this.state; // or props if it's passed as a prop
  
    if (anchorFilterContainerEl) {
      return (
        <FiterImage
          src={Filter}
          data-test-id="filterOpenTestID"
          alt="Filter"
        />
      );
    } else {
      return (
        <img
          src={NewFilter}
          data-test-id="filterOpenTestID"
          alt="New Filter"
        />
      );
    }
  };

  renderCoursesTable = () => {
    if (this.state.isCoursesLoading) {
      return <Loader loading={this.state.isCoursesLoading} />;
    } else if (!this.state.isCoursesLoading && this.state.courseResponse.length === 0) {
      return (
        <>
          <Box style={{ justifyContent: 'center', display: 'flex', marginTop: '64px' }}>
            <NoDataImg src={NotFoundImage} />
          </Box>
          <NotFoundText>No results </NotFoundText>
          <Typography
            style={{
              justifyContent: 'center',
              display: 'flex',
              fontWeight: 400,
              fontSize: '16px',
              color: '#787776',
            }}
          >
            Try and widen your search
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <TableContainer>
            <TableWrapper>
              <Table>
                <Thead>
                  <Tr>
                    {headCells?.map((header: any, index: number) => {
                      return (
                        <Th key={index} colSpan={header.colSpan}>
                          <p className="headingLabel">
                            {header.label}
                            <span className="arrowIconsContainer">
                              {this.shouldDisplaySortArrows(index) && (
                                <>
                                  <img
                                    data-test-id={`sort_desc${header.label}`}
                                    src={UpArrowIcon}
                                    width="12px"
                                    onClick={() => this.handleSort(header.id)}
                                  />
                                </>
                              )}
                            </span>
                          </p>
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>{this.renderTableBody()}</Tbody>
              </Table>
            </TableWrapper>
          </TableContainer>
  
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '16px',
              gap: '24px',
            }}
          >
            <Button
              className="btn-popup"
              style={{
                width: '227px',
                height: '56px',
                borderRadius: '16px',
                border: '1px solid #E47004',
                color: '#E47004',
                textTransform: 'capitalize',
              }}
              onClick={this.handlePopupClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn-popup"
              style={{
                width: '227px',
                height: '56px',
                borderRadius: '16px',
                backgroundColor: this.getBackgroundColor2(this.state.selectedRows.length === 0),
                color: this.getTextColor(this.state.selectedRows.length === 0),
                textTransform: 'capitalize',
              }}
              onClick={this.handleSelectCourse}
              disabled={this.state.selectedRows.length === 0}
            >
              Select
            </Button>
          </Box>
        </>
      );
    }
  };
  
  renderTableBody=()=>{
    return(
      <>
        {this.state.courseResponse?.map((row, index) => { 
           const BgColors=this.state.selectedRows.includes(row.course_id)
          const labelId = `checkbox-${index}`;

              return (
                <Tr key={index} rowBg={BgColors}>
                  <Td rowBg={BgColors}>
                  <Checkbox
                  checked={this.state.selectedRows.includes(row.course_id)}
                  onChange={(event) => this.handleCheckboxChange(row.course_id, row.title, row.status,this.state.phaseID)(event)}
                  inputProps={{ 'aria-labelledby': labelId }}
                    className='popup-checkbox'
                    data-test-id="checkbox_phases"
                  />
                  <img
                      src={row?.thumbnail_image?.url}
                      alt={row?.title}
                      style={{
                        borderRadius: '4px',
                        border: '1px solid #929090',
                        background: 'lightgray 50% / cover no-repeat',
                        boxShadow: '0px 1px 3px 0px rgba(140, 140, 140, 0.72)',
                        height: '40px',
                        width: '40px',
                        marginLeft:"16px"
                      }}
                    />
                  </Td>
                    <Td rowBg={BgColors}>
                    {row.title}
                    </Td> 
                    <Td rowBg={BgColors}>
                    {row.number || "--"}
                    </Td> 
                    <Td rowBg={BgColors}>
                    {row.xpert_name}
                    </Td>  
                    <Td rowBg={BgColors}>
                    {row.content_roles?.join(' ').length > 20 
                    ? `${row.content_roles?.join(' ').slice(0, 20)}...` 
                    : row.content_roles?.join(' ')}
                    </Td>
                    <Td rowBg={BgColors}>
                    {row.total_chapters}
                    </Td>
                    <Td rowBg={BgColors}>
                    {row.user_enrolled}
                    </Td>    
                    <Td rowBg={BgColors}>
                    {this.formatDate(row.updated_at)}
                    </Td>    
                    <Td rowBg={BgColors}>
                    <div style={{ display: "flex", ...(this.getStatusStyle(row.status)), width: '96px',height: '40px',borderRadius: '88px',justifyContent: "center", alignItems: "center", maxWidth: "300px" }}>
                        {this.toPascalCase(row.status)}
                    </div>
                    </Td>           
                </Tr>)})}
      </>
    )
  }

  getBackgroundColor = (id:string) => {
    const { selectedRows } = this.state;
    return selectedRows.includes(id) ? '#EEE8F2' : 'inherit';
  }
shouldDisplaySortArrows = (index: number): boolean => {
    return ![4, 5, 7].includes(index);
  };
   getBackgroundColor2 = (isSelectButtonDisabled: boolean): string => {
    return isSelectButtonDisabled ? '#E5E2E1' : '#652786';
  };
  getTextColor = (isSelectButtonDisabled: boolean): string => {
    return isSelectButtonDisabled ? '#929090' : '#ffffff';
  };
  getBorderStyle = (isNoRowPresent: boolean): string => {
    return isNoRowPresent ? '1px solid #E5E2E1' : '1px solid #E47004';
  };
  getTextColor2 = (isNoRowPresent: boolean): string => {
    return isNoRowPresent ? '#929090' : '#E47004';
  };
  getBGColor = (isDragging: boolean) => {
    return isDragging ? '#f0f0f0' : 'inherit';
  }
  getOpacity = (hoveredIndex: number, index: number) => {
    return hoveredIndex === index ? 1 : 0;
  }
  getPadding = (phasesIncluded: boolean) => {
    return phasesIncluded ? "18px 64px" : "18px 32px";
  }
  
  render() {
    const { anchorFilterContainerEl, courseResponse, selectedRows,coursesSelected } = this.state;
    const isNoRowPresent = !coursesSelected;
    const isSelectButtonDisabled = selectedRows.length === 0;
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl) 
   
    return (
      <>
    <div
     style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: this.state.phasesIncluded ? '6px 24px':'24px',
      boxShadow: this.state.coursesSelected ? '1px 2px 10px 0px rgba(0, 0, 0, 0.0677)' : '',
      borderRadius: '18px'
    }}>
      <Box style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
 <Box style={{
        display: "flex",
        justifyContent: "start",
        flexDirection: "row",
        alignItems: "center",
        columnGap: "24px",
        padding: this.state.phasesIncluded? "0px" : "0 24px",
      }}>
              <Typography className='program-title' data-test-id="program-title">
                  Program Title: {this.state.programTitle}
              </Typography>
              <div style={{ display: this.state.programTitle ? "flex" : "none", justifyContent: "space-between", alignItems: "center", maxWidth: "300px" }}>
              <div style={this.statusOptionData(this.state.programStatus)}
                  >
                  {this.state.programStatus}
              </div>
          </div>
          </Box>
          {this.state.phasesIncluded && (
          <Box sx={webStyle.addPhasesButton}>
          <Box sx={webStyle.imageContainer}>
            <img style={{minWidth: "16px", minHeight: "16px"}} src={BackgroundColor} alt="BackgroundColor" />
          </Box>
          <Button style={webStyle.addPhaseButtonText} data-testId="AddPhaseBtn" onClick={() => { this.handleAddPhase(this.state.programs.id, true) }}>Add phase</Button>
        </Box>
          )}
      {!this.state.phasesIncluded && this.renderCourseButton()}
      </Box>
      {!this.state.phasesIncluded && this.renderCourseAddedContent()}
      </div>
      {this.renderContent()}
    <DialogWrapper
        open={this.state.showCoursePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='popup-dialog'
      >
          <Box style={{display:"flex", gap:"28px", flexDirection:"column"}}>
        <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
          <Box className="heading_course_wrapper">
              <span>
                <img src={BackArrow} alt="back_img" style={{ cursor: 'pointer' }} onClick={this.handlePopupBackClose} />
              </span>
              <p className="heading_text">Select courses</p>
          </Box>
        </DialogTitle>
        <StyledContentWrapper  
       >
          <AdvancedSearch
            data-test-id="btn_change"
            isFilterDropActive={openFilterContainer}
            handleListItemClick={this.handleListItemClickSuggestion}
            searchInputValue={this.state.searchtext}
            handleInputChange={this.handleInputProgramChangeSearch}
            courseResponse={this.state.courseResponse}
            filterList={this.state.filteredList}
            showList={this.state.showList} navigation={undefined} id={""} />
          <div style={{ marginLeft: "-5px"}} data-test-id="filter-drop" className={anchorFilterContainerEl ? "filterIconBox filterBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
            {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
              <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
              ""
            )
            }
          {this.renderFilterIcon()}
          </div>
          <Popover
            data-test-id="filterPropID"
            id="myPopover"
            open={openFilterContainer}
            className="filterContainerPopover"
            onClose={this.filterContainerDropdownCloseHandler}
            anchorEl={this.state.anchorFilterContainerEl}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
               <FilterItems
              data-test-id="FilterItemsTestID"
              isContentRole={this.state.contentRoleTrue}
              selectedStatus={this.state.selectedStatus}
              categorySelectedItems={this.state.categorySelectedItems}
              contentRoleSelectedItems={this.state.contentRoleSelectedItems}
              categorySearchText={this.state.categorySearchText}
              handleChangeStatus={this.handleCatalogueChangeStatus}
              handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
              handleCategorySearchChange={this.handleCategoryChangeSearch}
              contentRoleSearchText={this.state.contentRoleSearchText}
              placeholderSelectedItems={this.state.placeholderSelectedItems}
              placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
              handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
              contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandleChange}
              creationDateChangeHandler={this.creationChangeHandlerDate}
              dropdownOpenHandler={this.dropdownHandlerFilterOpen}
              dropdownCloseHandler={this.dropdownHandlerClose}
              handleClick={this.handleClickFilter}
              categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
              handleCalendarOpen={this.handleOpenCalendor}
              anchorEl={this.state.anchorElPopup}
              dropdownType={this.state.dropdownType}
              placeholderSearchText={this.state.placeholderSearchText}
              creationDate={this.state.creationDate}
              expertAdminList={this.state.expertAdminList}
              adminCatagoryList={this.state.adminCatagoryList}
              adminContentRolesList={this.state.adminContentRolesList}
              isCalendarOpen={this.state.isCalendarOpen}
              handleClear={this.handleClearFilter}
              handleCalendarChange={this.handleChangeCalendor} />
          </Popover>

        </StyledContentWrapper>
      </Box>
      {this.renderCoursesTable()}
      </DialogWrapper>
   <Box style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        columnGap: "16px",
        marginTop: "31px",

      }}>
        <Button 
         style={{
           display: 'flex',
           width: '345px',
           height: '56px',
           justifyContent: 'center',
           alignItems: 'center',
           gap: '10px',
           backgroundColor: 'transparent', // 'none' is not valid; use 'transparent'
           borderRadius: '16px',
           border: this.getBorderStyle(isNoRowPresent),
           color: this.getTextColor2(isNoRowPresent),
           marginTop: '16px',
           fontSize: '16px',
           fontWeight: 600,
           textTransform: 'none', 
        }}
          data-test-id='save-draft-btn'
         disabled={isNoRowPresent}
         onClick={() => this.handleOpenDraftProgramPrompt()}>
          Save to Draft
          </Button>
        <Button  style = {{
          display: 'flex',
          width: '345px',
          height: '56px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '16px',
          marginTop: '16px',
          textTransform: 'capitalize',
          fontSize: '16px',
          fontWeight: 600,
          ...this.getRowStyles(isNoRowPresent),
        }}
          data-test-id='save-active-btn'
         disabled={isNoRowPresent}
         onClick={() => this.handleOpenCreateProgramPrompt()}>Create Program</Button>
  </Box>
  <ModalMainBody

        open={this.state.openPhaseDelete}
        onClose={this.cancelPhaseDeleteDialog}>
        <ModalContent>
          <IconAlign onClick={this.cancelPhaseDeleteDialog}><CloseIcon /></IconAlign>
          <ModalText>{`Are you sure you want to delete this ${this.state.phaseName} ?`}</ModalText>
          <ModalText1>{"All courses within this phase will be permanently deleted. Content is retained in the content library."}</ModalText1>
          <ButtonBox>
          <ButtonGroup>
            <ButtonShape2 onClick={()=>this.handleDeletePhase(this.state.phaseID)} style={{color:"white", background:"#652786"}}>Delete</ButtonShape2>
            <ButtonShape1 onClick={this.cancelPhaseDeleteDialog}>Cancel</ButtonShape1>
          </ButtonGroup>
          </ButtonBox>
        </ModalContent>

      </ModalMainBody>
      <DeleteModal
            data-test-id='save-active-prompt'
            openDialog={this.state.createProgramPrompt}
            headingText={`Are you sure you want to create this program as active?`}
            subHeading={"This makes the program available to users."}
            btnOkText="Save as Active"
            btnCancelText="Save as Draft"
            handleCancel={() => this.handleCloseProgramPrompts()}
            handleOk={() => {this.handleClosePrompt(); this.handleToastSavedActive(); this.handleSaveActiveSaveDraftProgram("active")}}
            handleOptionOne={() => {this.handleClosePrompt(); this.handleToastSavedDraft(); this.handleSaveActiveSaveDraftProgram("draft")}}
            programOption={true}
          />
        <DeleteModal
            data-test-id='save-draft-prompt'
            openDialog={this.state.draftProgramPrompt}
            headingText={`Are you sure you want to create this program but keep it in Draft status?`}
            subHeading={"This means the program isn't available to users."}
            btnOkText="Save Draft"
            btnCancelText="Save & Make Active"
            handleCancel={() => this.handleCloseProgramPrompts()}
            handleOk={() => {this.handleClosePrompt(); this.handleToastSavedDraft(); this.handleSaveActiveSaveDraftProgram("draft")}}
            handleOptionOne={() => {this.handleClosePrompt(); this.handleToastSavedActive(); this.handleSaveActiveSaveDraftProgram("active")}}
            programOption={true}
          />
      </>
    );
  }

  getRowStyles(isNoRowPresent: boolean) {
    return {
      backgroundColor: isNoRowPresent ? '#E5E2E1' : '#652786',
      color: isNoRowPresent ? '#929090' : '#ffffff',
    };
  }
  
    statusOptionData(row: any): React.CSSProperties | undefined {
            if(row ==="active"){
              return webStyle.statusNew
            }else if(row === "draft"){
              return webStyle.statusDraft
            }else {
              return webStyle.statusArchived
            }
          }
    };

export default ProgramContent;
