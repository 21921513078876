import React from "react";
import {
  Box, Button, Grid
  // Customizable Area End
  ,
  // Customizable Area Start
  Paper, TextField, Typography
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled, withStyles } from '@material-ui/core/styles';
import NewlyAddedController from './NewlyAddedController.web';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import './videostyles.css';
import { SearchChild } from "./Search.web";
// Customizable Area Start
const img4 = require("../assets/slider-1.png");
const img2 = require("../assets/courseimage4.png");
const play = require("../assets/play.png")
const img3 = require("../assets/courseimage5.png")
const group = require("../assets/Group 8.png")
const Short = require("../assets/Sort.png")
const shop = require("../assets/image_Bitmap Copy 4.png")

// Customizable Area End
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      width: '60%',
      marginTop: '15px',
      marginLeft: '17px',
    },
    colorPrimary: {
      // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
      borderRadius: 5,
      background: `linear-gradient(87deg, rgba(240,50,76,1) 0%, rgba(254,132,64,1) 100%)`
    },
  }))(LinearProgress);

export default class NewlyAdded extends NewlyAddedController {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start

    // Customizable Area End

    _onFocus = () => {
      this.props.history.push('/Search')
    }
    render() {
         return (
      <>

            <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="h3"
                        style={{ 
                          fontFamily: "Poppins",
                          fontSize: "48px",
                          fontWeight: 700,
                          lineHeight: "72px",
                          textAlign: "left",
                          }}
                      >
                        Newly Added
                      </Typography>

                {/* <Grid item lg={12} md={12} sm={12} xs={12}> */}
                <GridWrapper container data-test-id="grid-wrapper">
                  {this.state.allInProgressData?.data?.length !== 0 ?
                  this.state.allInProgressData?.data?.map((ele: any, index: any) => {
                    return (
                  <GridItem  lg={2} md={4} sm={6} xs={12} item  key={index}>
                  <>
                   <Box>
                    <div style={{ 
                      position: 'relative' 
                      }}>
                      <CardMedia
                        style={{ height: "240px", width: '100%', borderRadius: '20px 20px 0px 0px' }}
                        image={`${ele?.attributes?.collection?.attributes?.thumbnail_image?.url}`}
                      />

                    {ele.attributes.collection.attributes.expand_type === "Program" && 
                    <Typography style={{...styles.chipTypography,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{ele.attributes.collection.attributes.expand_type}</Typography>}

                    <Paper
                      style={{ 
                        width: '100%', 
                        borderRadius: '20px', 
                        marginTop: '-80px', 
                        paddingLeft:"24px",
                        paddingTop:"21px",
                      }}>
                      <Typography
                        id="handle-title-click"
                        style={{
                          minHeight: 65, 
                          marginBottom: '-10px',
                          height:"auto",
                          fontWeight: 700, 
                          fontSize: '15px', 
                          lineHeight: '28px', 
                          cursor: 'pointer'
                        }}
                        onClick={() => this.handleNavigate(ele)}
                      >
                        {ele?.attributes?.collection?.attributes?.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{ 
                          marginBottom: '4px',
                          color: 'black',
                          fontWeight: 600, 
                          fontSize: '14px', 
                          lineHeight: '21px',
                        }}>
                        {ele?.attributes?.collection?.attributes?.created_by}
                      </Typography>

                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{
                          marginBottom: '6px', 
                          color: '#44444F',
                          fontWeight: 400, 
                          fontSize: '13px', 
                          lineHeight: '24px',
                        }}>
                        Due in {ele?.attributes?.due_in_days} days
                      </Typography>

                      {ele?.attributes?.collection?.attributes?.is_paid
                        ?

                        <>
                          <Card style={{
                            width: '87px',
                            height: '45px',
                            borderRadius: '30px',
                            float: 'right',
                            marginTop: '-2px', 
                            marginRight: '20px',
                            background: '#1D1F4F'
                          }}>
                            <CardActionArea>
                            </CardActionArea>
                          </Card>
                        </>
                        :
                        <ButtonWrapper>
                          {!ele?.attributes?.is_enrolled && 
                          <Card 
                          style={{
                            width: '106px', 
                            height: '48px',
                            borderRadius: '30px',
                            float: 'left',
                            marginTop: '-2px',
                            background: '#EF2B4B',
                            margin:"12px 6px 18px 0px"
                          }}>
                            <CardActionArea 
                            id="post-newlyadded-data-btn"
                            onClick={() => this.postplayButton(ele)}>
                            <img src={group} style={{ margin: 'auto', padding: '14px', display: 'flex' }} />
                            </CardActionArea>
                          </Card>}
                          
                          <Card style={{
                            width: '106px', 
                            height: '48px', 
                            borderRadius: '30px', 
                            float: 'right',
                            marginTop: '-2px',
                            marginRight: '25px',
                            background: '#6C328B',
                            margin:"12px 16px 18px 6px"
                          }}>
                            <CardActionArea
                              id="post-newlyadded-data-play-btn"
                              onClick={() => this.postplayButton(ele)}>
                            <img src={play} style={{ margin: 'auto', padding: '12px', display: 'flex' }} />
                            </CardActionArea>
                          </Card>
                        </ButtonWrapper>}
                    </Paper>
                    </div>
                  </Box>
                  </>
                  </GridItem>
                  )}) :
                  <><h2 style={{textAlign:'center',marginTop:'10%'}}>No Courses available</h2></>}
                </GridWrapper>

              </Grid>

            </Grid>
                <Paper style={{ marginTop: '25px', height: '50px' }}>
                  <Pagination
                    count={this.state.allInProgressData?.meta?.pagination?.total_pages}
                    page={this.state.page}
                    color="primary"
                    onChange={this.pagination}
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  />
                </Paper>
       </>



        )

    }

}

const GridWrapper = styled(Grid)({
    marginTop: "50px",
    padding:"0px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    opacity: "0px",
  })

  const GridItem = styled(Grid)({
    maxWidth: "260px !important",
    boxShadow:"0px 0px 16px #BFBEBE",
    margin:"1%",
    backgroundColor:"white",
    borderRadius: '20px',
  })

  const ButtonWrapper = styled(Box)({
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
  })
  const styles = {
    chipTypography:{
      height:"28px",
      width: "117px",
      borderRadius: "16px",
      padding: "3px 0px",
      fontWeight:400,
      color:"white",
      background: "#E70E6B",
      fontSize:"14px",
    },
    paperStyleBefore: {
        overflow: 'hidden', flex: 1, height: '525px', maxWidth: '90vw', margin: 'auto', padding: '30px', borderRadius: '24px', background: '#FFFFFF', marginTop: '25px'
    },
    paperStyleAfter: {
        overflow: 'hidden', flex: 1, minHeight: '525px', maxWidth: '90vw', margin: 'auto', padding: '30px', borderRadius: '24px', background: '#FFFFFF', marginTop: '25px'
    },
    mainhadding: {
        display: 'contents',
        fontSize: '48px',
        fontWeight: 600,
    },
    subHadding: {
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '5px'
    },
    mainContainer: {
        display: 'flex'
    },
    BoxContainer: {
        width: '100%',
        height: '45px',
        marginTop: '-5px'
    },
    filterButton: {
        color: '#6C328B',
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        fontWeight: 700,
        border: '1px solid #6C328B'
    },
    IconBox: {
        width: '100%',
        marginTop: '-5px',
        paddingLeft: '15px'
    },
    filterIcon: {
        width: '75px',
        height: '45px',
        borderRadius: '12px',
        border: '1px solid #6C328B',
        cursor: 'pointer'
    },
    paper_personal: {
        flex: 1,
        overflow: 'hidden',
        height: '490px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    },
    typography_personal: {
        fontWeight: 700,
        fontSize: '24px'
    },
    typography_view_personal: {
        fontWeight: 700,
        fontSize: '17px',
        float: 'right' as 'right',
        marginTop: '-36px',
        cursor: 'pointer',
        color: 'rgba(97, 97, 97, 1)'
    },
    img_shape: {
        paddingLeft: '13px'
    },
    paper_corporate: {
        overflow: 'hidden',
        flex: 1,
        height: '500px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    },
    paper_xpand: {
        overflow: 'hidden',
        flex: 1,
        height: '500px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    }
}