// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Rating from '@material-ui/lab/Rating';
import './videostyles.css';
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { courseImage, smallCheckIcon, startIcon, viewShape } from "./assets";


import ProgramPageController, {
    Props
} from "./ProgramPageController.web";
import { lessonpic } from "../../../../packages/blocks/catalogue/src/assets";

// Customizable Area End
export default class ProgramPage extends ProgramPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    handleAddToCartBtnRender = () => {
      return (
          <Box style={{
              display:"flex",
              flexDirection:"column",
              justifyContent:"flex-end",
              alignItems:"end"
          }}>
              <AddToCartBox>
          <Typography style={{
              fontWeight:700,fontSize:"24px",fontFamily:"Poppins"
          }}>${this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.price}/<span style={{
              fontWeight:400,fontSize:"16px",fontFamily:"Poppins"
          }}>month</span></Typography>
                      <Button
                          startIcon={<ShoppingCartOutlinedIcon style={{fontSize:"24px"}}/>}
                          style={{
                              width:"220px",height:"48px",
                              background: '#1C1B1B', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",
                              fontWeight:800,fontSize:"16px",fontFamily:"Poppins"
                   }}
                      >Add to cart</Button>
                      </AddToCartBox>
          </Box>
      )
  }

  handleStartBtnRender = () => {
      return (
                      <Button
                      startIcon={<img src={startIcon} style={{width:"24px",height:"24px"}}/>}
                          style={{
                              width:"220px",height:"48px",
                              background: '#1C1B1B', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",textTransform:"none",
                              fontWeight:600,fontSize:"16px",fontFamily:"Poppins"
                   }}>Start</Button>
      )
  }

  handleContinueBtnRender = () => {
      return (
                      <Button
                      startIcon={<img src={startIcon} style={{width:"24px",height:"24px"}}/>}
                      style={{
                              width:"220px",height:"48px",
                              background: '#E47004', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",
                              fontWeight:600,fontSize:"16px",fontFamily:"Poppins",textTransform:"none"
                   }}>Continue</Button>
      )
  }

  handleCompletedBtnRender = () => {
      return (
                      <Button
                          startIcon={<img src={smallCheckIcon} style={{width:"24px",height:"24px"}}/>}
                          style={{
                              width:"220px",height:"48px",
                              background: '#652786', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",textTransform:"none",
                              fontWeight:600,fontSize:"16px",fontFamily:"Poppins"
                   }}>Completd</Button>
      )
  }

  handleBtnRender = () =>{
      if(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.is_start){
        return this.handleStartBtnRender()
      }else if(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.is_continue){
        return this.handleContinueBtnRender()
      }else if(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.is_completed){
        return this.handleCompletedBtnRender()
      }else if(this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled){
        return this.handleAddToCartBtnRender()
      }
  }
    // Customizable Area Start

     renderPhasesData = (item:any) => {
        return (
            <CustomAccordionDetails key={item}>
               {item.description.length > 100 ?
                    (<LightNewTooltip
                      placement="top"enterDelay={500}
                      leaveDelay={100}enterTouchDelay={5}
                      leaveTouchDelay={10000}
                      interactive={true}
                      arrow={true}
                      title={
                        <NewToolTipMain>
                      <Typography style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            color:"black",
            }}>{item.description}</Typography>
                        </NewToolTipMain>
                      }
                    >
                    <Box style={{...webStyle.flexBox,gap:"12px",alignItems:"start"}}>
                        <img width="80px" height="80px" style={{
                            borderRadius:"8px"
                        }} src={item.thumbnail_image}/>
                        <Box>
                  <Typography style={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "22px",
            }}>{item.title}</Typography>
                  <Typography style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            }}>{`${item.description.slice(0,100)}...`}</Typography>
                  <Typography style={{display:"flex",
                  alignItems:"center",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "22px",
                    color:"#FF8B3F"
                  }}>{item.rating}<Rating
                    name='read-only'
                    value={item.rating}
                    /></Typography>
                  <Typography  style={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "22px",
            }}>{item.author_name}</Typography>
                  </Box>
                  </Box>
                  </LightNewTooltip>) : (
                    <Box style={{...webStyle.flexBox,gap:"12px",alignItems:"start"}}>
                        <img width="80px" height="80px" style={{borderRadius:"8px"
                        }} src={item.thumbnail_image}/>
                        <Box>
                  <Typography style={{
            fontSize: "16px",fontWeight: 700,
            lineHeight: "22px",
            }}>{item.title}</Typography>
                  <Typography style={{
            fontSize: "14px",fontWeight: 500,
            lineHeight: "22px",
            }}>{item.description.split(0,500)}</Typography>
                  <Typography style={{display:"flex",
                  alignItems:"center",
                    fontSize: "16px",
                    fontWeight: 600,lineHeight: "22px",
                    color:"#FF8B3F"}}>{item.rating}<Rating
                    name='read-only'
                    value={item.rating}
                    /></Typography>
                  <Typography  style={{
            fontSize: "12px",fontWeight: 400,
            lineHeight: "22px",
            }}>{item.author_name}</Typography>
                  </Box>
                  </Box>
                  )}
          </CustomAccordionDetails>
        )
     }

     renderWithoutPhaseData = (phases:any) => {
        return (
            phases.description.length > 100 ?
            (
                <LightNewTooltip
                placement="top"enterDelay={500}
                leaveDelay={100}enterTouchDelay={5}
                leaveTouchDelay={10000}interactive={true}
                arrow={true}title={
                  <NewToolTipMain>
                <Typography style={{
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      color:"black",
      }}>{phases.description}</Typography>
                  </NewToolTipMain>
                }
              ><CustomBox key={phases}>
                    <Box style={{...webStyle.flexBox,marginTop:"20px",gap:"12px",alignItems:"start"}}>
                        <img width="80px" height="80px" style={{
                            borderRadius:"8px"
                        }} src={phases.thumbnail_image}/>
                        <Box>
                  <Typography style={{
            fontSize: "16px",fontWeight: 700,
            lineHeight: "22px",}}>{phases.title}</Typography>
                  <Typography style={{fontSize: "14px",fontWeight: 500,lineHeight: "22px",}}>{`${phases.description.slice(0,100)}...`}</Typography>
                  <Typography style={{display:"flex",
                  alignItems:"center",fontSize: "16px",fontWeight: 600,lineHeight: "22px",color:"#FF8B3F"}}>{phases.rating}<Rating
                    name='read-only'
                    value={phases.rating}/></Typography>
                  <Typography  style={{fontSize: "12px",
            fontWeight: 400,
            lineHeight: "22px",
            }}>{phases.author_name}</Typography>
                  </Box>
                  </Box>
          </CustomBox></LightNewTooltip>)
          : (
            <Box key={phases}>
                    <Box style={{...webStyle.flexBox,marginTop:"20px",gap:"12px",alignItems:"start"}}>
                        <img width="80px" height="80px" style={{borderRadius:"8px"}} src={phases.thumbnail_image}/>
                        <Box>
                  <Typography style={{
            fontSize: "16px",
            fontWeight: 700,lineHeight: "22px",
            }}>{phases.title}</Typography>
                  <Typography style={{
            fontSize: "14px",
            fontWeight: 500,lineHeight: "22px",
            }}>{phases.description}</Typography>
                  <Typography style={{display:"flex",
                  alignItems:"center",
                    fontSize: "16px",fontWeight: 600,lineHeight: "22px",color:"#FF8B3F"
                  }}>{phases.rating}<Rating
                    name='read-only'
                    value={phases.rating}
                    />
                    </Typography>
                  <Typography  style={{
            fontSize: "12px",fontWeight: 400,lineHeight: "22px",
            }}>{phases.author_name}</Typography>
                  </Box></Box>
          </Box>
          )
        )
     }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Loader loading={this.state.isLoading} />
                <Grid container key={this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.id}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '14px' }}>
                        <Box style={webStyle.flexBox}>
                    <img src={viewShape} data-test-id="backButtonId" style={{padding:"0px 10px 0px 10px", width:"32px", height: "22px"}} onClick={this.gotoBackSide}/>
                        <Typography variant="h3" style={{ fontSize:"48",fontWeight: 700 }}>{this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.title}
                        </Typography>
                        </Box>
                        <Grid container spacing={4} style={{ marginTop: '20px' }}>

                            <Grid item lg={8} xs={12}>
                                <Box
                                    style={{
                                        width: "100%",
                                        height: "300px",
                                        borderTopRightRadius: "10px",
                                        borderTopLeftRadius: "10px",

                                    }}>
                                    <img src={this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.thumbnail_image.url} style={{ width: '100%', height: '300px', borderTopRightRadius: "20px", borderTopLeftRadius: "20px" }} alt="img" />
                                </Box>
                                <Grid container style={{ marginTop: '20px' }}>
                                    <Grid item lg={6} md={6} sm={12}>
                                        <Box style={{...webStyle.flexBox,gap:"14px"}}>
                                            <Box>
                                        <Typography style={{ fontSize: '12px', fontFamily:"Poppins",color:"#1C1B1B", fontWeight: 400 }}>Created By</Typography>
                                        <Box style={{ fontSize: '16px', fontFamily:"Poppins",color:"#787776", fontWeight: 500 }}>{this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.created_by}</Box>
                                        </Box>
                                        <Box>
                                        <Typography style={{ fontSize: '12px', fontFamily:"Poppins",color:"#1C1B1B", fontWeight: 400 }}>Category</Typography>
                                        <Box style={{ fontSize: '16px', fontFamily:"Poppins",color:"#787776", fontWeight: 500 }}>{this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.categories} </Box>
                                        </Box>
                                        </Box>
                                        <Box style={{marginTop:"20px"}}>
                                            <Typography
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                lineHeight: "16px",
                                                color:"#652786",
                                                gap:"16px"
                                            }}>Due Date:&nbsp;&nbsp;&nbsp;<span style={{
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "16px",
                                                color:"#484646",
                                            }}>{this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.due_date}</span></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} style={{display:"flex",justifyContent:"flex-end"}}>
                                        {this.handleBtnRender()}
                                    </Grid>
                                </Grid>
                                <p style={{
                                         marginTop:"10px",
                                         fontWeight:600,fontSize:"20px",fontFamily:"Poppins"
                               }}>Description</p>
                                <p style={{
                                         marginTop:"10px",
                                         fontWeight:400,fontSize:"16px",fontFamily:"Poppins"
                               }}>{this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.description}</p>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <Box style={{
                                    boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                    padding:"24px",
                                }}>
                                <Typography style={{
                                 fontFamily: "Poppins",fontSize: "24px",fontWeight: 600,lineHeight: "36px",
                                }}>Program includes</Typography>
                        <Box style={{...webStyle.flexBox,...webStyle.gapTwentyFour,marginTop:"15px"}}>
                            <Box style={{
                          display: "flex",
                          maxWidth: "304px",
                          gap: "8px",
                          alignItems: "center",
                          flexWrap: "wrap",
                           borderRight:"2px solid #E5E2E1",
                           padding:"0 15px 0px 0px"
                        }}>
                          <div>
                            <img src={courseImage} />
                          </div>
                          <div>
                            <Typography style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            > {this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.total_courses} </Typography>
                            <Typography style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              lineHeight: "16px",
                              color: "#484646"
                            }}> Courses </Typography>
                          </div>

                        </Box>
                        <Box style={{
                          display: "flex",
                          maxWidth: "304px",
                          gap: "8px",
                          alignItems: "center",
                          flexWrap: "wrap"
                        }}>
                          <div>
                            <img src={lessonpic} />
                          </div>
                          <div>
                            <Typography style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            > {this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.total_lessons} </Typography>
                            <Typography style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              lineHeight: "16px",
                              color: "#484646"
                            }}> Lessons </Typography>
                          </div>
                        </Box>
                        </Box>
                        {(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.content_release_schedule) && <AfterCountLesson>
                                            <Typography className="weightSet">Phases are released</Typography>
                                            <ContentReleaseStatus style={{...webStyle.flexBox,justifyContent:"center"}}>
                                                {this.state.allProgramLibraryData.data.attributes.content_release_schedule_value}</ContentReleaseStatus>
                                        </AfterCountLesson>}
                        <Box>
                            {(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.content_release_schedule) ? this.state.allProgramLibraryData.data.attributes.phases.map((phases:any,index:number)=>(
                        <CustomizeAccordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p style={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "22px",
            }}>{phases.phase_name}</p>
          </AccordionSummary>
            {phases.program_include.map((item: any) => {
              return (
                  this.renderPhasesData(item)
              );
            })}
            
        </CustomizeAccordion>
                            )) : (
                                <Box style={{marginTop:"10px"}}>
                                {this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.program_include.map((phases:any) =>
                                    this.renderWithoutPhaseData(phases)
    )}
                            </Box>
                            )}
                        </Box>
                        </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    flexBox:{
        display:"flex",
        justifyContent:"start",
        alignItems:"center"
    },
    gapTwentyFour:{
        gap:"24px"
    }
};

const CustomAccordionDetails = styled(AccordionDetails)({
    "&:hover": {
        border:"1px solid #542070",
        backgroundColor: '#EEE8F2',
        borderRadius:"12px"
    },
})

const CustomBox = styled(Box)({
    padding:"10px",
    "&:hover": {
        backgroundColor: '#EEE8F2',
        border:"1px solid #542070",
        borderRadius:"12px"
      },
})

const LightNewTooltip = withStyles((theme) => ({
    tooltip: {
        boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
      backgroundColor: theme.palette.common.white,
      border: '15px',
      maxWidth: '341px',
      padding: '10px',
      borderRadius: "14px",
      top: theme.props?.MuiTooltip?.placement === "top" ? "auto" : 40,
    },
    arrow: {
        color: "white",
      },
  }))(Tooltip);

  const NewToolTipMain = styled("div")({
    width: "100%"})
const AfterCountLesson = styled(Box)({
    display: "flex", 
    alignContent: "center", 
    justifyContent: "space-between", 
    alignItems: "center",
     gap: "10px",
     height: "64px", 
         fontSize: "14px",
        fontWeight: 400,
        marginTop:"10px"
});

const ContentReleaseStatus = styled(Typography)({
    borderRadius: "88px",
    background: "#E0D4E7",
    padding: "6px 9px 6px 9px",
    height: "40px",
    width: "86px",
    fontWeight: 400,
    textAlign: "center",
    color: "#652786",
    fontSize: "14px !important",
});

const CustomizeAccordion = styled(Accordion)({
    "&.MuiAccordion-root .Mui-expanded":{
        maxWidth:"100% !important"
    }
})

const AddToCartBox = styled(Box)({
        textAlign:"center",
        gap:"16px",
        "@media (max-width:960px)": {
            display:"flex",
            marginTop:"20px",
            alignItems:"center"
          },
})



// Customizable Area End